const General = {
	ProjectName: "Into the Czech Lands",
	ProjectName_m: "Into the \nCzech Lands",
	Legacy: "Legacy",
	NextChapter: "Next Chapter",
	Map: "Map",
	Smetana: "Bedřich Smetana",
	Dvorak: "Antonín Dvořák",
	Janacek: "Leoš Janáček",
	Martinu: "Bohuslav Martinů",
	NationalTheatreBrno: "National Theatre Brno",
	NationalTheatreBrno_C: "NATIONAL THEATRE BRNO",
	Introduction: "Introduction",
	TutorialOne: "1. Click the landmark to know more",
	TutorialTwo: "2. Click the composer to learn his music journey",
	Smetana_m: "Bedřich Smetana",
	Dvorak_m: "Antonín Dvořák",
	Janacek_m: "Leoš Janáček",
	Martinu_m: "Bohuslav Martinů",
	NationalTheatreBrno_m: "National\nTheatre\nBrno",
};

const Menu = {
	ExhibitionAuthor: "Exhibition Author",
	IllustrationDesign: "Illustration Design",
	WebsiteDesign: "Website Design & Developer",
	Courtesy: "Courtesy of",
	ShareExp: "Share Your Experience",
	Credit: "Credit",
	BackToMenu: "Back to Menu",
};

const Map = {
	Intro:
		"The fertile cultural history of the regions of Bohemia and Moravia is amply reflected in art, architecture, literature and music. For centuries, rich ethnic and folk music traditions have inspired numerous composers working in this enchanted realm. While Bohemia emerged as a social and cultural phenomenon bound to a movement that sought to revive the Czech language, culture and national identity, Moravia maintained a musical tradition distinct from the folk music of neighbouring provinces.",
	RedutaTheatre: "Reduta Theatre",
	MahenTheatre: "Mahen Theatre",
	JanacekTheatre: "Janáček Theatre",
	BrnoTheatresTT:
		"Brno's major performing arts venues, of \ngreat importance to the historical, cultural \nand musical development of the city. ",
	JanacekHouse: "The Leoš Janáček House",
	JanacekHouseTT:
		"Part of the Leoš Janáček Memorial; built specifically for \nthe composer and where he wrote all his major works",
	OrganistHouse: "Organist House",
	OrganistHouseTT:
		"Dvořák received his basic music instruction here from \norganist Antonín Liehmann",
	SychrovCastle: "Sychrov Castle",
	SychrovCastleTT:
		"Neo-Gothic castle and park frequently visited by \nDvořák; inspiration for several compositions.",
	MoravianTheatre: "Moravian Theatre Olomouc",
	MoravianTheatreTT:
		"Gustav Mahler briefly conducted at this theatre \nbefore moving to Vienna.",
	MonumentOfBystrouška: "Monument of Bystrouška,",
	MonumentOfBystrouškaTT:
		"Birthplace of Janáček, where today stands a \nstatue of his famous opera character, the \nCunning Little Vixen",
	LitomyšlCastle: "Litomyšl Castle",
	LitomyšlCastleTT: "Renaissance castle complex, birth place of Smetana.",
	StJamesChurch: "St. James Church, {wordVal}",
	StJamesChurchTT:
		"Martinu was born in the small room in the tower of St. \nJames Church.",
	CharlesBridge: "Charles Bridge",
	CharlesBridgeTT:
		"Medieval stone bridge across the Vltava river, featured \nmusically in Má Vlast",
	Rudolfinum: "Rudolfinum",
	RudolfinumTT:
		"Dvořák conducted the first concert of the Czech \nPhilharmonic Orchestra",
	EstatesTheatre: "The Estates Theatre",
	EstatesTheatreTT:
		"World Premieres of Mozart operas Don Giovanni and La \nClemenza di Tito",
	NationalTheatre: "The National Theatre",
	NationalTheatreTT:
		"National monument of Czech history and art, and alma \nmater of Czech opera. Staged world premieres of \noperas by Smetana, Dvořák, Janáček and Martinů ",
	Vyšehrad: "Vyšehrad",
	VyšehradTT:
		"Historic fort in Prague. Final resting place of Dvořák, \nSmetana, writer Karel Čapek and the painter Alphonse \nMucha",
	DvorakMemorial: "The Antonín Dvořák Memorial",
	DvorakMemorialTT:
		"Neo-Renaissance stately residence at Vysoká u \nPříbrami, owned by Dvořák's brother-in-law. The estate, \nwoods and nearby lake served as sources of inspiration \nfor the composer",
	AugustinianHouse: "Augustinian House{wordVal}",
	AugustinianHouseTT:
		"Spa town where Janáček met Kamila Stösslová, the \nmuse of his later years",
};

const Smetana = {
	Smetana_BGc:
		"The Provisional Theatre in 1864, photography by Wilhelm Rupp (1821-1893). It is now The National Theatre (Prague).",
	Smetana_p1h1: "1848: The Year of Awakening",
	Smetana_p1p:
		"Political stirrings of national identity and pride ignited a great awakening across Europe. Urging an end to Habsburg absolutist rule, the Czech composer Smetana openly participated in this revolution. Barely escaping arrest, and unable to establish a career in Prague, Smetana left for Sweden.",
	Smetana_q1: "I am Czech in body and soul.",
	Smetana_p2h1: "Exile in Sweden",
	Smetana_p2p:
		"The years in exile helped form Smetana’s development as a composer. Stirred by the rhythms and melodies of Czech folk music, he created a new and unique poetic language. Smetana enthusiastically envisioned operas and symphonic works based on themes from Czech history and mythology.",
	Smetana_S4h1: "The Establishment of the Provisional Theatre",
	Smetana_S4p:
		"Celebrating the autonomy of a unique Czech nation, a new school of music opened in 1863. It was exclusively dedicated to promoting Czech music, and Czech nationalist opera became the genre that defined Smetana’s career. {wordVal} is the intimate realisation of Smetana’s artistic vision, and it gave his Czech homeland its own musical language.",
	Smetana_Slh1: "The Bartered Bride",
	Smetana_Sl1p:
		"Smetana’s comic opera {wordVal}, with a \nlibretto by Karel Sabina, was first performed at the Prague \nProvisional Theatre on 30 May 1866. It was not immediately \nsuccessful, but after extensive revisions over four years, it \nwas widely regarded as a major contribution towards \nthe development of Czech opera and music.",
	Smetana_Sl2c1: "Set Design to Bedřich Smetana's {wordVal}, Act I",
	Smetana_Sl2c2: "(The National Theatre, 1883)",
	Smetana_Sl3p:
		"Set in a country village with realistic characters, the spirited \nheroine has to use all her determination, charm and cunning to \n marry the man she loves. It is a joyous celebration of Czech culture \nand identity, and the distinct rhythmic inflections of the Czech \nlanguage and of Czech folk dances combine irresistibly.",
	Smetana_Sl4p:
		"Smetana said he was trying to give the music “a popular \ncharacter, because the plot was taken from village life and demanded a \nnational treatment”. In doing so, he rhythmically referenced the characteristic folk \ndances of Bohemia—the polka, the skočná and the furiant. Smetana provides only\n occasional glimpses of authentic Czech folk melodies, and the habitual emphasis \non the authenticity of the setting and costumes in productions is primarily a matter \nof staging. Nevertheless, Smetana “clearly felt the pulse of peasantry” and the \nsimplicity of the music not only connected to a broad folk base, but proved \nhighly inspirational to the emerging independence movement.",
	Smetana_q2: "My father land means more to \nme than anything else.",
	Smetana_S6c1:
		"The first performance poster of {wordVal}. The premiere took place on 5 November 1882 in Žofín Palace, Prague under Adolf Čech.",
	Smetana_p3h1: "Má vlast",
	Smetana_p3p:
		"Throughout human consciousness, the great rivers of this world have spiritually highlighted the paradoxical relationship between eternity and change. As metaphors for life itself, they simultaneously underscore all that is timeless and ephemeral in human experience and existence. Rivers sustain life by renewing the fertility of the land, and they variously inspire poetry, folklore and music. Perhaps the most famous river journey is musically encoded in Smetana’s “Vltava” (The Moldau).",
	Smetana_p4p:
		"This second part of a cycle of six tone poems entitled {wordVal} (My Homeland) depicts the Vltava. Smetana provided brief commentaries explaining the content of his tone poem.",
	Smetana_q4:
		"“The composition depicts the course of the river, from its beginning where two brooks, one cold, the other warm, join a stream.”",
	Smetana_p5p:
		"Water splashes gaily against rocks and, as the river grows wider, her banks echo with the sound of hunting horns and peasant dancing as part of a folk wedding. Bathed in moonlight, themysterious dance of the water nymphs heralds the emerging day. As day breaks, the river reaches St John’s Rapids, on whose rocks her waves break into a frothy spray. From there she flows towards Prague, where she greets the venerable Vyšehrad castle. Finally, the river disappears into the distance, as she majestically flows into the Elbe. Smetana’s river journey has captured the imagination of audiences worldwide, and it underscores the unity of a people intrinsically bound to this majestic waterway.",
	Smetana_legacy:
		"Smetana is widely considered the father of Czech music, and his work posthumously became synonymous with a Czech national musical style. Establishing a Czech classical music canon, Smetana became a national symbol who pioneered a musical aesthetic that endured changes in politics, governments and the ethnic composition of his native land.",
	Smetana_m_p1h1: "1848: The Year of \nAwakening",
	Smetana_m_q1: "I am Czech in body and soul.",
	Smetana_m_q2: "My father land \nmeans more to me \nthan anything else.",
	Smetana_m_q4:
		"“The composition depicts the course of the river, from its beginning where two brooks, one cold, the other warm, join a stream.”",
	Smetana_m_Sl1p:
		"Smetana’s comic opera {wordVal}, with a libretto by Karel Sabina, was first performed at the Prague Provisional Theatre on 30 May 1866. It was not immediately successful, but after extensive revisions over four years, it was widely regarded as a major contribution towards the development of Czech opera and music.",
	Smetana_m_Sl2c1: "Set Design to Bedřich Smetana's {wordVal}, Act I",
	Smetana_m_Sl2c2: "(The National Theatre, 1883)",
	Smetana_m_Sl3p:
		"Set in a country village with realistic characters, the spirited heroine has to use all her determination, charm and cunning to  marry the man she loves. It is a joyous celebration of Czech culture and identity, and the distinct rhythmic inflections of the Czech language and of Czech folk dances combine irresistibly.",
	Smetana_m_Sl4p:
		"Smetana said he was trying to give the music “a popular character, because the plot was taken from village life and demanded a national treatment”. In doing so, he rhythmically referenced the characteristic folk dances of Bohemia—the polka, the skočná and the furiant. Smetana provides only occasional glimpses of authentic Czech folk melodies, and the habitual emphasis on the authenticity of the setting and costumes in productions is primarily a matter of staging. Nevertheless, Smetana “clearly felt the pulse of peasantry” and the simplicity of the music not only connected to a broad folk base, but proved highly inspirational to the emerging independence movement.",
};

const Dvorak = {
	Dvorak_p1h1: "Dvořák Reloaded",
	Dvorak_p1p:
		"The traditional image of Antonín Dvořák as a simple Czech with a flair for composing symphonic and chamber music has recently given way to one of a complex figure writing works filled with hidden drama and secret programs. Dvořák carefully cultivated a Czech musical identity throughout his life, but he was at home in a variety of different environments. In fact, he may well have been the most versatile composer of his time.",
	Dvorak_p2h1: "A Violist in Provisional Theatre Orchestra",
	Dvorak_p2p:
		"Dvořák broadened his musical horizon as the principal violist at the newly built Provisional Theatre. When Smetana took over as principal conductor, Dvořák gained first-hand knowledge of compositions crafted by Czech and Slavic composers. Inspired by Smetana, he began intensive studies of various folk music collections. In his compositions, Dvořák turned towards a new classicism with elements of Slavonic folklore starting to permeate his musical language.",
	Dvorak_c1:
		"Czech composer Antonín Dvořák (1841-1904) with friends and family in New York.",
	Dvorak_p3h1: "International Recognition",
	Dvorak_p3p:
		"Dvořák was profoundly nationalist in many respects. Nevertheless, among the great composers of the 19th century, his cosmopolitan musical tendencies were probably equalled only by Liszt and Tchaikovsky. Many features of his musical language that are supposedly clear signs of nationalism are in fact unequivocal gestures of internationalism. Dvořák became the first Czech composer of international renown, and his {wordVal} created an eager market for his music at home and abroad.",
	Dvorak_p4h1: "Slavonic Dances",
	Dvorak_p4p:
		"Antonín Dvořák was a relatively unknown composer when he applied for the Austrian State Prize fellowship. He managed to win it three times in four years, and Johannes Brahms referred Dvořák to his own publisher Fritz Simrock. The publisher first issued Dvořák’s {wordVal} and, encouraged by its resounding financial and popular success, he asked the composer to write something with a dance-like character.",
	Dvorak_c2:
		"The title page of the first series of {wordVal} with Antonín Dvořák's dedication to Mr. Wassman.",
	Dvorak_p5p:
		"Taking Brahms’s {wordVal1} as a model, Dvořák wrote a total of 16 dances for piano four hands, which he subsequently orchestrated. The {wordVal2} ({wordVal3}) emerged in two sets, as Opus 46 and Opus 72. Dvořák’s biographer Otakar Šourek heard in the Dances",
	Dvorak_q1:
		'"the humour and pride, the optimism and passion of \nthe Czech people come to life, and in it breathes \nthe sweet fragrance and unspoiled beauty of \nCzech woods and meadows."',
	Dvorak_p6p:
		"Dvořák brilliantly translated the characteristic rhythms of Slavic folk music into extended stylish numbers. Referencing, among others, the Czech folk dances the skočná, the sousedská, the dumka and the furiant, Dvořák created something truly original from traditional elements. The {wordVal}, lively and intended to be overtly nationalistic, were received enthusiastically at the time and still rank among the composer’s most memorable compositions today.",
	Dvorak_q2:
		"The music of the people is like a \nrare and lovely flower growing \namidst encroaching weed.",
	Dvorak_p7h1: "Self-Reflection",
	Dvorak_p7p:
		"Nature played a major role in Dvořák’s life and work, and Slavonic folklore became part of his compositional toolbox. Nature and folk customs became a powerful source of inspiration for the composer, and much of his music closely resembles a landscape painting in its richness of colour, evocation of place and people, sense of grandeur and unfolding vistas.",
	Dvorak_p8p:
		"Dvořák frequently visited a country estate in the tiny village of Vysoká, and a small path behind the mansion leads to Rusalka Lake. Sitting by the pond for hours on end provided the composer with the inspiration for more than thirty works, including his world-famous opera {wordVal}.",
	Dvorak_Slh1: "Rusalka",
	Dvorak_Sl1p:
		"From the very beginning of his creative career, Antonín Dvořák \nregarded opera as a central pillar of his compositional craft. Active \nduring a period when the idea of Czech national opera was being \nformulated, Dvořák adopted three opera topics for his own work. \nRural Czech village life with its typical characters, subjects \nfrom extended Slavonic history, and Czech stories and \nfairy tales, including {wordVal}.",
	Dvorak_Sl2p:
		"Jaroslav Kvapil, who consulted a number of literary sources—\nincluding Fouqué’s {wordVal1}, Hans Christian Andersen’s {wordVal2}, the French legend of Melusine, and Karel Jaromír Erben’s {wordVal3}—penned the libretto for this lyrical fairy tale. \nMaking use of materials notated in his American sketchbooks, the \ncomposer became absorbed with the work and produced one \nof his most fluent compositions of his career.",
	Dvorak_Sl3c1:
		"{wordVal} was first performed in Prague on 31 March 1901, with Růžena Maturová as the first Rusalka.",
	Dvorak_Sl5p:
		"The title character Rusalka is an immortal water \nnymph who has fallen in love with a mortal. Rusalka decides to give \nup her immortality to pursue true love; however, the prince she loves is \nno hero and he abandons her. Eventually, he realises his mistake, and \nwillingly makes his own ultimate sacrifice to restore her immortality. \nWhile the story was criticised for its lack of naturalism, it \nnevertheless became Dvořák’s most popular opera \nboth in the composer’s native land and abroad.",
	Dvorak_m_p2h1: "A Violist in Provisional \nTheatre Orchestra",
	Dvorak_m_q1:
		'"the humour and pride, the optimism and passion of the Czech people come to life, and in it breathes the sweet fragrance and unspoiled beauty of Czech woods and meadows."',
	Dvorak_m_q2:
		"The music of the people is like a rare and lovely flower growing amidst encroaching weed.",
	Dvorak_m_Sl1p:
		"From the very beginning of his creative career, Antonín Dvořák regarded opera as a central pillar of his compositional craft. Active during a period when the idea of Czech national opera was being formulated, Dvořák adopted three opera topics for his own work. Rural Czech village life with its typical characters, subjects from extended Slavonic history, and Czech stories and fairy tales, including {wordVal}.",
	Dvorak_m_Sl2p:
		"Jaroslav Kvapil, who consulted a number of literary sources—including Fouqué’s {wordVal1}, Hans Christian Andersen’s {wordVal2}, the French legend of Melusine, and Karel Jaromír Erben’s {wordVal3}—penned the libretto for this lyrical fairy tale. Making use of materials notated in his American sketchbooks, the composer became absorbed with the work and produced one of his most fluent compositions of his career.",
	Dvorak_m_Sl3c1:
		"{wordVal} was first performed in Prague on 31 March 1901, with Růžena Maturová as the first Rusalka.",
	Dvorak_m_Sl5p:
		"The title character Rusalka is an immortal water nymph who has fallen in love with a mortal. Rusalka decides to give up her immortality to pursue true love; however, the prince she loves is no hero and he abandons her. Eventually, he realises his mistake, and willingly makes his own ultimate sacrifice to restore her immortality. While the story was criticised for its lack of naturalism, it nevertheless became Dvořák’s most popular opera both in the composer’s native land and abroad.",
};

const Janacek = {
	Janacek_BGc: "Autograph page of Fanfare (1st movement) from {wordVal}.",
	Janacek_p1h1: "Early Life",
	Janacek_p1p:
		"Janáček grew up in poverty, yet his interest in composition saw him study at the conservatories in Leipzig and Vienna. Upon returning to Brno in 1875, he tirelessly worked to make the Moravian capital his musical centre and cultural base. When his first opera failed to resonate with the public, Janáček immersed himself into the folk music of his native region.",
	Janacek_vlink: "See more at Brno Tourist Information Centre {link}",
	Janacek_v1p:
		"The animated video briefly illustrates Janáček's birth, his arrival in Brno and his student days. Created in cooperation between the artist Vendula Chalánková and the animators Tomoko Lisá and David Lisý.",
	Janacek_c1:
		"Leoš Janáček collecting folksongs in Strání in 1906, a village in the Moravian Slovakia.",
	Janacek_p2h1: "Intensive Study of Folklore",
	Janacek_p2p:
		"Janáček’s enthusiasm for his native folk music spawned repeated ethnographic excursions into the Moravian countryside. He spearheaded folklorist activity in Moravia and Silesia, and his study of the relationships between language and music gradually developed an original compositional style. His compositions share in the melodic and rhythmic irregularities of word-based Slovak, Hungarian and Romanian folk music, and by conveying a sense of heightened realism powerfully make the connection to everyday life.",
	Janacek_Slh1: "Glagolitic Mass",
	Janacek_Sl1p:
		"Leoš Janáček’s original intention in composing what would \nbecome known as the {wordVal} was to set an Old Church \nSlavic version of the Ordinary of the Roman Catholic Mass.",
	Janacek_Sl2p:
		"Glagolitic was the first Slavic literary and written language, \noriginating around the 9th century. The Czech scholar Jezef Vajs \npublished two Latin-alphabet transcriptions of a 14th-century source \nin 1919 and 1920, respectively. Janáček used both text transcriptions in \ndifferent parts of the mass, and he sent the text to a professor of \nSlavic studies in Prague. To this day, various editions of the\n {wordVal} show slight differences in text, but Janáček \nalways viewed it as a celebration of Slavic culture.",
	Janacek_Sl3p:
		"Janáček was at the peak of his musical powers, and he \nbrilliantly poured his dramatic skills into the composition of the Mass. \nThe orchestral writing is brilliantly detailed, and the solo parts expressive. \nNot to be outdone, the choral writing is magnificent, with occasional \noperatic intensity. The drama of the Kyrie and Credo is balanced \nby moments of pastoral exultation, and in the Gloria Janáček \nevokes the hills surrounding his hometown.",
	Janacek_Sl4p:
		"It is music “made for a cathedral from the huge expanse of \nwoods and sky stretching into a misty distance”. With its dramatic \nmix of religious and pagan ritual, and the juxtaposition of exuberant \nenergy and subtle tenderness, Janáček takes the listener on a \nhighly original and exhilarating journey.",
	Janacek_c2: "Leoš Janáček and Kamila Stösslová in Luhačovice in 1920.",
	Janacek_p3h1: "Obsession and Inspiration",
	Janacek_p3p:
		"Janáček was a married man aged 63 when he met the 25 year-old Kamila Stösslová, herself also married. What might have turned into an innocent and transient infatuation became a fully fledged obsession. Kamila remained unattainable, and became the inspiration for most of his operatic heroines.",
	Janacek_q1:
		'"There is so much emotional fire,” he writes to Kamila, \n“if we both caught fire, we would be reduced to ashes."',
	Janacek_p4p:
		"One sunny day in 1925 the unlikely couple sat in a park in the town of Písek, where Kamila lived with her husband. Spellbound, they listened to a performance by a military band marching in full uniform.",
	Janacek_p5h1: "Sinfonietta",
	Janacek_p5p:
		"Patriotism and nationalism, particularly in the aftermath of the declaration of Czech independence in October 1918, had always appealed to Leoš Janáček. And when he was asked to compose music for the gymnastic Sokol festival—a movement that celebrated youth, sport and an independent nationhood—Janáček readily gave expression to his notion of national identity. Initially, the work was titled {wordVal} and dedicated to the Czechoslovak Armed Forces.\n\n",
	Janacek_p6p: "According to the composer,",
	Janacek_q2:
		'"it embodied the ideals of contemporary free man, \nhis spiritual beauty and joy, his strength, courage and \ndetermination to fight for victory"',
	Janacek_p7p:
		"Eventually he dropped the military designation, and the work became known simply as the {wordVal}.\n\nDepicting various scenes and locations associated with the city Brno after the 1918 declaration of independence, Janáček wrote: “I saw the town undergo a miraculous change. I lost my dislike for the gloomy Town Hall, my hatred of the hill from whose depths so much pain was screaming, my distaste for the street and its throng. As if by a miracle, liberty was conjured up, glowing over the town. I saw myself in it. I belonged to it. And the blare of the victorious trumpets, the holy peace of the Queen’s Monastery, the shadows of the night, the breath of the green hill and the vision of the growing greatness of the town, of my Brno, were all giving birth to my Sinfonietta.“",
	Janacek_m_p7p:
		"Eventually he dropped the military designation, and the work became known simply as the {wordVal}.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\nDepicting various scenes and locations associated with the city Brno after the 1918 declaration of independence, Janáček wrote: “I saw the town undergo a miraculous change. I lost my dislike for the gloomy Town Hall, my hatred of the hill from whose depths so much pain was screaming, my distaste for the street and its throng. As if by a miracle, liberty was conjured up, glowing over the town. I saw myself in it. I belonged to it. And the blare of the victorious trumpets, the holy peace of the Queen’s Monastery, the shadows of the night, the breath of the green hill and the vision of the growing greatness of the town, of my Brno, were all giving birth to my Sinfonietta.“",
	Janacek_v2p:
		'It is an animated video by Vendula Chalánková, Oksana Rogožina and Jan Smrčka. It portrays Janáček\'s "great old age", the last ten years of his life when he wrote his major works which are famous across the world today.',
	Janacek_legacy:
		"Janáček drew on the inner resources of music and speech to convey complex feelings and emotional states to his listeners. His extraordinary power in translating profound psychological insights into music emerged from his fervent compassion for the human condition.",
	Janacek_m_q1:
		'"There is so much emotional fire,” he writes to Kamila, “if we both caught fire, we would be reduced to ashes."',
	Janacek_m_q2:
		'"it embodied the ideals of contemporary free man, his spiritual beauty and joy, his strength, courage and determination to fight for victory"',
	Janacek_m_Sl1p:
		"Leoš Janáček’s original intention in composing what would become known as the {wordVal} was to set an Old Church Slavic version of the Ordinary of the Roman Catholic Mass.",
	Janacek_m_Sl2p:
		"Glagolitic was the first Slavic literary and written language, originating around the 9th century. The Czech scholar Jezef Vajs published two Latin-alphabet transcriptions of a 14th-century source in 1919 and 1920, respectively. Janáček used both text transcriptions in different parts of the mass, and he sent the text to a professor of Slavic studies in Prague. To this day, various editions of the {wordVal} show slight differences in text, but Janáček always viewed it as a celebration of Slavic culture.",
	Janacek_m_Sl3p:
		"Janáček was at the peak of his musical powers, and he brilliantly poured his dramatic skills into the composition of the Mass. The orchestral writing is brilliantly detailed, and the solo parts expressive. Not to be outdone, the choral writing is magnificent, with occasional operatic intensity. The drama of the Kyrie and Credo is balanced by moments of pastoral exultation, and in the Gloria Janáček evokes the hills surrounding his hometown.",
	Janacek_m_Sl4p:
		"It is music “made for a cathedral from the huge expanse of woods and sky stretching into a misty distance”. With its dramatic mix of religious and pagan ritual, and the juxtaposition of exuberant energy and subtle tenderness, Janáček takes the listener on a highly original and exhilarating journey.",
};

const Martinu = {
	Martinu_BGc:
		"Bohuslav Martinů's first concert with his sister, Maria when he was 8 years old.© Center of Bohuslav Martinů, Polička",
	Martinu_p1h1: "Unsung Concertos",
	Martinu_p1p:
		"Martinů always paid close attention to avant-garde cosmopolitan musical developments. He was also acutely aware of his position as a composer of Czech national music. He composed in a variety of colourful styles and fluid forms, and his characteristic musical language gradually builds small musical cells over insistent rhythms. Jazz harmonies happily mingle within an intricate network of counterpoint and one sculptor described his compositions as “carved out of marble”.",
	Martinu_c1: "The Birth Place of Martinů, St. James Church in Polička.",
	Martinu_p2h1: "Beginnings in Prague",
	Martinu_p2p:
		"Martinů gave his first public performance as a solo violinist in his hometown at the age of five. The town immediately recognised his exceptional talent and funded his musical education at the Prague conservatory for a number of years. Martinů developed rapidly as a composer but, unable to make his mark in conservative Prague, he packed his bags and headed for Paris in 1923.",
	Martinu_c2:
		"Bohuslav Martinů with the Czech community in Paris in 1938: Josef Páleníček (a composer), \nRudolf Firkušný (a pianist) and Karel Šebánek (a publisher).\n© Center of Bohuslava Martinů, Polička",
	Martinu_p3h1: "In Paris",
	Martinu_p3p:
		"Ballet initially became Martinů’s favourite medium for experimentation. However, as the political situation worsened in Europe and back home in Czechoslovakia, Martinů’s music quickly reflected a rekindled consciousness of Czech folklore and culture. His premonitions are expressed in anguished lyricism brutally disturbed by a menacing angularity of the rhythm. His worst fears came true in the end, and in 1940 Martinů had to flee Paris after the Nazi invasion of the city as he had been blacklisted for his connections to the Czech resistance.",
	Martinu_p4p:
		"Martinů’s three-act ballet {wordVal} premiered in Prague in 1932. Composed at a time when Martinů was deeply in love with a young Czech woman, he increasingly started to think of the folk culture of his native land. {wordVal}, according to scholars, might have referenced cheaply produced collections of folk tales and ballads, illustrated by genre pictures of life in Czech villages.",
	Martinu_p5p:
		"Martinů’s imagination may also have been fired by a collection of national songs and riddles titled {wordVal1}, produced by the prolific painter and illustrator Mikoláš Aleš. The ballet also originally included the cantata {wordVal2} with a text by the influential 19th-century poet and folklorist Karel Jaromír Erben.",
	Martinu_p6p:
		"An illustration from {wordVal}, produced by the prolific painter and illustrator Mikoláš Alešd. It says, \n''I am a man from Moravia, my girl, believe me: \nI'm like a bird, I love you, I leave so''",
	Martinu_p7p:
		"In the event, Martinů described {wordVal} as “a ballet of popular plays, customs and fairy tales”. The narrative weaves together familiar fairy tales and Czech folk legends, and his music juxtaposes folk themes, ironic Baroque flourishes and a distinct modernism inspired by the group of French composers known as Les Six.",
	Martinu_q1: "My work is still Czech and \nconnected with my homeland.",
	Martinu_c3:
		"Bohuslav and Charlotte Martinů, probably shortly after arriving from Europe to the United States in 1941.\n© Center of Bohuslava Martinů, Polička.",
	Martinu_p8h1: "Exile in America",
	Martinu_p8p:
		"Escaping Nazi oppression, Martinů found refuge in the United States. Struggling financially and eternally homesick, Martinů started to set folk song texts that became a symbolic connection to his beloved homeland. His dejected dreamlike vision of peace and longing for his country contrasted sharply with the imperfections of the real world. There was to be no happy ending for Martinů as the post-war advent of a communist government in Czechoslovakia prevented him from returning home.",
	Martinu_Slh1: "Opening of the Springs",
	Martinu_Sl1p:
		"Forced to spend the final decades of his life in \nexile, Bohuslav Martinů (1890–1959) frequently visited his \nhomeland through his compositions. In a letter to his old friend, \nthe writer and poet Miroslav Bureš, Martinů suggested that the \npremiere of the Cantata {wordVal} ({wordVal2}) might take place in their hometown of Polička. \n“That way,” he wrote, “at least part of me will be \nable to return home.”",
	Martinu_Sl2p:
		"Discouraged and profoundly homesick, Martinů was \ndeeply touched by Bureš’s simple folk verses, which celebrated \nthe landscape of the Czech-Moravian highlands and the local folk \ncustoms. Emotionally vested, Martinů crafted a loose cycle of \nfour chamber cantatas. {wordVal} is one \nof the composer’s most intimate professions of \nlove for his native land.",
	Martinu_Sl3p:
		"Inspired by the annual ritual of saying goodbye to winter by\ncleaning and reopening the sources of water, Martinů composed music \nintimately bound to the customs and speech of traditional men and women \nfrom his home region. Scored for two violins, viola and piano, and featuring a \nfemale chorus, baritone solos and a reciter, {wordVal} \nretains the spirit of Moravian folk songs while imparting them with \na universal quality that elevates Martinů’s musical expressions \nbeyond the narrow borders of patriotic folklore.",
	Martinu_legacy:
		"Martinů’s musical legacy consists of almost 400 compositions, ranging from choral works and operas to symphonies and a great deal of chamber music. Always searching for the meaning of life and for the truth, he once wrote: “a system of uncertainty has entered our daily life. The pressures of mechanisation and uniformity to which it is subject call for protest and the artist has only one means of expressing this—music.”",
	Martinu_m_c2:
		"Bohuslav Martinů with the Czech community in Paris in 1938: Josef Páleníček (a composer), Rudolf Firkušný (a pianist) and Karel Šebánek (a publisher).© Center of Bohuslava Martinů, Polička",
	Martinu_m_p6p:
		"An illustration from {wordVal}, produced by the prolific painter and illustrator Mikoláš Alešd. It says, ''I am a man from Moravia, my girl, believe me: I'm like a bird, I love you, I leave so''",
	Martinu_m_q1: "My work is still Czech and connected with my homeland.",
	Martinu_m_Sl1p:
		"Forced to spend the final decades of his life in exile, Bohuslav Martinů (1890–1959) frequently visited his homeland through his compositions. In a letter to his old friend, the writer and poet Miroslav Bureš, Martinů suggested that the premiere of the Cantata {wordVal} ({wordVal2}) might take place in their hometown of Polička. “That way,” he wrote, “at least part of me will be able to return home.”",
	Martinu_m_Sl2p:
		"Discouraged and profoundly homesick, Martinů was deeply touched by Bureš’s simple folk verses, which celebrated the landscape of the Czech-Moravian highlands and the local folk customs. Emotionally vested, Martinů crafted a loose cycle of four chamber cantatas. {wordVal} is one of the composer’s most intimate professions of love for his native land.",
	Martinu_m_Sl3p:
		"Inspired by the annual ritual of saying goodbye to winter bycleaning and reopening the sources of water, Martinů composed music intimately bound to the customs and speech of traditional men and women from his home region. Scored for two violins, viola and piano, and featuring a female chorus, baritone solos and a reciter, {wordVal} retains the spirit of Moravian folk songs while imparting them with a universal quality that elevates Martinů’s musical expressions beyond the narrow borders of patriotic folklore.",
};

const NDB = {
	NDB_p1p:
		"The history of opera in Brno dates back to the early 18th century. It all began in a beautiful building, originally a tavern,  on the corner of Zelný trh (Green Market)  in the city centre, today called the Reduta and it is still part of the  National Theatre Brno.",
	NDB_p2p:
		"When the Italian impresario Angelo Mingotti arrived in Brno in 1732, he built his own theatre in the riding school between ramparts under Špilberk Castle. It most likely served as the model for the city’s own theatre, built in 1733, with a deep-perspective stage and loges in the auditorium at Zelný trh. The theatre was the first opera house outside Italy to be established and funded by a municipality. Leading European artists such as Mozart, Liszt and Clara Schuman appeared at the Reduta and among its theatrical directors was Emanuel Schikaneder, librettist of Mozart’s opera {wordVal}.",
	NDB_te1:
		"The Reduta burns down for a second time. Rebuilt and influenced by the opera houses in Vienna, it was a place for Brno’s citizens to hear operatic performances, including Mozart’s {wordVal1}, and Salieri’s {wordVal2}.",
	NDB_te2:
		"The theatre’s flourishing artistic life was brought to a halt by another fire. It took 20 years for the building to be reconstructed, being reopened not as a theatre but as a market.",
	NDB_te3: "The Reduta resumes life as a theatre in March.",
	NDB_te4:
		"After the establishment of the Czechoslovak Republic, the theatre is co-administered by Czech and German companies.",
	NDB_te5:
		"The Reduta housed an operetta ensemble after the Second World War but opera also continued to be staged.",
	NDB_te6:
		"The building is closed due to the critical condition of the structure.",
	NDB_te7: "The Reduta reopens after several years’ reconstruction.",
	NDB_p3p1:
		"Czech theatre began to emerge in Brno in the 1880s; a temporary theatre building on the corner of Veveří Street on the site of the former U Marovských tavern housed opera, operetta and drama (and later also ballet) companies. It opened on 6 December 1884, with the first issue of {wordVal} (Music Pages) edited by the young Leoš Janáček, enthusiastically declaring:",
	NDB_q1:
		'"The moment that we have waited so long for has finally arrived: \nthe national Theatre of Art has opened! \nThis signifies a new era for Brno and for the whole Moravia."',
	NDB_p3p2:
		"During World War II, the building was damaged and it was finally demolished in December 1973. The theatre building, which was supposed to be only temporary, would ultimately serve the National Theatre Brno for 68 full seasons.",
	NDB_p4p1:
		"After the Reduta Theatre was destroyed in a fire in 1870, Brno’s City Council decided to build a new theatre, which would be a symbol of prestige for the city.",
	NDB_p4p2:
		"The Viennese architects Fellner & Helmer were commissioned to design it and construction started on 18 July 1881. A series of devastating fires which damaged European theatres in 1881 led to the decision to replace the proposed gas lighting with a new invention: electric light, so the Mahen Theatre became the first in Europe to be fully lit by electricity.",
	NDB_p5p:
		"The exterior design of the theatre has neo-renaissance features, and the splendid main staircase was inspired by the Palais Garnier in Paris.",
	NDB_p6p:
		"The German theatre was originally taken over by the Brno Czech National Theatre in 1919 and its first performance was Janáček's {wordVal1}. Most of the Janáček’s operas would be given their world premieres at the Mahen Theatre. Other notable world premieres at the theatre included Bohuslav Martinů’s {wordVal2} and {wordVal3}, and Prokofiev’s {wordVal4}.\n\nToday the Mahen Theatre is primarily home to the drama ensemble of the National Theatre Brno, while the Janáček Opera and the Ballet have, since 1965, been housed in the newer building of the Janáček Theatre.",
	NDB_p7p:
		"A milestone for the opera ensemble came in October 1965 when it moved into a new building, the Janáček Theatre, opening with a production of the composer’s {wordVal}.",
	NDB_p8p:
		"The theatre had been planned for a long time; between 1910 and 1958 seven architectural competitions were held to select a design. The winning one was drawn up by a team led by architect Jan Víšek. When it opened, the Janáček Theatre was the largest and technically best equipped theatre in what was then Czechoslovakia.",
	NDB_p9p:
		"The Janáček Opera is now \namong the leading opera houses in Central Europe, \noffering a wide repertoire of 18th and 19th opera. The \ncompany also collaborates with distinguished theatres abroad. \nThe Janáček Theatre is also home to the International Festival \nJanáček Brno. The festival features mainly opera performances \nby Czech and foreign companies but also symphonic and chamber \nconcerts, matinees, and other programmes connected with the \ncomposer. Janáček Brno became the first festival in the \nCzech Republic to win Best Festival at the prestigious \nInternational Opera Awards in 2018.",
	NDB_v1c: "Libuše\nBedřich Smetana",
	NDB_v2c: "The Devil and Kate\nAntonín Dvořák",
	NDB_v3c: "The Greek Passion\nBohuslav Martinů",
	BackToTheMap: "Back to the Map",
	NDB_m_p9p:
		"The Janáček Opera is now among the leading opera houses in Central Europe, offering a wide repertoire of 18th and 19th opera. The company also collaborates with distinguished theatres abroad. The Janáček Theatre is also home to the International Festival Janáček Brno. The festival features mainly opera performances by Czech and foreign companies but also symphonic and chamber concerts, matinees, and other programmes connected with the composer. Janáček Brno became the first festival in the Czech Republic to win Best Festival at the prestigious International Opera Awards in 2018.",
};

const Share = {
	ShareJourney: "Choose your favourite music journey",
	ShareSM: "Share this experience on social media",
};

const en = Object.assign(
	General,
	Menu,
	Map,
	Smetana,
	Dvorak,
	Janacek,
	Martinu,
	NDB,
	Share
);

export default en;
