import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie/es6";
import "../scss/MenuToggler.scss";
import * as SHARE from "../Redux/Actions/Share";

type propTypes = { getMenuStatus: () => boolean; toggleMenu: () => void };

const MenuToggler = ({ getMenuStatus, toggleMenu }: propTypes) => {
	const cookies = new Cookies();
	// @ts-ignore
	const currPage = useSelector((state) => state.route.page);

	return (
		<div
			id="MenuToggler"
			className={
				"MenuToggler" +
				(getMenuStatus() || (cookies.get("ShareExp") === "visible" && !currPage)
					? " Opened"
					: "")
			}
			onClick={() => {
				if (cookies.get("ShareExp") === "visible" && !currPage) {
					cookies.remove("ShareExp");
					// dispatch(SHARE.OFF());

					var shareExp = document.getElementById("ShareExp");
					if (shareExp) {
						shareExp.style.zIndex = "-300";
					}

					document.getElementById("MenuToggler")?.classList.remove("Opened");
				} else {
					toggleMenu();
				}
			}}
		>
			<div className="Line Mid" />
		</div>
	);
};

export default MenuToggler;
