const findNearestSnap = (...numbers: number[]) => {
	var nearestSnap = 0;
	numbers.reduce((acc, num, i) => {
		if (num < acc) {
			nearestSnap = i;
			return num;
		}

		return acc;
	});

	return nearestSnap;
};

export default findNearestSnap;
