export const ON = () => {
	return {
		type: "On",
	};
};

export const OFF = () => {
	return {
		type: "Off",
	};
};
