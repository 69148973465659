import { Action, combineReducers } from "redux";
import Cookies from "universal-cookie/es6";

const cookies = new Cookies();

const langReducer = (
	state = {
		lang: "en",
	},
	action: Action
) => {
	switch (action.type) {
		case "en":
			state.lang = "en";
			cookies.set("LANG", action.type, { path: "/" });
			return state;
		case "zh-hant":
			state.lang = "zh-hant";
			cookies.set("LANG", action.type, { path: "/" });
			return state;
		default:
			return state;
	}
};

const routeReducer = (state = { page: "" }, action: Action) => {
	switch (action.type) {
		case "Introduction":
			state.page = action.type;
			return state;
		case "Smetana":
			state.page = action.type;
			return state;
		case "Dvorak":
			state.page = action.type;
			return state;
		case "Janacek":
			state.page = action.type;
			return state;
		case "Martinu":
			state.page = action.type;
			return state;
		case "Ndb":
			state.page = action.type;
			return state;
		default:
			return state;
	}
};

const shareReducer = (state = { show: "Off" }, action: Action) => {
	switch (action.type) {
		case "On":
			state.show = "On";
			return state;
		case "Off":
			state.show = "Off";
			return state;
		default:
			return state;
	}
};

const combinedReducer = combineReducers({
	locale: langReducer,
	route: routeReducer,
	share: shareReducer,
});

export default combinedReducer;
