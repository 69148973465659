export const MAP = () => {
	return {
		type: "",
	};
};

export const INTRO = () => {
	return {
		type: "Introduction",
	};
};

export const SMETANA = () => {
	return {
		type: "Smetana",
	};
};

export const DVORAK = () => {
	return {
		type: "Dvorak",
	};
};

export const JANACEK = () => {
	return {
		type: "Janacek",
	};
};

export const MARTINU = () => {
	return {
		type: "Martinu",
	};
};

export const NDB = () => {
	return {
		type: "Ndb",
	};
};
