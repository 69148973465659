import React, { MouseEvent } from "react";
import "../scss/Component.scss";
import Tooltip from "./Tooltip";

interface propTypes {
	type: "Composer" | "Building";
	object: string;
	onEnter: (object: string) => void;
	onLeave: () => void;
	onClick: (e: MouseEvent, object: string) => void;
	h1MsgCode?: string;
	pMsgCode?: string;
	wordVal?: string;
	wordValLineBreak?: boolean;
}

const Map = ({
	type,
	object,
	onEnter,
	onLeave,
	onClick,
	h1MsgCode,
	pMsgCode,
	wordVal,
	wordValLineBreak,
}: propTypes) => {
	return (
		<div>
			<div
				className={type + " " + object}
				onMouseEnter={() => onEnter(object)}
				onMouseLeave={() => onLeave()}
				onClick={(e) => onClick(e, object)}
			/>
			<Tooltip
				objName={object}
				h1MsgCode={h1MsgCode}
				pMsgCode={pMsgCode}
				wordVal={wordVal}
				wordValLineBreak={wordValLineBreak}
			/>
		</div>
	);
};

export default Map;
