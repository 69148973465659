import React from "react";
import ReactDOM from "react-dom";
import "./scss/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore } from "redux";
import combinedReducer from "./Redux/Reducer";
import { Provider } from "react-redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import Menu from "./Components/Menu";
import { BrowserRouter } from "react-router-dom";

const store = createStore(combinedReducer, devToolsEnhancer({}));

const Root = () => {
	return (
		<Provider store={store}>
			<BrowserRouter forceRefresh={true}>
				<App />
			</BrowserRouter>
		</Provider>
	);
};

ReactDOM.render(
	<React.StrictMode>
		<Root />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
