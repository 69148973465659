import React, { useEffect, useLayoutEffect, useState } from "react";
import "../../scss/Martinu.scss";
import { useHistory } from "react-router-dom";
import * as Scroll from "react-scroll";
import Portrait from "../../Assets/Images/Martinu/Section1/Portrait.png";
import Church from "../../Assets/Images/Martinu/Section2/StJamesChurch.png";
import InParis from "../../Assets/Images/Martinu/Section5/InParis.png";
import MartinuIllustration from "../../Assets/Images/Martinu/Section5/MartinuIllustration.png";
import ArriveUSA from "../../Assets/Images/Martinu/Section5/ArriveUSA.jpeg";
import Iframe from "react-iframe";
import ChapterBG from "../../Assets/Images/Martinu/Martinu.png";
import * as ROUTE from "../../Redux/Actions/Route";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Constant from "../../Constant";
import findNearestSnap from "../../Utils/GeneralUtils";
import * as LANG from "../../Redux/Actions/Lang";

const Martinu = () => {
	const [footerHover, setFooterHover] = useState(false);
	const history = useHistory();
	const [offsetY, setOffsetY] = useState(0);

	const [currSlide, setCurrSlide] = useState("One");
	const [sliderClass, setSliderClass] = useState("One");

	const dispatch = useDispatch();
	dispatch(ROUTE.MARTINU());

	const intl = useIntl();
	// @ts-ignore
	const lang = useSelector((state) => state.locale.lang);

	const [isDrag, setIsDrag] = useState(false);

	useLayoutEffect(() => {
		function classChkOnReload() {
			var elements = document.getElementsByClassName("Section");
			for (var i = 0; i < elements.length; i++) {
				// @ts-ignore
				if (elements.item(i).offsetTop <= window.pageYOffset) {
					elements.item(i)?.classList.add("Active");
				}
			}
		}

		window.addEventListener("load", classChkOnReload);
		window.addEventListener("scroll", classChkOnReload);
		window.addEventListener("load", () => setOffsetY(window.pageYOffset));

		return () => {
			window.removeEventListener("load", classChkOnReload);
			window.removeEventListener("scroll", classChkOnReload);
			window.removeEventListener("load", () => setOffsetY(window.pageYOffset));
		};
	});

	useEffect(() => {
		window.name = "Martinu";

		const handleScroll = () => setOffsetY(window.pageYOffset);

		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	});

	function nextSlide() {
		switch (currSlide) {
			case "One":
				setCurrSlide("Two");
				setSliderClass("One2Two");
				break;
			case "Two":
				setCurrSlide("Three");
				setSliderClass("Two2Three");
				break;
			case "Three":
				setCurrSlide("Four");
				setSliderClass("Three2Four");
				break;
			default:
				break;
		}

		// @ts-ignore
		document.getElementById("ProgressBar")?.setAttribute("style", "");
	}

	function prevSlide() {
		switch (currSlide) {
			case "Four":
				setCurrSlide("Three");
				setSliderClass("Four2Three");
				break;
			case "Three":
				setCurrSlide("Two");
				setSliderClass("Three2Two");
				break;
			case "Two":
				setCurrSlide("One");
				setSliderClass("Two2One");
				break;
			default:
				break;
		}

		// @ts-ignore
		document.getElementById("ProgressBar")?.setAttribute("style", "");
	}

	function setSlide(slide: number) {
		switch (slide) {
			case 0:
				setCurrSlide("One");
				setSliderClass("Two2One");
				break;
			case 1:
				setCurrSlide("Two");
				setSliderClass("One2Two");
				break;
			case 2:
				setCurrSlide("Three");
				setSliderClass("Two2Three");
				break;
			case 3:
				setCurrSlide("Four");
				setSliderClass("Three2Four");
				break;
			default:
				break;
		}
	}

	return (
		<div
			className={
				"ChapterMartinu" + (lang === LANG.EN().type ? " ENG" : " ZH-HANT")
			}
		>
			<div className="Images ChapterBG">
				<img src={ChapterBG} alt="Marinu with his sister" />
			</div>
			<div className="ChapterCaption">
				<p>
					{intl.formatMessage({
						id: "Martinu_BGc",
						defaultMessage:
							"Bohuslav Martinů's first concert with his sister, Maria when he was 8 years old.",
					})}
				</p>
			</div>
			<div
				id="Section One"
				className="Section One"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Two")?.offsetTop
					);
					document.getElementById("Section Two")?.classList.add("Active");
				}}
			>
				<div className="DotsRect" />
				<div className="ImgContainer Portrait">
					<img src={Portrait} alt="Martinu Portrait" />
				</div>
				<div
					className="Ellipse Green"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				<div
					className="Ellipse Blue"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				{lang === LANG.EN().type ? (
					<div className="ChapterName" />
				) : (
					<div className="ChapterName ZH">
						<p>
							{window.innerWidth > 450
								? intl.formatMessage({ id: "Martinu" })
								: intl.formatMessage({ id: "Martinu_m" })}
						</p>
					</div>
				)}
				<div className="ChapterYear">
					<p>(1890-1959)</p>
				</div>
			</div>
			<div
				id="Section Two"
				className="Section Two"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Three")?.offsetTop
					);
				}}
			>
				<div
					className="clipContent"
					// @ts-ignore
					// style={{ opacity: `${window.pageYOffset / sectTwoPos}` }}
				>
					<div className="DotsRect" />
					<div
						className="Ellipse Blue"
						style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
					/>
					<div className="Paragraph One">
						<h1>
							{intl.formatMessage({
								id: "Martinu_p1h1",
								defaultMessage: "Unsung Concertos",
							})}
						</h1>
						<p>
							{intl.formatMessage({
								id: "Martinu_p1p",
								defaultMessage:
									"Martinů always paid close attention to avant-garde cosmopolitan musical developments. He was also acutely aware of his position as a composer of Czech national music. He composed in a variety of colourful styles and fluid forms, and his characteristic musical language gradually builds small musical cells over insistent rhythms. Jazz harmonies happily mingle within an intricate network of counterpoint and one sculptor described his compositions as “carved out of marble”.",
							})}
						</p>
					</div>
					<div className="ImgContainer Church">
						<img src={Church} alt="St.James Church" />
						<div className="Caption Church">
							<p>
								{intl.formatMessage({
									id: "Martinu_c1",
									defaultMessage:
										"The Birth Place of Martinů, St. James Church in Pardubice.",
								})}
							</p>
						</div>
					</div>
				</div>
				<div
					className="Ellipse Green"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
			</div>
			<div
				id="Section Three"
				className="Section Three"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Four")?.offsetTop
					);
					document.getElementById("Section Four")?.classList.add("Active");
				}}
			/>
			<div
				id="Section Four"
				className="Section Four"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Five")?.offsetTop
					);
					document.getElementById("Section Five")?.classList.add("Active");
				}}
			>
				<div>
					<h1>
						{intl.formatMessage({
							id: "Martinu_p2h1",
							defaultMessage: "Beginnings in Prague",
						})}
					</h1>
					<p>
						{intl.formatMessage({
							id: "Martinu_p2p",
							defaultMessage:
								"Martinů gave his first public performance as a solo violinist in his hometown at the age of five. The town immediately recognised his exceptional talent and funded his musical education at the Prague conservatory for a number of years. Martinů developed rapidly as a composer but, unable to make his mark in conservative Prague, he packed his bags and headed for Paris in 1923.",
						})}
					</p>
				</div>
			</div>
			<div
				id="Section Five"
				className="Section Five"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section FivePointOne")?.offsetTop
					);
					// document.getElementById("Section FivePointOne")?.classList.add("Active");
				}}
			>
				<div className="BGDots LeftDots" />
				<div className="BGDots RightDots" />
				<div id="InParis" className="Images InParis">
					<img
						src={InParis}
						alt="Martinu in Paris"
						style={{
							transform:
								"scale(" +
								(Math.min(
									Math.abs(
										offsetY -
											// @ts-ignore
											document.getElementById("InParis")?.offsetTop -
											// @ts-ignore
											document.getElementById("Section Five")?.offsetTop
									) /
										// @ts-ignore
										document.getElementById("InParis")?.offsetHeight,
									1
								) *
									Constant.photoMagnifier +
									1) +
								")",
						}}
					/>
					<div className="Caption InParis">
						<p>
							{window.innerWidth > 450
								? intl.formatMessage({
										id: "Martinu_c2",
										defaultMessage:
											"Bohuslav Martinů with the Czech community in Paris in 1938: Josef Páleníček (a composer), \nRudolf Firkušný (a pianist) and Karel Šebánek (a publisher).",
								  })
								: intl.formatMessage({
										id: "Martinu_m_c2",
										defaultMessage: "",
								  })}
						</p>
					</div>
				</div>
				<div className="Paragraph Two">
					<h1>
						{intl.formatMessage({
							id: "Martinu_p3h1",
							defaultMessage: "In Paris",
						})}
					</h1>
					<p>
						{intl.formatMessage({
							id: "Martinu_p3p",
							defaultMessage:
								"Ballet initially became Martinů’s favourite medium for experimentation. However, as the political situation worsened in Europe and back home in Czechoslovakia, Martinů’s music quickly reflected a rekindled consciousness of Czech folklore and culture. His premonitions are expressed in anguished lyricism brutally disturbed by a menacing angularity of the rhythm. His worst fears came true in the end, and in 1940 Martinů had to flee Paris after the Nazi invasion of the city as he had been blacklisted for his connections to the Czech resistance.",
						})}
					</p>
				</div>
			</div>
			<div
				id="Section FivePointOne"
				className="Section FivePointOne"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section FivePointTwo")?.offsetTop
					);
				}}
			>
				<div className="Title">
					{lang === LANG.EN().type ? (
						<div className="Špalíček" />
					) : (
						<div className="Špalíček">
							<h1>《斯帕利切克》</h1>
						</div>
					)}
					<div className="MusicNotes" />
				</div>
				<div
					id="MartinuIllustration"
					className="ImgContainer MartinuIllustration"
				>
					<div className="Images MartinuIllustration">
						<img
							src={MartinuIllustration}
							alt="Illustration of Martinu"
							style={{
								transform:
									"scale(" +
									(Math.min(
										Math.abs(
											offsetY -
												// @ts-ignore
												document.getElementById("MartinuIllustration")
													?.offsetTop -
												// @ts-ignore
												document.getElementById("Section FivePointOne")
													?.offsetTop
										) /
											// @ts-ignore
											document.getElementById("MartinuIllustration")
												?.offsetHeight,
										1
									) *
										Constant.photoMagnifier +
										1) +
									")",
							}}
						/>
					</div>
					<div className="Caption MartinuIllustration">
						<p>
							{window.innerWidth > 450
								? intl.formatMessage(
										{
											id: "Martinu_p6p",
											defaultMessage:
												"An illustration from {wordVal}, produced by the prolific painter and illustrator Mikoláš Alešd. It says, \n''I am a man from Moravia, my girl, believe me: \ni'm like a bird, I love you, i leave so''",
										},
										{ wordVal: <i>Špalíček</i> }
								  )
								: intl.formatMessage(
										{
											id: "Martinu_m_p6p",
											defaultMessage: "",
										},
										{ wordVal: <i>Špalíček</i> }
								  )}
						</p>
					</div>
				</div>
				<div className="RightHalf">
					<div className="Paragraph Three">
						<p>
							{intl.formatMessage(
								{
									id: "Martinu_p4p",
									defaultMessage:
										"Martinů’s three-act ballet {wordVal} premiered in Prague in 1932. Composed at a time when Martinů was deeply in love with a young Czech woman, he increasingly started to think of the folk culture of his native land. {wordVal}, according to scholars, might have referenced cheaply produced collections of folk tales and ballads, illustrated by genre pictures of life in Czech villages.",
								},
								{ wordVal: <i>Špalíček</i> }
							)}
						</p>
					</div>
					<div className="Paragraph Four">
						<p>
							{intl.formatMessage(
								{
									id: "Martinu_p5p",
									defaultMessage:
										"Martinů’s imagination may also have been fired by a collection of national songs and riddles titled {wordVal1}, produced by the prolific painter and illustrator Mikoláš Aleš. The ballet also originally included the cantata {wordVal2} with a text by the influential 19th-century poet and folklorist Karel Jaromír Erben.",
								},
								{
									wordVal1: <i>Špalíček</i>,
									wordVal2: <i>The Spectre’s Bride</i>,
								}
							)}
						</p>
					</div>
					<div className="Paragraph Six">
						<p>
							{intl.formatMessage(
								{
									id: "Martinu_p7p",
									defaultMessage:
										"In the event, Martinů described {wordVal} as “a ballet of popular plays, customs and fairy tales”. The narrative weaves together familiar fairy tales and Czech folk legends, and his music juxtaposes folk themes, ironic Baroque flourishes and a distinct modernism inspired by the group of French composers known as Les Six.",
								},
								{ wordVal: <i>Špalíček</i> }
							)}
						</p>
					</div>
				</div>
				<Iframe
					className="Spotify"
					url="https://open.spotify.com/embed/album/3brqjylTwXeDs7hARAvJ6z"
					width={(window.innerWidth > 450
						? window.innerWidth * 0.3
						: window.innerWidth * 0.85
					).toString()}
					height={(window.innerWidth > 450
						? window.innerWidth * 0.12
						: window.innerWidth * 0.4
					).toString()}
					allow="encrypted-media"
				/>
			</div>
			<div
				id="Section FivePointTwo"
				className="Section FivePointTwo"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section FivePointThree")?.offsetTop
					);
				}}
			>
				{lang === LANG.EN().type ? (
					<>
						<div
							className="QuoteMark"
							style={{
								left:
									Math.min(
										Math.max(
											(offsetY -
												// @ts-ignore
												document.getElementById("Section FivePointTwo")
													?.offsetTop +
												window.innerWidth * 0.55 * Constant.screenRatio) /
												((window.innerWidth * Constant.screenRatio) / 6),
											0
										),
										1
									) *
										13 +
									0 +
									"vw",
							}}
						/>
						<div
							className="QuoteMark Close"
							style={{
								left:
									Math.min(
										Math.max(
											(offsetY -
												// @ts-ignore
												document.getElementById("Section FivePointTwo")
													?.offsetTop +
												window.innerWidth * 0.55 * Constant.screenRatio) /
												((window.innerWidth * Constant.screenRatio) / 6),
											0
										),
										1
									) *
										13 +
									(window.innerWidth > 450 ? 68 : 65) +
									"vw",
							}}
						/>
					</>
				) : (
					<></>
				)}
				<div
					className="Quote"
					style={{
						left:
							Math.min(
								Math.max(
									(offsetY -
										// @ts-ignore
										document.getElementById("Section FivePointTwo")?.offsetTop +
										window.innerWidth * 0.55 * Constant.screenRatio) /
										((window.innerWidth * Constant.screenRatio) / 6),
									0
								),
								1
							) *
								13 +
							(window.innerWidth > 450
								? lang === LANG.EN().type
									? 11
									: 0
								: lang === LANG.EN().type
								? -5
								: -8) +
							"vw",
					}}
				>
					<p>
						{window.innerWidth > 450
							? intl.formatMessage({
									id: "Martinu_q1",
									defaultMessage:
										"My work is still Czech and \nconnected with my homeland.",
							  })
							: intl.formatMessage({
									id: "Martinu_m_q1",
									defaultMessage: "",
							  })}
					</p>
				</div>
			</div>
			<div
				id="Section FivePointThree"
				className="Section FivePointThree"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Six")?.offsetTop
					);
				}}
			>
				<div id="ArriveUSA" className="Images ArriveUSA">
					<img
						src={ArriveUSA}
						alt="Martinu Arrive from Europe to USA"
						style={{
							transform:
								"scale(" +
								(Math.min(
									Math.abs(
										offsetY -
											// @ts-ignore
											document.getElementById("ArriveUSA")?.offsetTop -
											// @ts-ignore
											document.getElementById("Section FivePointThree")
												?.offsetTop
									) /
										// @ts-ignore
										document.getElementById("ArriveUSA")?.offsetHeight,
									1
								) *
									Constant.photoMagnifier +
									1) +
								")",
						}}
					/>
				</div>
				<div className="Caption ArriveUSA">
					<p>
						{intl.formatMessage({
							id: "Martinu_c3",
							defaultMessage:
								"Bohuslav and Charlotte Martinů, probably shortly after arriving from Europe to the United States in 1941.© Center of Bohuslava Martinů, Polička",
						})}
					</p>
				</div>
				<div className="Paragraph Seven">
					<h1>
						{intl.formatMessage({
							id: "Martinu_p8h1",
							defaultMessage: "Exile in America",
						})}
					</h1>
					<p>
						{intl.formatMessage({
							id: "Martinu_p8p",
							defaultMessage:
								"Escaping Nazi oppression, Martinů found refuge in the United States. Struggling financially and eternally homesick, Martinů started to set folk song texts that became a symbolic connection to his beloved homeland. His dejected dreamlike vision of peace and longing for his country contrasted sharply with the imperfections of the real world. There was to be no happy ending for Martinů as the post-war advent of a communist government in Czechoslovakia prevented him from returning home.",
						})}
					</p>
				</div>
			</div>
			<div id="Section Six" className="Section Six Slider">
				<section
					className={"Slides " + sliderClass}
					onMouseMove={(e) => {
						if (isDrag) {
							var pbLeft =
								Math.min(
									Math.max(e.screenX, window.innerWidth * 0.2),
									window.innerWidth * 0.68
								) -
								window.innerWidth * 0.075;

							// @ts-ignore
							document.getElementById("ProgressBar").style.left = pbLeft + "px";
						}
					}}
					onMouseUp={(e) => {
						if (isDrag) {
							setIsDrag(false);

							var slideOneDiff = Math.abs(
								e.screenX - window.innerWidth * 0.275
							);

							var slideTwoDiff = Math.abs(
								e.screenX - window.innerWidth * 0.425
							);

							var slideThreeDiff = Math.abs(
								e.screenX - window.innerWidth * 0.575
							);

							var slideFourDiff = Math.abs(
								e.screenX - window.innerWidth * 0.725
							);

							var slide = findNearestSnap(
								slideOneDiff,
								slideTwoDiff,
								slideThreeDiff,
								slideFourDiff
							);

							// @ts-ignore
							document.getElementById("ProgressBar").style.left =
								slide * 15 + 20 + "%";

							setSlide(slide);
						}
					}}
				>
					<div className="Slide Zero">
						<div className="YellowEllipse" />
						<div className="ProgressBarBG" />
						<div
							id="ProgressBar"
							className="ProgressBar"
							onMouseDown={() => {
								setIsDrag(true);
							}}
						/>
						<div className="SliderBtn PrevSlide" onClick={() => prevSlide()} />
						<div className="SliderBtn NextSlide" onClick={() => nextSlide()} />
					</div>
					<div className="Slide One">
						<h1>
							{intl.formatMessage({
								id: "Martinu_Slh1",
								defaultMessage: "Opening of the Springs",
							})}
						</h1>
						<p>
							{window.innerWidth > 450
								? intl.formatMessage(
										{
											id: "Martinu_Sl1p",
											defaultMessage:
												"Forced to spend the final decades of his life in \nexile, Bohuslav Martinů (1890–1959) frequently visited his \nhomeland through his compositions. In a letter to his old friend, \nthe writer and poet Miroslav Bureš, Martinů suggested that the \npremiere of the Cantata {wordVal} (The Opening of \nthe Springs) might take place in their hometown of Polička. \n“That way,” he wrote, “at least part of me will be \nable to return home.",
										},
										{
											wordVal: <i>Otvírání studánek</i>,
											wordVal2: (
												<i>
													The Opening of <br />
													the Springs
												</i>
											),
										}
								  )
								: intl.formatMessage(
										{
											id: "Martinu_m_Sl1p",
											defaultMessage: "",
										},
										{
											wordVal: <i>Otvírání studánek</i>,
											wordVal2: (
												<i>
													The Opening of <br />
													the Springs
												</i>
											),
										}
								  )}
						</p>
						<div className="MusicNote" />
						<div className="FlippedClick" />
						<div className="ScrollGIF" />
					</div>
					<div className="Slide Two">
						<h1>
							{intl.formatMessage({
								id: "Martinu_Slh1",
								defaultMessage: "Opening of the Springs",
							})}
						</h1>
						<p>
							{window.innerWidth > 450
								? intl.formatMessage(
										{
											id: "Martinu_Sl2p",
											defaultMessage:
												"Discouraged and profoundly homesick, Martinů was deeply \ntouched by Bureš’s simple folk verses, which celebrated the landscape \nof the Czech-Moravian highlands and the local folk customs. \nEmotionally vested, Martinů crafted a loose cycle of four \nchamber cantatas. {wordVal} is one \nof the composer’s most intimate professions of \nlove for his native land.",
										},
										{ wordVal: <i>The Opening of the Springs</i> }
								  )
								: intl.formatMessage(
										{
											id: "Martinu_m_Sl2p",
											defaultMessage: "",
										},
										{ wordVal: <i>The Opening of the Springs</i> }
								  )}
						</p>
						<div className="MusicNote" />
					</div>
					<div className="Slide Three">
						<h1>
							{intl.formatMessage({
								id: "Martinu_Slh1",
								defaultMessage: "Opening of the Springs",
							})}
						</h1>
						<p>
							{window.innerWidth > 450
								? intl.formatMessage(
										{
											id: "Martinu_Sl3p",
											defaultMessage:
												"Inspired by the annual ritual of saying goodbye to winter by\ncleaning and reopening the sources of water, Martinů composed music \nintimately bound to the customs and speech of traditional men and women \nfrom his home region. Scored for two violins, viola and piano, and featuring a \nfemale chorus, baritone solos and a reciter, {wordVal} \nretains the spirit of Moravian folk songs while imparting them with \na universal quality that elevates Martinů’s musical expressions \nbeyond the narrow borders of patriotic folklore.",
										},
										{ wordVal: <i>The Opening of the Springs</i> }
								  )
								: intl.formatMessage(
										{
											id: "Martinu_m_Sl3p",
											defaultMessage: "",
										},
										{ wordVal: <i>The Opening of the Springs</i> }
								  )}
						</p>
						<div className="MusicNote" />
					</div>
					<div className="Slide Four">
						<Iframe
							className="Spotify"
							url="https://open.spotify.com/embed/album/3A9yULLlLy5W6sY68v6D3x"
							width={(window.innerWidth > 450
								? window.innerWidth * 0.4
								: window.innerWidth * 0.85
							).toString()}
							height={(window.innerWidth > 450
								? window.innerWidth * 0.13
								: window.innerWidth * 0.6
							).toString()}
							allow="encrypted-media"
						/>
					</div>
				</section>
			</div>
			<div id="Section Seven" className="Legacy">
				<div className="DotsRect" />
				<div className="Paragraph Eight">
					<h1>
						{intl.formatMessage({ id: "Legacy", defaultMessage: "Legacy" })}
					</h1>
					<p>
						{intl.formatMessage({
							id: "Martinu_legacy",
							defaultMessage:
								"Martinů’s musical legacy consists of almost 400 compositions, ranging from choral works and operas to symphonies and a great deal of chamber music. Always searching for the meaning of life and for the truth, he once wrote: “a system of uncertainty has entered our daily life. The pressures of mechanisation and uniformity to which it is subject call for protest and the artist has only one means of expressing this—music.”",
						})}
					</p>
				</div>
				<div
					className="Ellipse Left"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				<div
					id="FooterSwitch"
					className={"FooterSwitch" + (footerHover ? " Hover" : "")}
				>
					<div
						id="Ellipse Right"
						className="Ellipse Right"
						style={{
							transform: `translateY(-${offsetY * 0.5}px)`,
							top:
								// @ts-ignore
								(document.getElementById("Section Seven")?.offsetTop +
									window.innerHeight -
									// @ts-ignore
									document.getElementById("Section Seven")?.offsetHeight) /
									2 -
								window.innerHeight +
								"px",
						}}
					/>
					<div
						className="FooterBtn"
						onMouseEnter={() => {
							setFooterHover(true);
							// @ts-ignore
							document.getElementById("Ellipse Right").style.top =
								// @ts-ignore
								(document.getElementById("Section Seven")?.offsetTop +
									window.innerHeight -
									// @ts-ignore
									document.getElementById("Section Seven")?.offsetHeight) /
									2 -
								window.innerHeight +
								// @ts-ignore
								document.getElementById("FooterSwitch")?.offsetTop +
								// @ts-ignore
								document.getElementById("NextChapter")?.offsetTop / 1.6 +
								"px";
						}}
						onMouseLeave={() => {
							setFooterHover(false);
							// @ts-ignore
							document.getElementById("Ellipse Right").style.top =
								// @ts-ignore
								(document.getElementById("Section Seven")?.offsetTop +
									window.innerHeight -
									// @ts-ignore
									document.getElementById("Section Seven")?.offsetHeight) /
									2 -
								window.innerHeight +
								"px";
						}}
						onClick={() => {
							history.push("/Ndb");
						}}
					>
						<div className="FooterName">
							<p>{intl.formatMessage({ id: "NationalTheatreBrno_C" })}</p>
						</div>
						<div id="NextChapter" className="NextChapter">
							<p>
								{intl.formatMessage({
									id: "NextChapter",
									defaultMessage: "Next Chapter",
								})}
							</p>
							<div className="FooterArrow" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Martinu;
