export const EN = () => {
  return {
    type: "en",
  };
};

export const ZH_HANT = () => {
  return {
    type: "zh-hant",
  };
};
