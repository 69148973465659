import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../scss/Dvorak.scss";
import * as Scroll from "react-scroll";
import Iframe from "react-iframe";
import Portrait from "../../Assets/Images/Dvorak/Section1/Portrait.png";
import NewYorkPhoto from "../../Assets/Images/Dvorak/Section3/NewYorkPhoto.png";
import SlavonicDances from "../../Assets/Images/Dvorak/Section4/SlavonicDances.png";
import Signature from "../../Assets/Images/Dvorak/Section4/Signature.png";
import RůženaMaturová from "../../Assets/Images/Dvorak/Section5/RůženaMaturová.jpg";
import RusalkaVideo from "../../Assets/Images/Dvorak/Section5/Rusalka.mp4";
import * as ROUTE from "../../Redux/Actions/Route";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Constant from "../../Constant";
import findNearestSnap from "../../Utils/GeneralUtils";
import * as LANG from "../../Redux/Actions/Lang";

const Dvorak = () => {
	const [footerHover, setFooterHover] = useState(false);
	const history = useHistory();
	const [offsetY, setOffsetY] = useState(0);

	const [currSlide, setCurrSlide] = useState("One");
	const [sliderClass, setSliderClass] = useState("One");

	const dispatch = useDispatch();
	dispatch(ROUTE.DVORAK());

	const intl = useIntl();
	// @ts-ignore
	const lang = useSelector((state) => state.locale.lang);

	const [isDrag, setIsDrag] = useState(false);

	useLayoutEffect(() => {
		function classChkOnReload() {
			var elements = document.getElementsByClassName("Section");
			for (var i = 0; i < elements.length; i++) {
				// @ts-ignore
				if (elements.item(i).offsetTop <= window.pageYOffset) {
					elements.item(i)?.classList.add("Active");
				}
			}
		}

		window.addEventListener("load", classChkOnReload);
		window.addEventListener("scroll", classChkOnReload);
		window.addEventListener("load", () => setOffsetY(window.pageYOffset));

		return () => {
			window.removeEventListener("load", classChkOnReload);
			window.removeEventListener("scroll", classChkOnReload);
			window.removeEventListener("load", () => setOffsetY(window.pageYOffset));
		};
	});

	useEffect(() => {
		window.name = "Dvorak";

		const handleScroll = () => setOffsetY(window.pageYOffset);

		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	});

	function nextSlide() {
		switch (currSlide) {
			case "One":
				setCurrSlide("Two");
				setSliderClass("One2Two");
				break;
			case "Two":
				setCurrSlide("Three");
				setSliderClass("Two2Three");
				break;
			case "Three":
				setCurrSlide("Four");
				setSliderClass("Three2Four");
				break;
			case "Four":
				setCurrSlide("Five");
				setSliderClass("Four2Five");
				break;
			default:
				break;
		}

		// @ts-ignore
		document.getElementById("ProgressBar")?.setAttribute("style", "");
	}

	function prevSlide() {
		switch (currSlide) {
			case "Five":
				setCurrSlide("Four");
				setSliderClass("Five2Four");
				break;
			case "Four":
				setCurrSlide("Three");
				setSliderClass("Four2Three");
				break;
			case "Three":
				setCurrSlide("Two");
				setSliderClass("Three2Two");
				break;
			case "Two":
				setCurrSlide("One");
				setSliderClass("Two2One");
				break;
			default:
				break;
		}

		// @ts-ignore
		document.getElementById("ProgressBar")?.setAttribute("style", "");
	}

	function setSlide(slide: number) {
		switch (slide) {
			case 0:
				setCurrSlide("One");
				setSliderClass("Two2One");
				break;
			case 1:
				setCurrSlide("Two");
				setSliderClass("One2Two");
				break;
			case 2:
				setCurrSlide("Three");
				setSliderClass("Two2Three");
				break;
			case 3:
				setCurrSlide("Four");
				setSliderClass("Three2Four");
				break;
			case 4:
				setCurrSlide("Five");
				setSliderClass("Four2Five");
				break;
			default:
				break;
		}

		if (slide === 3) {
			// @ts-ignore
			document.getElementById("RusalkaVideo")?.play();
		}
	}

	return (
		<div
			className={
				"ChapterDvorak" + (lang === LANG.EN().type ? " ENG" : " ZH-HANT")
			}
		>
			<div
				id="Section One"
				className="Section One"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Two")?.offsetTop
					);
					document.getElementById("Section Two")?.classList.add("Active");
				}}
			>
				<div className="DotsRect" />
				<div className="ImgContainer Portrait">
					<img src={Portrait} alt="Dvorak Portrait" />
				</div>
				<div
					className="Ellipse Green"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				<div
					className="Ellipse Blue"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				{lang === LANG.EN().type ? (
					<div className="ChapterName" />
				) : (
					<div className="ChapterName ZH">
						<p>
							{window.innerWidth > 450
								? intl.formatMessage({ id: "Dvorak" })
								: intl.formatMessage({ id: "Dvorak_m" })}
						</p>
					</div>
				)}
				<div className="ChapterYear">
					<p>(1841-1904)</p>
				</div>
			</div>
			<div
				id="Section Two"
				className="Section Two"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Three")?.offsetTop
					);
				}}
			>
				<div
					className="clipContent"
					// @ts-ignore
					// style={{ opacity: `${window.pageYOffset / sectTwoPos}` }}
				>
					<div className="DotsRect" />
					<div
						className="Ellipse Blue"
						style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
					/>
					<div className="Paragraph One">
						<h1>
							{intl.formatMessage({
								id: "Dvorak_p1h1",
								defaultMessage: "Dvořák Reloaded",
							})}
						</h1>
						<p>
							{intl.formatMessage({
								id: "Dvorak_p1p",
								defaultMessage:
									"The traditional image of Antonín Dvořák as a simple Czech with a flair for composing symphonic and chamber music has recently given way to one of a complex figure writing works filled with hidden drama and secret programs. Dvořák carefully cultivated a Czech musical identity throughout his life, but he was at home in a variety of different environments. In fact, he may well have been the most versatile composer of his time.",
							})}
						</p>
					</div>
					<div className="Paragraph Two">
						<h1>
							{window.innerWidth > 450
								? intl.formatMessage({
										id: "Dvorak_p2h1",
										defaultMessage:
											"A Violist in Provisional Theatre Orchestra",
								  })
								: intl.formatMessage({
										id: "Dvorak_m_p2h1",
										defaultMessage: "",
								  })}
						</h1>
						<p>
							{intl.formatMessage({
								id: "Dvorak_p2p",
								defaultMessage:
									"Dvořák broadened his musical horizon as the principal violist at the newly built Provisional Theatre. When Smetana took over as principal conductor, Dvořák gained first-hand knowledge of compositions crafted by Czech and Slavic composers. Inspired by Smetana, he began intensive studies of various folk music collections. In his compositions, Dvořák turned towards a new classicism with elements of Slavonic folklore starting to permeate his musical language.",
							})}
						</p>
					</div>
				</div>
				<div
					className="Ellipse Green"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
			</div>
			<div
				id="Section Three"
				className="Section Three"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Four")?.offsetTop
					);
					document.getElementById("Section Four")?.classList.add("Active");
				}}
			>
				<div id="NewYorkPhoto" className="Images NewYorkPhoto">
					<img
						src={NewYorkPhoto}
						alt="Dvorak in New York"
						style={{
							transform:
								"scale(" +
								(Math.min(
									Math.abs(
										offsetY -
											// @ts-ignore
											document.getElementById("NewYorkPhoto")?.offsetTop -
											// @ts-ignore
											document.getElementById("Section Three")?.offsetTop
									) /
										// @ts-ignore
										document.getElementById("NewYorkPhoto")?.offsetHeight,
									1
								) *
									Constant.photoMagnifier +
									1) +
								")",
						}}
					/>
				</div>
				<div className="Caption NewYorkPhoto">
					<p>
						{intl.formatMessage({
							id: "Dvorak_c1",
							defaultMessage:
								"Czech composer Antonín Dvořák (1841-1904) with friends and family in New York.",
						})}
					</p>
				</div>
				<div className="Paragraph One">
					<h1>
						{intl.formatMessage({
							id: "Dvorak_p3h1",
							defaultMessage: "International Recognition",
						})}
					</h1>
					<p>
						{intl.formatMessage(
							{
								id: "Dvorak_p3p",
								defaultMessage:
									"Dvořák was profoundly nationalist in many respects. Nevertheless, among the great composers of the 19th century, his cosmopolitan musical tendencies were probably equalled only by Liszt and Tchaikovsky. Many features of his musical language that are supposedly clear signs of nationalism are in fact unequivocal gestures of internationalism. Dvořák became the first Czech composer of international renown, and his {wordVal} created an eager market for his music at home and abroad.",
							},
							{ wordVal: <i>Slavonic Dances</i> }
						)}
					</p>
				</div>
				<div className="BGDots RightDots" />
			</div>
			<div
				id="Section Four"
				className="Section Four"
				onClick={() => {
					if (window.innerWidth > 450) {
						if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section Four")?.offsetTop +
								window.innerHeight * 0.49
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Four")?.offsetTop +
									window.innerHeight * 0.5
							);
						} else {
							Scroll.animateScroll.scrollTo(
								//@ts-ignore
								document.getElementById("Section FourPointOne")?.offsetTop
							);
						}
					} else {
						if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section Four")?.offsetTop +
								window.innerHeight * 0.85
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Four")?.offsetTop +
									window.innerHeight * 0.86
							);
						} else {
							Scroll.animateScroll.scrollTo(
								//@ts-ignore
								document.getElementById("Section FourPointOne")?.offsetTop
							);
						}
					}
				}}
			>
				<div>
					<div className="Paragraph Zero">
						<div className="Title">
							<h1>
								{intl.formatMessage({
									id: "Dvorak_p4h1",
									defaultMessage: "Slavonic Dance",
								})}
							</h1>
							<div className="MusicNotes" />
						</div>
						<p>
							{intl.formatMessage(
								{
									id: "Dvorak_p4p",
									defaultMessage:
										"Antonín Dvořák was a relatively unknown composer when he applied for the Austrian State Prize fellowship. He managed to win it three times in four years, and Johannes Brahms referred Dvořák to his own publisher Fritz Simrock. The publisher first issued Dvořák’s {wordVal} and, encouraged by its resounding financial and popular success, he asked the composer to write something with a dance-like character.",
								},
								{ wordVal: <i>Moravian Duets</i> }
							)}
						</p>
					</div>
					<div id="SlavonicDances" className="ImgContainer SlavonicDances">
						<div className="Images SlavonicDances">
							<img
								src={SlavonicDances}
								alt="Title Page of SlavonicDances"
								style={{
									transform:
										"scale(" +
										(Math.min(
											Math.abs(
												offsetY -
													// @ts-ignore
													document.getElementById("SlavonicDances")?.offsetTop -
													// @ts-ignore
													document.getElementById("Section Four")?.offsetTop
											) /
												// @ts-ignore
												document.getElementById("SlavonicDances")?.offsetHeight,
											1
										) *
											Constant.photoMagnifier +
											1) +
										")",
								}}
							/>
						</div>
						<div className="Caption SlavonicDances">
							<p>
								{intl.formatMessage(
									{
										id: "Dvorak_c2",
										defaultMessage:
											"The title page of the first series of {wordVal} with Antonín Dvořák's dedication to Mr. Wassman.",
									},
									{ wordVal: <i>Slavonic Dances</i> }
								)}
							</p>
						</div>
					</div>
					<div className="RightHalf">
						<div className="Paragraph Three">
							<p>
								{intl.formatMessage(
									{
										id: "Dvorak_p5p",
										defaultMessage:
											"Taking Brahms’s {wordVal1} as a model, Dvořák wrote a total of 16 dances for piano four hands, which he subsequently orchestrated. The {wordVal2} (Slovanské tance) emerged in two sets, as Opus 46 and Opus 72. Dvořák’s biographer Otakar Šourek heard in the Dances",
									},
									{
										wordVal1: <i>Hungarian Dances</i>,
										wordVal2: <i>Slavonic Dances</i>,
										wordVal3: <i>Slovanské tance</i>,
									}
								)}
							</p>
							<div className="Quote One">
								<p>
									{window.innerWidth > 450
										? intl.formatMessage({
												id: "Dvorak_q1",
												defaultMessage:
													'"the humour and pride, the optimism and passion of \nthe Czech people come to life, and in it breathes \nthe sweet fragrance and unspoiled beauty of \nCzech woods and meadows."',
										  })
										: intl.formatMessage({
												id: "Dvorak_m_q1",
												defaultMessage: "",
										  })}
								</p>
							</div>
							<Iframe
								className="Spotify"
								url="https://open.spotify.com/embed/album/6IbnvS9Nl3KXTByW2HIktE"
								width={(window.innerWidth > 450
									? window.innerWidth * 0.3
									: window.innerWidth * 0.85
								).toString()}
								height={(window.innerWidth > 450
									? window.innerWidth * 0.14
									: window.innerWidth * 0.4
								).toString()}
								allow="encrypted-media"
							/>
						</div>
					</div>
					<div className="Paragraph Four">
						<p>
							{intl.formatMessage(
								{
									id: "Dvorak_p6p",
									defaultMessage:
										"Dvořák brilliantly translated the characteristic rhythms of Slavic folk music into extended stylish numbers. Referencing, among others, the Czech folk dances the skočná, the sousedská, the dumka and the furiant, Dvořák created something truly original from traditional elements. The {wordVal}, lively and intended to be overtly nationalistic, were received enthusiastically at the time and still rank among the composer’s most memorable compositions today.",
								},
								{ wordVal: <i>Slavonic Dances</i> }
							)}
						</p>
					</div>
				</div>
				<div className="BGDots RightDots" />
			</div>
			<div
				id="Section FourPointOne"
				className="Section FourPointOne"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section FourPointTwo")?.offsetTop
					);
				}}
			>
				{lang === LANG.EN().type ? (
					<>
						<div
							className="QuoteMark"
							style={{
								left:
									Math.min(
										Math.max(
											(offsetY -
												// @ts-ignore
												document.getElementById("Section FourPointOne")
													?.offsetTop +
												window.innerWidth * 0.7 * Constant.screenRatio) /
												((window.innerWidth * Constant.screenRatio) / 6),
											0
										),
										1
									) *
										13 -
									(window.innerWidth > 450 ? 1 : 0) +
									"vw",
							}}
						/>
						<div
							className="QuoteMark Close"
							style={{
								left:
									Math.min(
										Math.max(
											(offsetY -
												// @ts-ignore
												document.getElementById("Section FourPointOne")
													?.offsetTop +
												window.innerWidth * 0.7 * Constant.screenRatio) /
												((window.innerWidth * Constant.screenRatio) / 6),
											0
										),
										1
									) *
										13 +
									(window.innerWidth > 450 ? 55 : 60) +
									"vw",
							}}
						/>
					</>
				) : (
					<></>
				)}
				<div
					className="Quote Two"
					style={{
						left:
							Math.min(
								Math.max(
									(offsetY -
										// @ts-ignore
										document.getElementById("Section FourPointOne")?.offsetTop +
										window.innerWidth * 0.7 * Constant.screenRatio) /
										((window.innerWidth * Constant.screenRatio) / 6),
									0
								),
								1
							) *
								13 +
							(window.innerWidth > 450
								? lang === LANG.EN().type
									? 8
									: 5
								: lang === LANG.EN().type
								? -4
								: -3) +
							"vw",
					}}
				>
					<p
						style={{
							fontSize:
								window.innerWidth > 450
									? lang === LANG.EN().type
										? "7vw"
										: "5vw"
									: lang === LANG.EN().type
									? "12vw"
									: "9vw",
							lineHeight:
								window.innerWidth > 450
									? lang === LANG.EN().type
										? "7vw"
										: "6vw"
									: lang === LANG.EN().type
									? "12vw"
									: "9vw",
						}}
					>
						{window.innerWidth > 450
							? intl.formatMessage({
									id: "Dvorak_q2",
									defaultMessage:
										"The music of the people is like a \nrare and lovely flower growing \namidst encroaching weed.",
							  })
							: intl.formatMessage({
									id: "Dvorak_m_q2",
									defaultMessage: "",
							  })}
					</p>
				</div>
				<div className="ImgContainer Signature">
					<img src={Signature} alt="Dvorak's Signature" />
				</div>
				<div className="BGDots LeftDots" />
			</div>
			<div
				id="Section FourPointTwo"
				className="Section FourPointTwo"
				onClick={() => {
					if (window.innerWidth > 450) {
						Scroll.animateScroll.scrollTo(
							//@ts-ignore
							document.getElementById("Section Five")?.offsetTop
						);
					} else {
						if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section FourPointTwo")?.offsetTop +
								window.innerHeight * 0.39
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section FourPointTwo")?.offsetTop +
									window.innerHeight * 0.4
							);
						} else {
							Scroll.animateScroll.scrollTo(
								//@ts-ignore
								document.getElementById("Section Five")?.offsetTop
							);
						}
					}
				}}
			>
				<div className="Paragraph Five">
					<h1>
						{intl.formatMessage({
							id: "Dvorak_p7h1",
							defaultMessage: "Self-Reflection",
						})}
					</h1>
					<p>
						{intl.formatMessage({
							id: "Dvorak_p7p",
							defaultMessage:
								"Nature played a major role in Dvořák’s life and work, and Slavonic folklore became part of his compositional toolbox. Nature and folk customs became a powerful source of inspiration for the composer, and much of his music closely resembles a landscape painting in its richness of colour, evocation of place and people, sense of grandeur and unfolding vistas.",
						})}
					</p>
				</div>
				<div className="Paragraph Six">
					<p>
						{intl.formatMessage(
							{
								id: "Dvorak_p8p",
								defaultMessage:
									"Dvořák frequently visited a country estate in the tiny village of Vysoká, and a small path behind the mansion leads to Rusalka Lake. Sitting by the pond for hours on end provided the composer with the inspiration for more than thirty works, including his world-famous opera {wordVal}.",
							},
							{ wordVal: <i>Rusalka</i> }
						)}
					</p>
				</div>
				<div className="DvořákMemorial" />
				<div className="DvorakMini" />
			</div>
			<div id="Section Five" className="Section Five Slider">
				<section
					className={"Slides " + sliderClass}
					onMouseMove={(e) => {
						if (isDrag) {
							var pbLeft =
								Math.min(
									Math.max(e.screenX, window.innerWidth * 0.2),
									window.innerWidth * 0.68
								) -
								window.innerWidth * 0.06;

							// @ts-ignore
							document.getElementById("ProgressBar").style.left = pbLeft + "px";
						}
					}}
					onMouseUp={(e) => {
						if (isDrag) {
							setIsDrag(false);

							var slideOneDiff = Math.abs(e.screenX - window.innerWidth * 0.26);

							var slideTwoDiff = Math.abs(e.screenX - window.innerWidth * 0.38);

							var slideThreeDiff = Math.abs(
								e.screenX - window.innerWidth * 0.5
							);

							var slideFourDiff = Math.abs(
								e.screenX - window.innerWidth * 0.62
							);

							var slideFiveDiff = Math.abs(
								e.screenX - window.innerWidth * 0.74
							);

							var slide = findNearestSnap(
								slideOneDiff,
								slideTwoDiff,
								slideThreeDiff,
								slideFourDiff,
								slideFiveDiff
							);

							// @ts-ignore
							document.getElementById("ProgressBar").style.left =
								slide * 12 + 20 + "%";

							setSlide(slide);
						}
					}}
				>
					<div className="Slide Zero">
						<div className="YellowEllipse" />
						<div className="ProgressBarBG" />
						<div
							id="ProgressBar"
							className="ProgressBar"
							onMouseDown={() => {
								setIsDrag(true);
								// @ts-ignore
								document.getElementById("RusalkaVideo")?.pause();
							}}
						/>
						<div
							className="SliderBtn PrevSlide"
							onClick={() => {
								prevSlide();
								// @ts-ignore
								document.getElementById("RusalkaVideo").pause();
								if (currSlide === "Five") {
									// @ts-ignore
									document.getElementById("RusalkaVideo")?.play();
								}
							}}
						/>
						<div
							className="SliderBtn NextSlide"
							onClick={() => {
								nextSlide();
								// @ts-ignore
								document.getElementById("RusalkaVideo").pause();
								if (currSlide === "Three") {
									// @ts-ignore
									document.getElementById("RusalkaVideo")?.play();
								}
							}}
						/>
					</div>
					<div className="Slide One">
						<h1>
							{intl.formatMessage({
								id: "Dvorak_Slh1",
								defaultMessage: "Rusalka",
							})}
						</h1>
						<p>
							{window.innerWidth > 450
								? intl.formatMessage(
										{
											id: "Dvorak_Sl1p",
											defaultMessage:
												"From the very beginning of his creative career, Antonín Dvořák \nregarded opera as a central pillar of his compositional craft. Active \nduring a period when the idea of Czech national opera was being \nformulated, Dvořák adopted three opera topoi for his own work. \nRural Czech village life with its typical characters, subjects \nfrom extended Slavonic history, and Czech stories and \nfairy tales, including {wordVal}.",
										},
										{ wordVal: <i>Rusalka</i> }
								  )
								: intl.formatMessage(
										{
											id: "Dvorak_m_Sl1p",
											defaultMessage: "",
										},
										{ wordVal: <i>Rusalka</i> }
								  )}
						</p>
						<div className="MusicNote" />
						<div className="FlippedClick" />
						<div className="ScrollGIF" />
					</div>
					<div className="Slide Two">
						<h1>
							{intl.formatMessage({
								id: "Dvorak_Slh1",
								defaultMessage: "Rusalka",
							})}
						</h1>
						<p>
							{window.innerWidth > 450
								? intl.formatMessage(
										{
											id: "Dvorak_Sl2p",
											defaultMessage:
												"Jaroslav Kvapil, who consulted a number of literary sources—\nincluding Fouqué’s {wordVal1}, Hans Christian Andersen’s {wordVal2}, the French legend of Melusine, and Karel Jaromír Erben’s {wordVal3}—penned the libretto for this lyrical fairy tale. \nMaking use of materials notated in his American sketchbooks, the \ncomposer became absorbed with the work and produced one \nof his most fluent compositions of his career.",
										},
										{
											wordVal1: <i>Undine</i>,
											wordVal2: (
												<i>
													The Little <br />
													Mermaid
												</i>
											),
											wordVal3: (
												<i>
													A <br />
													Garland of National Tales
												</i>
											),
										}
								  )
								: intl.formatMessage(
										{
											id: "Dvorak_m_Sl2p",
											defaultMessage: "",
										},
										{
											wordVal1: <i>Undine</i>,
											wordVal2: <i>The Little Mermaid</i>,
											wordVal3: <i>A Garland of National Tales</i>,
										}
								  )}
						</p>
						<div className="MusicNote" />
					</div>
					<div className="Slide Three">
						<div className="ImgContainer RůženaMaturová">
							<img
								src={RůženaMaturová}
								alt="Růžena Maturová as the first Rusalka"
							/>
						</div>
						<div className="Caption RůženaMaturová">
							<p>
								{intl.formatMessage(
									{
										id: "Dvorak_Sl3c1",
										defaultMessage:
											"{wordVal} was first performed in Prague on 31 March 1901, with Růžena Maturová as the first Rusalka.",
									},
									{ wordVal: <i>Rusalk</i> }
								)}
							</p>
						</div>
					</div>
					<div className="Slide Four">
						<div className="VideoContainer">
							<video
								id="RusalkaVideo"
								className="Video"
								controls
								controlsList="nodownload"
							>
								<source src={RusalkaVideo} type="video/mp4" />
							</video>
						</div>
					</div>
					<div className="Slide Five">
						<h1>
							{intl.formatMessage({
								id: "Dvorak_Slh1",
								defaultMessage: "Rusalka",
							})}
						</h1>
						<p>
							{window.innerWidth > 450
								? intl.formatMessage({
										id: "Dvorak_Sl5p",
										defaultMessage:
											"The title character Rusalka is an immortal water \nnymph who has fallen in love with a mortal. Rusalka decides to give \nup her immortality to pursue true love; however, the prince she loves is \nno hero and he abandons her. Eventually, he realises his mistake, and \nwillingly makes his own ultimate sacrifice to restore her immortality. \nWhile the story was criticised for its lack of naturalism, it \nnevertheless became Dvořák’s most popular opera \nboth in the composer’s native land and abroad.",
								  })
								: intl.formatMessage({
										id: "Dvorak_m_Sl5p",
										defaultMessage: "",
								  })}
						</p>
						<div className="MusicNote" />
						<div className={"FooterSwitch" + (footerHover ? " Hover" : "")}>
							<div
								className="FooterBtn"
								onMouseEnter={() => {
									setFooterHover(true);
								}}
								onMouseLeave={() => {
									setFooterHover(false);
								}}
								onClick={() => {
									history.push("/Janacek");
								}}
							>
								<div className="FooterName">
									<p>
										{intl.formatMessage({
											id: "Janacek",
										})}
									</p>
								</div>
								<div className="NextChapter">
									<p>
										{intl.formatMessage({
											id: "NextChapter",
											defaultMessage: "Next Chapter",
										})}
									</p>
									<div className="FooterArrow" />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Dvorak;
