import React from "react";
import { useIntl } from "react-intl";
import "../scss/Tooltip.scss";

interface propTypes {
	objName: string;
	h1MsgCode?: string;
	pMsgCode?: string;
	wordVal?: string;
	wordValLineBreak?: boolean;
}

const Tooltip = ({
	objName,
	h1MsgCode,
	pMsgCode,
	wordVal,
	wordValLineBreak,
}: propTypes) => {
	const intl = useIntl();

	return (
		<div className={"Tooltip " + objName}>
			{h1MsgCode ? (
				wordValLineBreak ? (
					<>
						<h1>{intl.formatMessage({ id: h1MsgCode })}</h1>
						<h1 style={{ marginTop: "0", marginBottom: "0", fontSize: "1vw" }}>
							{wordVal}
						</h1>
					</>
				) : (
					<h1>
						{intl.formatMessage(
							{ id: h1MsgCode },
							{
								wordVal: <h1>{wordVal}</h1>,
							}
						)}
					</h1>
				)
			) : (
				<></>
			)}
			{pMsgCode ? <p>{intl.formatMessage({ id: pMsgCode })}</p> : <></>}
		</div>
	);
};

export default Tooltip;
