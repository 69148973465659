import React from "react";
import "../scss/Intro.scss";
import * as ROUTE from "../Redux/Actions/Route";
import { useDispatch } from "react-redux";

const Intro = () => {
	const dispatch = useDispatch();
	dispatch(ROUTE.INTRO());

	return (
		<div className="Intro">
			<div className="IntroBG" />
			<div className="BlackMask" />
			<div>
				<h1>Introduction</h1>
				<p>
					The fertile cultural history of the regions of Bohemia and Moravia is
					amply reflected in art, architecture, literature and music. For
					centuries, rich ethnic and folk music traditions have inspired
					numerous composers working in this enchanted realm. While Bohemia
					emerged as a social and cultural phenomenon bound to a movement that
					sought to revive the Czech language, culture and national identity,
					Moravia maintained a musical tradition distinct from the folk music of
					neighbouring provinces.
				</p>
			</div>
		</div>
	);
};

export default Intro;
