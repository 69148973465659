import React, { useEffect, useLayoutEffect, useState } from "react";
import "../../scss/Smetana.scss";
import Portrait from "../../Assets/Images/Smetana/Section1/Portrait.png";
import SmetanaSign from "../../Assets/Images/Smetana/Section1/Signature.png";
import Poster from "../../Assets/Images/Smetana/Section6/Poster.jpg";
import Iframe from "react-iframe";
import * as Scroll from "react-scroll";
import { useHistory } from "react-router-dom";
import BBVideo from "../../Assets/Images/Smetana/Section5/BarteredBride.mp4";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as LANG from "../../Redux/Actions/Lang";
import * as ROUTE from "../../Redux/Actions/Route";
import Constant from "../../Constant";
import findNearestSnap from "../../Utils/GeneralUtils";

const Smetana = () => {
  const [footerHover, setFooterHover] = useState(false);
  const history = useHistory();
  const [offsetY, setOffsetY] = useState(0);

  const [currSlide, setCurrSlide] = useState("One");
  const [sliderClass, setSliderClass] = useState("One");

  const intl = useIntl();
  // @ts-ignore
  const lang = useSelector((state) => state.locale.lang);

  const [isDrag, setIsDrag] = useState(false);

  const dispatch = useDispatch();
  dispatch(ROUTE.SMETANA());

  useLayoutEffect(() => {
    function classChkOnReload() {
      var elements = document.getElementsByClassName("Section");
      for (var i = 0; i < elements.length; i++) {
        // @ts-ignore
        if (elements.item(i).offsetTop <= window.pageYOffset) {
          elements.item(i)?.classList.add("Active");
        }
      }
    }

    function stopVideo() {
      if (
        window.pageYOffset >
          // @ts-ignore
          document.getElementById("Section Four")?.offsetTop &&
        // @ts-ignore
        window.pageYOffset < document.getElementById("Section Six")?.offsetTop
      ) {
        var fourFiveMidPt =
          // @ts-ignore
          (document.getElementById("Section Four")?.offsetTop +
            // @ts-ignore
            document.getElementById("Section Five")?.offsetTop) /
          2;
        var fiveSixMidPt =
          // @ts-ignore
          (document.getElementById("Section Five")?.offsetTop +
            // @ts-ignore
            document.getElementById("Section FivePointFive")?.offsetTop) /
          2;
        if (
          window.pageYOffset < fourFiveMidPt ||
          window.pageYOffset > fiveSixMidPt
        ) {
          // @ts-ignore
          document.getElementById("BarteredBride").pause();
        }
      }
    }

    window.addEventListener("load", classChkOnReload);
    window.addEventListener("scroll", classChkOnReload);
    window.addEventListener("load", () => setOffsetY(window.pageYOffset));
    window.addEventListener("scroll", stopVideo);

    return () => {
      window.removeEventListener("load", classChkOnReload);
      window.removeEventListener("scroll", classChkOnReload);
      window.removeEventListener("load", () => setOffsetY(window.pageYOffset));
      window.removeEventListener("scroll", stopVideo);
    };
  });

  useEffect(() => {
    window.name = "Smetana";

    const handleScroll = () => setOffsetY(window.pageYOffset);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  function nextSlide() {
    switch (currSlide) {
      case "One":
        setCurrSlide("Two");
        setSliderClass("One2Two");
        break;
      case "Two":
        setCurrSlide("Three");
        setSliderClass("Two2Three");
        break;
      case "Three":
        setCurrSlide("Four");
        setSliderClass("Three2Four");
        break;
      case "Four":
        setCurrSlide("Five");
        setSliderClass("Four2Five");
        break;
      default:
        break;
    }

    // @ts-ignore
    document.getElementById("ProgressBar")?.setAttribute("style", "");
  }

  function prevSlide() {
    switch (currSlide) {
      case "Five":
        setCurrSlide("Four");
        setSliderClass("Five2Four");
        break;
      case "Four":
        setCurrSlide("Three");
        setSliderClass("Four2Three");
        break;
      case "Three":
        setCurrSlide("Two");
        setSliderClass("Three2Two");
        break;
      case "Two":
        setCurrSlide("One");
        setSliderClass("Two2One");
        break;
      default:
        break;
    }

    // @ts-ignore
    document.getElementById("ProgressBar")?.setAttribute("style", "");
  }

  function setSlide(slide: number) {
    switch (slide) {
      case 0:
        setCurrSlide("One");
        setSliderClass("Two2One");
        break;
      case 1:
        setCurrSlide("Two");
        setSliderClass("One2Two");
        break;
      case 2:
        setCurrSlide("Three");
        setSliderClass("Two2Three");
        break;
      case 3:
        setCurrSlide("Four");
        setSliderClass("Three2Four");
        break;
      case 4:
        setCurrSlide("Five");
        setSliderClass("Four2Five");
        break;
      default:
        break;
    }

    if (slide === 4) {
      // @ts-ignore
      document.getElementById("BarteredBride")?.play();
    }
  }

  return (
    <div
      className={
        "ChapterSmetana" + (lang === LANG.EN().type ? " ENG" : " ZH-HANT")
      }
      style={{
        backgroundPosition:
          window.innerWidth > 450 ? "" : "-100vw " + offsetY + "px",
      }}
    >
      <div className="ChapterCaption">
        <p>
          {intl.formatMessage({
            id: "Smetana_BGc",
            defaultMessage:
              "The Provisional Theatre in 1864, photography by Wilhelm Rupp (1821-1893). It is now The National Theatre (Prague).",
          })}
        </p>
      </div>
      <div
        id="Section One"
        className="Section One"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Two")?.offsetTop
          );
          document.getElementById("Section Two")?.classList.add("Active");
        }}
      >
        <div className="DotsRect" />
        <div className="ImgContainer Portrait">
          <img src={Portrait} alt="Smetana Portrait" />
        </div>
        <div
          className="Ellipse Green"
          style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        />
        <div
          className="Ellipse Blue"
          style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        />
        {lang === LANG.EN().type ? (
          <div className="ChapterName" />
        ) : (
          <div className="ChapterName ZH">
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage({ id: "Smetana" })
                : intl.formatMessage({ id: "Smetana_m" })}
            </p>
          </div>
        )}
        <div className="ChapterYear">
          <p>(1824-1884)</p>
        </div>
      </div>
      <div
        id="Section Two"
        className="Section Two"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Three")?.offsetTop
          );
        }}
      >
        <div
          className="clipContent"
          // @ts-ignore
          // style={{ opacity: `${window.pageYOffset / sectTwoPos}` }}
        >
          <div className="DotsRect" />
          <div
            className="Ellipse Blue"
            style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
          />
          <div className="Paragraph One">
            <h1>
              {window.innerWidth > 450
                ? intl.formatMessage({
                    id: "Smetana_p1h1",
                    defaultMessage: "1848: The Year of Awakening",
                  })
                : intl.formatMessage({
                    id: "Smetana_m_p1h1",
                    defaultMessage: "",
                  })}
            </h1>
            <p>
              {intl.formatMessage({
                id: "Smetana_p1p",
                defaultMessage:
                  "Political stirrings of national identity and pride ignited a great awakening across Europe. Urging an end to Habsburg absolutist rule, the Czech composer Smetana openly participated in this revolution. Barely escaping arrest, and unable to establish a career in Prague, Smetana left for Sweden.",
              })}
            </p>
          </div>
          <div
            className="Quote One"
            style={{
              left:
                Math.min(
                  Math.max(
                    (offsetY -
                      // @ts-ignore
                      document.getElementById("Section Two")?.offsetTop +
                      window.innerWidth * 0.375 * Constant.screenRatio) /
                      ((window.innerWidth * Constant.screenRatio) / 6),
                    0
                  ),
                  1
                ) *
                  10 +
                (window.innerWidth > 450
                  ? lang === LANG.EN().type
                    ? 15
                    : 2
                  : lang === LANG.EN().type
                  ? -2.5
                  : -13) +
                "vw",
            }}
          >
            <p
              style={{
                fontSize:
                  window.innerWidth > 450
                    ? lang === LANG.EN().type
                      ? "6vw"
                      : "5vw"
                    : lang === LANG.EN().type
                    ? "15vw"
                    : "12vw",
                lineHeight:
                  window.innerWidth > 450
                    ? lang === LANG.EN().type
                      ? "6vw"
                      : "5vw"
                    : lang === LANG.EN().type
                    ? "15vw"
                    : "12vw",
              }}
            >
              {window.innerWidth > 450
                ? intl.formatMessage({
                    id: "Smetana_q1",
                    defaultMessage: "I am Czech in body and soul.",
                  })
                : intl.formatMessage({
                    id: "Smetana_m_q1",
                    defaultMessage: "",
                  })}
            </p>
          </div>
          {lang === LANG.EN().type ? (
            <>
              <div
                className="QuoteMark"
                style={{
                  left:
                    Math.min(
                      Math.max(
                        (offsetY -
                          // @ts-ignore
                          document.getElementById("Section Two")?.offsetTop +
                          window.innerWidth * 0.375 * Constant.screenRatio) /
                          ((window.innerWidth * Constant.screenRatio) / 6),
                        0
                      ),
                      1
                    ) *
                      10 +
                    (window.innerWidth > 450 ? 8 : -2.5) +
                    "vw",
                }}
              />
              <div
                className="QuoteMark Close"
                style={{
                  left:
                    Math.min(
                      Math.max(
                        (offsetY -
                          // @ts-ignore
                          document.getElementById("Section Two")?.offsetTop +
                          window.innerWidth * 0.375 * Constant.screenRatio) /
                          ((window.innerWidth * Constant.screenRatio) / 6),
                        0
                      ),
                      1
                    ) *
                      10 +
                    (window.innerWidth > 450 ? 67 : 72) +
                    "vw",
                }}
              />
            </>
          ) : (
            <></>
          )}
          <div className="Paragraph Two">
            <h1>
              {intl.formatMessage({
                id: "Smetana_p2h1",
                defaultMessage: "Exile in Sweden",
              })}
            </h1>
            <p>
              {intl.formatMessage({
                id: "Smetana_p2p",
                defaultMessage:
                  "The years in exile helped form Smetana’s development as a composer. Stirred by the rhythms and melodies of Czech folk music, he created a new and unique poetic language. Smetana enthusiastically envisioned operas and symphonic works based on themes from Czech history and mythology.",
              })}
            </p>
          </div>
        </div>
        <div
          className="Ellipse Green"
          style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        />
      </div>
      <div
        id="Section Three"
        className="Section Three"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Four")?.offsetTop
          );
          document.getElementById("Section Four")?.classList.add("Active");
        }}
      />
      <div
        id="Section Four"
        className="Section Four"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Five")?.offsetTop
          );
          document.getElementById("Section Five")?.classList.add("Active");
        }}
      >
        <h1>
          {intl.formatMessage({
            id: "Smetana_S4h1",
            defaultMessage: "The Establishment of the Provisional Theatre",
          })}
        </h1>
        <p>
          {intl.formatMessage(
            {
              id: "Smetana_S4p",
              defaultMessage:
                "Celebrating the autonomy of a unique Czech nation, a new school of music opened in 1863. It was exclusively dedicated to promoting Czech music, and Czech nationalist opera became the genre that defined Smetana’s career. The Bartered Bride is the intimaterealisation of Smetana’s artistic vision, and it gave his Czech homeland its own musical language.",
            },
            { wordVal: <i>The Bartered Bride</i> }
          )}
        </p>
      </div>
      <div id="Section Five" className="Section Five Slider">
        <section
          className={"Slides " + sliderClass}
          onMouseMove={(e) => {
            if (isDrag) {
              var pbLeft =
                Math.min(
                  Math.max(e.screenX, window.innerWidth * 0.2),
                  window.innerWidth * 0.68
                ) -
                window.innerWidth * 0.06;

              // @ts-ignore
              document.getElementById("ProgressBar").style.left = pbLeft + "px";
            }
          }}
          onMouseUp={(e) => {
            if (isDrag) {
              setIsDrag(false);

              var slideOneDiff = Math.abs(e.screenX - window.innerWidth * 0.26);

              var slideTwoDiff = Math.abs(e.screenX - window.innerWidth * 0.38);

              var slideThreeDiff = Math.abs(
                e.screenX - window.innerWidth * 0.5
              );

              var slideFourDiff = Math.abs(
                e.screenX - window.innerWidth * 0.62
              );

              var slideFiveDiff = Math.abs(
                e.screenX - window.innerWidth * 0.74
              );

              var slide = findNearestSnap(
                slideOneDiff,
                slideTwoDiff,
                slideThreeDiff,
                slideFourDiff,
                slideFiveDiff
              );

              // @ts-ignore
              document.getElementById("ProgressBar").style.left =
                slide * 12 + 20 + "%";

              setSlide(slide);
            }
          }}
        >
          <div className="Slide Zero">
            <div className="YellowEllipse" />
            <div className="ProgressBarBG" />
            <div
              id="ProgressBar"
              className="ProgressBar"
              onMouseDown={() => {
                setIsDrag(true);
                // @ts-ignore
                document.getElementById("BarteredBride")?.pause();
              }}
            />
            <div
              className="SliderBtn PrevSlide"
              onClick={() => {
                prevSlide();
                // @ts-ignore
                document.getElementById("BarteredBride").pause();
              }}
            />
            <div
              className="SliderBtn NextSlide"
              onClick={() => {
                nextSlide();
                if (currSlide === "Four") {
                  // @ts-ignore
                  document.getElementById("BarteredBride")?.play();
                }
              }}
            />
          </div>
          <div className="Slide One">
            <h1>
              {intl.formatMessage({
                id: "Smetana_Slh1",
                defaultMessage: "The Bartered Bride",
              })}
            </h1>
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage(
                    {
                      id: "Smetana_Sl1p",
                      defaultMessage:
                        "Smetana’s comic opera {wordVal}, with a \nlibretto by Karel Sabina, was first performed at the Prague \nProvisional Theatre on 30 May 1866. It was not immediately \nsuccessful, but after extensive revisions over four years, it \nwas widely regarded as a major contribution towards \nthe development of Czech opera and music.",
                    },
                    { wordVal: <i>The Bartered Bride</i> }
                  )
                : intl.formatMessage(
                    {
                      id: "Smetana_m_Sl1p",
                      defaultMessage: "",
                    },
                    { wordVal: <i>The Bartered Bride</i> }
                  )}
            </p>
            <div className="MusicNote" />
            <div className="FlippedClick" />
            <div className="ScrollGIF" />
          </div>
          <div className="Slide Two">
            <div className="BarteredBrideBG" />
            <div className="Caption BarteredBride">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p>
                  {intl.formatMessage(
                    {
                      id: "Smetana_Sl2c1",
                      defaultMessage: "Set Design to Bedřich Smetana's ",
                    },
                    { wordVal: <i>The Bartered Bride</i> }
                  )}
                </p>
              </div>
              <p>
                {intl.formatMessage({
                  id: "Smetana_Sl2c2",
                  defaultMessage: "(The National Theatre, 1883)",
                })}
              </p>
            </div>
          </div>
          <div className="Slide Three">
            <h1>
              {intl.formatMessage({
                id: "Smetana_Slh1",
                defaultMessage: "The Bartered Bride",
              })}
            </h1>
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage({
                    id: "Smetana_Sl3p",
                    defaultMessage:
                      "Set in a country village with realistic characters, the spirited \nheroine has to use all her determination, charm and cunning to \nmarry the man she loves. It is a joyous celebration of Czech culture \nand identity, and the distinct rhythmic inflections of the Czech \nlanguage and of Czech folk dances combine irresistibly.",
                  })
                : intl.formatMessage({
                    id: "Smetana_m_Sl3p",
                    defaultMessage: "",
                  })}
            </p>
            <div className="MusicNote" />
          </div>
          <div className="Slide Four">
            <h1>
              {intl.formatMessage({
                id: "Smetana_Slh1",
                defaultMessage: "The Bartered Bride",
              })}
            </h1>
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage({
                    id: "Smetana_Sl4p",
                    defaultMessage:
                      "Smetana said he was trying to give the music “a popular \ncharacter, because the plot was taken from village life and demanded a \nnational treatment”. In doing so, he rhythmically referenced the characteristic folk \ndances of Bohemia—the polka, the skočná and the furiant. Smetana provides only\noccasional glimpses of authentic Czech folk melodies, and the habitual emphasis \non the authenticity of the setting and costumes in productions is primarily a matter \nof staging. Nevertheless, Smetana “clearly felt the pulse of peasantry” and the \nsimplicity of the music not only connected to a broad folk base, but proved \nhighly inspirational to the emerging independence movement.",
                  })
                : intl.formatMessage({
                    id: "Smetana_m_Sl4p",
                    defaultMessage: "",
                  })}
            </p>
            <div className="MusicNote" />
          </div>
          <div className="Slide Five">
            <div className="VideoContainer">
              <video
                id="BarteredBride"
                className="Video"
                controlsList="nodownload"
                controls
              >
                <source src={BBVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </section>
      </div>
      <div
        id="Section FivePointFive"
        className="Section FivePointFive"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Six")?.offsetTop
          );
          document.getElementById("Section Six")?.classList.add("Active");
        }}
      >
        <div
          className="Quote Two"
          style={{
            left:
              Math.min(
                Math.max(
                  (offsetY -
                    // @ts-ignore
                    document.getElementById("Section FivePointFive")
                      ?.offsetTop +
                    window.innerWidth * 0.6 * Constant.screenRatio) /
                    ((window.innerWidth * Constant.screenRatio) / 6),
                  0
                ),
                1
              ) *
                13 +
              (window.innerWidth > 450
                ? lang === LANG.EN().type
                  ? 5
                  : -5
                : lang === LANG.EN().type
                ? 2.5
                : -8) +
              "vw",
          }}
        >
          <p
            style={{
              fontSize:
                window.innerWidth > 450
                  ? lang === LANG.EN().type
                    ? "8vw"
                    : "5vw"
                  : lang === LANG.EN().type
                  ? "15vw"
                  : "12vw",
              lineHeight:
                window.innerWidth > 450
                  ? lang === LANG.EN().type
                    ? "8vw"
                    : "6vw"
                  : lang === LANG.EN().type
                  ? "15vw"
                  : "12vw",
            }}
          >
            {window.innerWidth > 450
              ? intl.formatMessage({
                  id: "Smetana_q2",
                  defaultMessage:
                    "My father land means more to \nme than anything else.",
                })
              : intl.formatMessage({
                  id: "Smetana_m_q2",
                  defaultMessage: "",
                })}
          </p>
        </div>
        {lang === LANG.EN().type ? (
          <>
            <div
              className="QuoteMark"
              style={{
                left:
                  Math.min(
                    Math.max(
                      (offsetY -
                        // @ts-ignore
                        document.getElementById("Section FivePointFive")
                          ?.offsetTop +
                        window.innerWidth * 0.6 * Constant.screenRatio) /
                        ((window.innerWidth * Constant.screenRatio) / 6),
                      0
                    ),
                    1
                  ) *
                    13 +
                  (window.innerWidth > 450 ? -6 : -6) +
                  "vw",
              }}
            />
            <div
              className="QuoteMark Close"
              style={{
                left:
                  Math.min(
                    Math.max(
                      (offsetY -
                        // @ts-ignore
                        document.getElementById("Section FivePointFive")
                          ?.offsetTop +
                        window.innerWidth * 0.6 * Constant.screenRatio) /
                        ((window.innerWidth * Constant.screenRatio) / 6),
                      0
                    ),
                    1
                  ) *
                    13 +
                  (window.innerWidth > 450 ? 54 : 62) +
                  "vw",
              }}
            />
          </>
        ) : (
          <></>
        )}
        <div className="ImgContainer Signature">
          <img src={SmetanaSign} alt="Smetana Signature" />
        </div>
      </div>
      <div
        id="Section Six"
        className="Section Six"
        onClick={() => {
          if (
            offsetY <
            // @ts-ignore
            document.getElementById("Section Six")?.offsetTop +
              window.innerHeight * 0.93
          ) {
            Scroll.animateScroll.scrollTo(
              // @ts-ignore
              document.getElementById("Section Six")?.offsetTop +
                window.innerHeight * 0.94
            );
          } else {
            Scroll.animateScroll.scrollTo(
              //@ts-ignore
              document.getElementById("Section Seven")?.offsetTop
            );
          }
        }}
      >
        <div className="BGDots LeftDots" />
        <div className="BGDots RightDots" />
        <div id="Má Vlast Poster" className="ImgContainer Poster">
          <div className="Images Poster">
            <img
              src={Poster}
              alt="Poster of Má Vlast"
              style={{
                transform:
                  "scale(" +
                  (Math.min(
                    Math.abs(
                      offsetY -
                        // @ts-ignore
                        document.getElementById("Má Vlast Poster")?.offsetTop -
                        // @ts-ignore
                        document.getElementById("Section Six")?.offsetTop
                    ) /
                      // @ts-ignore
                      document.getElementById("Má Vlast Poster")?.offsetHeight,
                    1
                  ) *
                    Constant.photoMagnifier +
                    1) +
                  ")",
              }}
            />
          </div>
          <div className="Caption Poster">
            <p>
              {intl.formatMessage(
                {
                  id: "Smetana_S6c1",
                  defaultMessage:
                    "The first performance poster of {wordVal}. The premiere took place on 5 November 1882 in Žofín Palace, Prague under Adolf Čech.",
                },
                { wordVal: <i>Má vlast</i> }
              )}
            </p>
          </div>
        </div>
        <div className="Paragraph Three">
          <div className="Title">
            <h1>
              {intl.formatMessage({
                id: "Smetana_p3h1",
                defaultMessage: "Má vlast",
              })}
            </h1>
            <div className="MusicNotes" />
          </div>
          <p>
            {intl.formatMessage({
              id: "Smetana_p3p",
              defaultMessage:
                "Throughout human consciousness, the great rivers of this world have spiritually highlighted the paradoxical relationship between eternity and change. As metaphors for life itself, they simultaneously underscore all that is timeless and ephemeral in human experience and existence. Rivers sustain life by renewing the fertility of the land, and they variously inspire poetry, folklore and music. Perhaps the most famous river journey is musically encoded in Smetana’s “Vltava” (The Moldau).",
            })}
          </p>
          <Iframe
            className="Spotify"
            url="https://open.spotify.com/embed/album/0zInM2fNoYHhVdLGKlDH3p"
            width={(window.innerWidth > 450
              ? window.innerWidth * 0.3
              : window.innerWidth * 0.85
            ).toString()}
            height={(window.innerWidth > 450
              ? window.innerWidth * 0.2
              : window.innerWidth * 0.4
            ).toString()}
            frameBorder={0}
            allow="encrypted-media"
          />
        </div>
        <div className="Paragraph Four">
          <p>
            {intl.formatMessage(
              {
                id: "Smetana_p4p",
                defaultMessage:
                  "This second part of a cycle of six tone poems entitled {wordVal} (My Homeland) depicts the Vltava. Smetana provided brief commentaries explaining the content of his tone poem.",
              },
              { wordVal: <i>Má vlast</i> }
            )}
          </p>
        </div>
        <div
          className={"Quote Four" + (lang === LANG.ZH_HANT().type ? " ZH" : "")}
        >
          <p>
            {window.innerWidth > 450
              ? intl.formatMessage({
                  id: "Smetana_q4",
                  defaultMessage:
                    "“The composition depicts the course of the river, from its beginning where \ntwo brooks, one cold, the other warm, join a stream.”",
                })
              : intl.formatMessage({
                  id: "Smetana_m_q4",
                  defaultMessage: "",
                })}
          </p>
        </div>
        <div className="Paragraph Five">
          <p>
            {intl.formatMessage({
              id: "Smetana_p5p",
              defaultMessage:
                "Water splashes gaily against rocks and, as the river grows wider, her banks echo with the sound of hunting horns and peasant dancing as part of a folk wedding. Bathed in moonlight, themysterious dance of the water nymphs heralds the emerging day. As day breaks, the river reaches St John’s Rapids, on whose rocks her waves break into a frothy spray. From there she flows towards Prague, where she greets the venerable Vyšehrad castle. Finally, the river disappears into the distance, as she majestically flows into the Elbe. Smetana’s river journey has captured the imagination of audiences worldwide, and it underscores the unity of a people intrinsically bound to this majestic waterway.",
            })}
          </p>
        </div>
        <div className="CharlesBridgeVyšehrad" />
      </div>
      <div id="Section Seven" className="Legacy">
        <div className="DotsRect" />
        <div className="Paragraph Six">
          <h1>
            {intl.formatMessage({
              id: "Legacy",
              defaultMessage: "Legacy",
            })}
          </h1>
          <p>
            {intl.formatMessage({
              id: "Smetana_legacy",
              defaultMessage:
                "Smetana is widely considered the father of Czech music, and his work posthumously became synonymous with a Czech national musical style. Establishing a Czech classical music canon, Smetana became a national symbol who pioneered a musical aesthetic that endured changes in politics, governments and the ethnic composition of his native land.",
            })}
          </p>
        </div>
        <div
          className="Ellipse Left"
          style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        />
        <div
          id="FooterSwitch"
          className={"FooterSwitch" + (footerHover ? " Hover" : "")}
        >
          <div
            id="Ellipse Right"
            className="Ellipse Right"
            style={{
              transform: `translateY(-${offsetY * 0.5}px)`,
              top:
                // @ts-ignore
                (document.getElementById("Section Seven")?.offsetTop +
                  window.innerHeight -
                  // @ts-ignore
                  document.getElementById("Section Seven")?.offsetHeight) /
                  2 -
                window.innerHeight +
                "px",
            }}
          />
          <div
            className="FooterBtn"
            onMouseEnter={() => {
              setFooterHover(true);
              // @ts-ignore
              document.getElementById("Ellipse Right").style.top =
                // @ts-ignore
                (document.getElementById("Section Seven")?.offsetTop +
                  window.innerHeight -
                  // @ts-ignore
                  document.getElementById("Section Seven")?.offsetHeight) /
                  2 -
                window.innerHeight +
                // @ts-ignore
                document.getElementById("FooterSwitch")?.offsetTop +
                // @ts-ignore
                document.getElementById("NextChapter")?.offsetTop / 1.6 +
                "px";
            }}
            onMouseLeave={() => {
              setFooterHover(false);
              // @ts-ignore
              document.getElementById("Ellipse Right").style.top =
                // @ts-ignore
                (document.getElementById("Section Seven")?.offsetTop +
                  window.innerHeight -
                  // @ts-ignore
                  document.getElementById("Section Seven")?.offsetHeight) /
                  2 -
                window.innerHeight +
                "px";
            }}
            onClick={() => {
              history.push("/Dvorak");
            }}
          >
            <div className="FooterName">
              <p>
                {intl.formatMessage({
                  id: "Dvorak",
                })}
              </p>
            </div>
            <div id="NextChapter" className="NextChapter">
              <p>
                {intl.formatMessage({
                  id: "NextChapter",
                  defaultMessage: "Next Chapter",
                })}
              </p>
              <div className="FooterArrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Smetana;
