const General = {
	ProjectName: "樂遊捷地",
	ProjectName_m: "樂遊捷地",
	Legacy: "歷史意義",
	NextChapter: "下一章",
	Map: "地圖",
	Smetana: "貝多伊齊 · 斯美塔那",
	Dvorak: "安東尼 · 德伏扎克",
	Janacek: "萊奧什 · 楊納傑克",
	Martinu: "博胡斯拉夫 · 馬替奴",
	NationalTheatreBrno: "捷克布爾諾國家歌劇院",
	NationalTheatreBrno_C: "捷克布爾諾國家歌劇院",
	Introduction: "簡介",
	TutorialOne: "1. 點擊地標了解更多",
	TutorialTwo: "2. 點擊作曲家去探索他的音樂旅途",
	Smetana_m: "貝多伊齊\n · \n斯美塔那",
	Dvorak_m: "安東尼\n · \n德伏扎克",
	Janacek_m: "萊奧什\n · \n楊納傑克",
	Martinu_m: "博胡斯拉夫\n · \n馬替奴",
	NationalTheatreBrno_m: "捷克布爾諾\n國家歌劇院",
};

const Menu = {
	ExhibitionAuthor: "展覽作者",
	IllustrationDesign: "畫像設計",
	WebsiteDesign: "網頁設計及製作",
	Courtesy: "鳴謝",
	ShareExp: "分享",
	Credit: "製作單位",
	BackToMenu: "返回目錄",
};

const Map = {
	Intro:
		"波希米亞和摩拉維亞兩個地區的豐饒文化歷史，充分體現在它們的藝術、建築、文學和音樂之中。數百年來，民族多元性和民間音樂傳統啟發了不少作曲家在這引人入勝的領域上創作。波希米亞盛載的社會及文化現象，與復甦捷克語言、文化及國族身份的運動密不可分；另一方面，摩拉維亞則秉持與相鄰地區截然不同的民俗音樂傳統。",
	RedutaTheatre: "瑞都塔劇院",
	MahenTheatre: "馬亨劇院",
	JanacekTheatre: "楊納傑克劇院",
	BrnoTheatresTT:
		"布爾諾的主要藝術表演場地，\n對這座城市的歷史、文化與\n音樂發展極為重要。",
	JanacekHouse: "楊納傑克之家",
	JanacekHouseTT:
		"楊納傑克紀念館的一部分，專為這位作曲家而建，\n亦是他主要作品的誕生之地。",
	OrganistHouse: "管風琴手之家",
	OrganistHouseTT:
		"德伏扎克在此處跟隨管風琴手安東寧·李曼\n學習，接受基礎音樂訓練。",
	SychrovCastle: "錫赫羅夫城堡",
	SychrovCastleTT:
		"德伏扎克經常到訪的新哥德式城堡和公園，\n亦是他一些音樂作品的靈感來源。",
	MoravianTheatre: "摩拉維亞劇院",
	MoravianTheatreTT: "馬勒移居維也納前，曾在這個劇院短暫擔任指揮。",
	MonumentOfBystrouška: "小狐狸紀念碑",
	MonumentOfBystrouškaTT:
		"楊納傑克的出生地，他的著名歌劇角色「尖耳朵\n狐女」雕像矗立於此。",
	LitomyšlCastle: "利托米什爾城堡",
	LitomyšlCastleTT: "捷克最重要的文藝復興建築之一，亦是\n斯美塔那的出生地。",
	StJamesChurch: "聖詹姆斯教堂",
	StJamesChurchTT: "聖詹姆斯教堂的一個小房間，是馬替奴的出生地。",
	CharlesBridge: "查爾斯橋",
	CharlesBridgeTT:
		"一座橫跨伏爾塔瓦河的中世紀石橋，《我的祖國》\n曾描繪它的宜人美景。",
	Rudolfinum: "魯道夫宮",
	RudolfinumTT: "德伏扎克在此指揮捷克愛樂樂團的創團音樂會。",
	EstatesTheatre: "莊園歌劇院",
	EstatesTheatreTT:
		"莫扎特歌劇《唐．喬望尼》和《狄托的仁慈》\n在此進行世界首演。",
	NationalTheatre: "布拉格國家劇院",
	NationalTheatreTT:
		"捷克國家名勝古蹟，與歷史與藝術息息相關，同時也是\n捷克歌劇的發源地。斯美塔那、德伏扎克、楊納傑克和\n馬替奴的歌劇都曾在此進行全球首演。",
	Vyšehrad: "高堡",
	VyšehradTT:
		"布拉格的歷史要塞，亦是德伏扎克、斯美塔那、\n作家卡雷爾．恰佩克與畫家阿爾豐斯．慕夏的\n安息之地。",
	DvorakMemorial: "德伏扎克紀念館",
	DvorakMemorialTT:
		"一棟新文藝復興時期的莊嚴大宅，位處普日\n布拉姆城邊的維蘇卡村，由德伏扎克的姊夫\n所擁有。大宅莊園、樹林和附近的湖泊皆為\n德伏扎克提供創作靈感。",
	AugustinianHouse: "奥古斯丁酒店",
	AugustinianHouseTT:
		"位於一座溫泉小鎮，楊納傑克在此邂逅\n卡米拉──他晚期作品的繆斯女神。",
};

const Smetana = {
	Smetana_BGc:
		"1864年的臨時劇院，今布拉格國家劇院。由威咸．魯普（1821–1893）所攝。",
	Smetana_p1h1: "1848: 覺醒之年",
	Smetana_p1p:
		"由民族身份與自豪感引起的政治動盪，激起民眾的覺醒並席捲整個歐洲。捷克作曲家斯美塔那公開參與這場革命運動，要求結束哈布斯堡王朝的專制統治。斯美塔那差點被捕入獄，亦無法在布拉格的音樂界取得一席之地，於是前往瑞典。",
	Smetana_q1: "「我的身體與靈魂都屬於捷克。」",
	Smetana_p2h1: "瑞典的流亡歲月",
	Smetana_p2p:
		"斯美塔那流亡瑞典幾年，其間發掘到屬於自己的作曲方向。在捷克民俗音樂的節奏與旋律薰陶之下，斯美塔那創造了一種新穎獨特的音樂語言。他滿懷熱情，創作出多部以捷克歷史和神話為主題的歌劇與交響樂作品。",
	Smetana_S4h1: "布拉格國家劇院的成立",
	Smetana_S4p:
		"為了慶祝捷克人獲得自主權，一所新的音樂學校遂於1863年成立。它專門推廣捷克音樂，而捷克歌劇亦成為塑造斯美塔那音樂生涯的一個重要類型。《被出賣的新娘》充分體現出斯美塔那的藝術視野，並為他的家鄉捷克賦予一種別樹一格的音樂語言。",
	Smetana_Slh1: "《被出賣的新娘》",
	Smetana_Sl1p:
		"喜歌劇《被出賣的新娘》為斯美塔那的作品，劇本由薩比納所作，在1866年5月30日於布拉格臨時劇院首演。這部作品並沒有一炮而紅，反而經過四年大規模修訂，才漸漸得到認可，被視為捷克歌劇與音樂發展中的一部重要作品。",
	Smetana_Sl2c1: "斯美塔那《被出賣的新娘》第一幕的佈景設計",
	Smetana_Sl2c2: "（國家劇院，1883）。",
	Smetana_Sl3p:
		"故事設定於一條鄉村，角色皆有血有肉：意志堅定的女主角使出她最大的決心、魅力和機智，務求與夢中情人結婚。這齣劇歌頌捷克文化與身份認同，也糅合了捷克語獨特的韻律變化和捷克民俗舞蹈，兩者在劇中成為密不可分的組合。",
	Smetana_Sl4p:
		"斯美塔那曾表示，他試着賦予此劇音樂「大眾化的形象，因為當中的情節取材自鄉村生活，但卻適用於整個民族。」為此，他在節奏上引用了波希米亞獨特的民俗舞蹈，包括波爾卡舞、史柯其納舞與火烈舞的曲風。斯美塔那只是偶爾引用正宗的捷克民間音樂，強調佈景及服裝的真實性，使其成為製作上的一個重要元素。無論如何，斯美塔那「明顯感受到農民的脈搏」，他音樂中的樸實不僅與民俗文化產生共鳴，而且對當時方興未艾的獨立運動給予極大的鼓舞。",
	Smetana_q2: "「祖國對我來說比任何東西都重要。」",
	Smetana_S6c1:
		"《我的祖國》首演時的海報。首演在1882年11月5日於布拉格的蘇菲宮舉行，由阿杜夫．傑赫擔任指揮。",
	Smetana_p3h1: "《我的祖國》",
	Smetana_p3p:
		"一直以來，世界各處的偉大河流都體現着永恆與變化之間的矛盾關係。河流是生命的象徵，同時強調了人類經驗裡不朽與轉瞬即逝的兩面。河流通過滋潤土地來養育生命，並啟發各種詩歌、民俗和音樂的形成。也許最著名的河流之旅就藏在斯美塔那的音樂作品中──以波希米亞地區最長河流來命名的《伏爾塔瓦河》。",
	Smetana_p4p:
		"《我的祖國》是由六首管弦樂章組成的交響詩作品，當中第二部分詳述伏爾塔瓦河。斯美塔那為這部交響詩作品的內容提供了簡短的釋述。",
	Smetana_q4:
		"「這部樂章描述伏爾塔瓦河的兩道源流──\n    一道冰冷而另一道溫和──交匯在一起。」",
	Smetana_p5p:
		"明亮的水花濺上石頭，隨着河面變寬，堤岸迴盪着獸獵的號角和民間婚禮上農民的舞蹈。沐浴在月色中的水仙子，以神秘的舞蹈預示新一天的來臨。天色破曉時，河流到達聖約翰急流，石塊把浪花粉碎成泡沫。河由此處流向布拉格，迎接神聖的高堡，最終壯麗地流入易北河，消失在遠方。斯美塔那的河流之旅喚起各地聽眾的想像，同時反映了與這條宏偉水道息息相關的民族團結精神。",
	Smetana_legacy:
		"斯美塔那被視為捷克音樂之父，在他去世以後，他的作品更成為了捷克音樂的代名詞。他開創了獨特的音樂美學，在政局變遷、政權交替以至國家族群變動下依然茁壯成長，建立捷克古典音樂的典範，令他成為國家的音樂標誌。",
	Smetana_m_p1h1: "1848: 覺醒之年",
	Smetana_m_q1: "「我的身體與靈魂\n    都屬於捷克。」",
	Smetana_m_q2: "「祖國對我來說\n    比任何東西都\n    重要。」",
	Smetana_m_q4:
		"「這部樂章描述伏爾塔瓦河的兩道源流──一道冰冷而另一道溫和──交匯在一起。」",
	Smetana_m_Sl1p:
		"喜歌劇《被出賣的新娘》為斯美塔那的作品，劇本由薩比納所作，在1866年5月30日於布拉格臨時劇院首演。這部作品並沒有一炮而紅，反而經過四年大規模修訂，才漸漸得到認可，被視為捷克歌劇與音樂發展中的一部重要作品。",
	Smetana_m_Sl2c1: "斯美塔那《被出賣的新娘》第一幕的佈景設計",
	Smetana_m_Sl2c2: "（國家劇院，1883）。",
	Smetana_m_Sl3p:
		"故事設定於一條鄉村，角色皆有血有肉：意志堅定的女主角使出她最大的決心、魅力和機智，務求與夢中情人結婚。這齣劇歌頌捷克文化與身份認同，也糅合了捷克語獨特的韻律變化和捷克民俗舞蹈，兩者在劇中成為密不可分的組合。",
	Smetana_m_Sl4p:
		"斯美塔那曾表示，他試着賦予此劇音樂「大眾化的形象，因為當中的情節取材自鄉村生活，但卻適用於整個民族。」為此，他在節奏上引用了波希米亞獨特的民俗舞蹈，包括波爾卡舞、史柯其納舞與火烈舞的曲風。斯美塔那只是偶爾引用正宗的捷克民間音樂，強調佈景及服裝的真實性，使其成為製作上的一個重要元素。無論如何，斯美塔那「明顯感受到農民的脈搏」，他音樂中的樸實不僅與民俗文化產生共鳴，而且對當時方興未艾的獨立運動給予極大的鼓舞。",
};

const Dvorak = {
	Dvorak_p1h1: "德伏扎克重新出發",
	Dvorak_p1p:
		"德伏扎克過去一直被認為是位擅於創作交響樂與室內樂的捷克作曲家，然而，這傳統形象近年已漸漸褪色，世人轉而認為他創作的作品劇情複雜，而且具有隱藏含意。儘管他一生一直悉心建立捷克人的音樂身份認同，但其實他於各種創作環境中都揮灑自如。事實上，德伏扎克很可能是他同輩中最多才多藝的作曲家。",
	Dvorak_p2h1: "擔任臨時劇院管弦樂隊的小提琴手",
	Dvorak_p2p:
		"德伏扎克在新落成的臨時劇院擔任首席小提琴手，這段時期的經歷使他得以拓寬音樂視野。當斯美塔那繼任首席指揮時，德伏扎克有機會親身認識到捷克和斯拉夫作曲家作品。受到斯美塔那的啟發，德伏扎克開始深入鑽研各種民間音樂，其作品因而轉向一種新的古典主義形式，其音樂語言亦開始顯現斯拉夫民間傳說的元素。",
	Dvorak_c1: "安東尼．德伏扎克與他的朋友和家人，1893年攝於美國紐約。",
	Dvorak_p3h1: "備受國際認可",
	Dvorak_p3p:
		"德伏扎克在各方面都高舉民族主義的旗幟。儘管如此，他那面向國際的音樂創作傾向，在十九世紀諸多偉大的作曲家之間，可能僅有李斯特和柴可夫斯基能與之平分秋色。他的音樂作品中，一些明顯表現出民族主義色彩的特徵，事實上都宣示着國際主義的理念。德伏扎克是首位名譽國際的捷克作曲家，其作品《斯拉夫舞曲》為他開拓出一個廣大市場，讓他的音樂聞名捷克，以至在全世界都炙手可熱。",
	Dvorak_p4h1: "《斯拉夫舞曲》",
	Dvorak_p4p:
		"德伏扎克申請奧地利國家獎學金時，還是一位比較名不經傳的作曲家。他在四年內三次赢得該獎學金，其中一位評判布拉姆斯將他引介給自己的出版商弗里茨．西姆羅克。這位出版商為德伏扎克發佈了第一首作品《摩爾維亞二重奏》，由於此作獲得極大的收益和好評，出版商又鼓勵德伏扎克創作一組類似舞曲的作品。",
	Dvorak_c2: "德伏扎克將《斯拉夫舞曲》題獻沃斯曼先生，圖為首系列的封面頁。",
	Dvorak_p5p:
		"德伏扎克以布拉姆斯的《匈牙利舞曲》為範本，一連寫了十六首鋼琴四手聯彈的舞曲，後來又將其編成管弦樂。《斯拉夫舞曲》共有兩組，分別是作品46和作品72。德伏扎克的傳記作家奧塔卡‧索雷克對此作品有以下評價：",
	Dvorak_q1:
		"「體現了捷克人的幽默與驕傲、樂觀與熱情，並\n    散發着捷克森林和草地的甜美芬芳及原始美態」。",
	Dvorak_p6p:
		"德伏扎克將斯拉夫民俗音樂的獨特節奏巧妙地轉換為新潮的樂曲。德伏扎克引用史柯其納舞、農夫舞、杜姆卡舞和火烈舞等捷克民俗舞蹈，從傳統元素中創造出新穎的作品。《斯拉夫舞曲》充滿生氣，毫不掩飾其民族主義色彩，在當時獲得熱烈的迴響，即使時至今天，仍是這位作曲家最令人難忘的作品之一。",
	Dvorak_q2:
		"「人民的音樂，猶如一朵在\n    遍地雜草間破土而出的花，\n    珍罕而可愛。」",
	Dvorak_p7h1: "自我省思",
	Dvorak_p7p:
		"大自然在德伏扎克的生活與工作上都扮演了一個重要的角色，而斯拉夫的民間傳說亦是構成他作曲的一部分。大自然和民間傳統成為這位作曲家的主要靈感來源，他的許多作品就像一幅風景畫：色彩豐富，與土地與人民息息相關，並呈現出壯麗堂皇的景致。",
	Dvorak_p8p:
		"德伏扎克經常到訪維索卡山一條鄉村的莊園，大宅後有一條小徑通往露莎卡湖。他經常在湖邊久坐，靈感如泉湧現，寫下了三十多首作品，包括他舉世聞名的歌劇《露莎卡》。",
	Dvorak_Slh1: "《露莎卡》",
	Dvorak_Sl1p:
		"德伏扎克剛開始創作時，就視歌劇為他作品中的一個關鍵部分。他創作力最活躍之時，正是捷克歌劇理念成形的年代，他在作品中採用三個傳統歌劇主題：捷克鄉村生活中的典型角色、斯拉夫的悠久歷史，以及捷克故事和童話，包括《露莎卡》。",
	Dvorak_Sl2p:
		"雅羅斯拉夫．克瓦皮爾參考了許多文學作品，才為這個童話故事執筆填詞，包括穆特．福開的《渦堤孩》、安徒生的《小美人魚》、法國神話《美露莘》和卡雷爾．愛爾本的《花束集》。德伏扎克在美國期間有寫筆記本的習慣，他取材自當中記錄的的素材，全情投入創作當中，寫下了他一生中最行雲流水的作品之一。",
	Dvorak_Sl3c1:
		"《露莎卡》在1901年3月31日於布拉格進行首演，由馬圖羅娃飾演第一代露莎卡。",
	Dvorak_Sl5p:
		"劇中主角露莎卡是一個永生的水仙子，她因為愛上了一個凡人，而決定放棄永生來追求真愛。然而她所愛的王子並非一位英雄，他抛棄了露莎卡。最後王子意識到自己的過錯，於是自願犧牲性命，使露莎卡得以重獲永生。儘管這個故事因缺乏自然主義色彩而備受批評，但不論在德伏扎克的故鄉或國外，依然是他最著名的歌劇作品。",
	Dvorak_m_p2h1: "擔任臨時劇院管弦樂隊的小提琴手",
	Dvorak_m_q1:
		"「體現了捷克人的幽默與驕傲、樂觀與熱情，並散發着捷克森林和草地的甜美芬芳及原始美態」。",
	Dvorak_m_q2: "「人民的音樂，猶如一朵在遍地雜草間破土而出的花，珍罕而可愛。」",
	Dvorak_m_Sl1p:
		"德伏扎克剛開始創作時，就視歌劇為他作品中的一個關鍵部分。他創作力最活躍之時，正是捷克歌劇理念成形的年代，他在作品中採用三個傳統歌劇主題：捷克鄉村生活中的典型角色、斯拉夫的悠久歷史，以及捷克故事和童話，包括《露莎卡》。",
	Dvorak_m_Sl2p:
		"雅羅斯拉夫．克瓦皮爾參考了許多文學作品，才為這個童話故事執筆填詞，包括穆特．福開的《渦堤孩》、安徒生的《小美人魚》、法國神話《美露莘》和卡雷爾．愛爾本的《花束集》。德伏扎克在美國期間有寫筆記本的習慣，他取材自當中記錄的的素材，全情投入創作當中，寫下了他一生中最行雲流水的作品之一。",
	Dvorak_m_Sl3c1:
		"《露莎卡》在1901年3月31日於布拉格進行首演，由馬圖羅娃飾演第一代露莎卡。",
	Dvorak_m_Sl5p:
		"劇中主角露莎卡是一個永生的水仙子，她因為愛上了一個凡人，而決定放棄永生來追求真愛。然而她所愛的王子並非一位英雄，他抛棄了露莎卡。最後王子意識到自己的過錯，於是自願犧牲性命，使露莎卡得以重獲永生。儘管這個故事因缺乏自然主義色彩而備受批評，但不論在德伏扎克的故鄉或國外，依然是他最著名的歌劇作品。",
};

const Janacek = {
	Janacek_BGc: "《小交響曲》開場號曲（第一樂章）的親筆簽名頁。",
	Janacek_p1h1: "早年生活",
	Janacek_p1p:
		"楊納傑克在貧窮中長大，然而他對作曲的興趣驅使他到萊比錫和維也納的音樂學院求學。他自1875年回到布爾諾後，就孜孜不倦地工作，在這座摩爾維亞首府建立他的音樂事業，以及文化生活。然而，他的第一套歌劇未受觀眾青睞，故其後轉而投入家鄉的民俗音樂世界之中。",
	Janacek_vlink: "到布爾諾網站查閱更多 {link}",
	Janacek_v1p:
		"這段動畫概述楊納傑克出生、抵達布爾諾和求學的日子。由藝術家凡杜拉．察蘭哥華、動畫師智子．莉莎和大衛．利絲共同製作。",
	Janacek_c1: "楊納傑克在摩拉維亞斯洛伐克一條村莊──斯特蘭尼收錄民歌。",
	Janacek_p2h1: "深入鑽研民俗音樂",
	Janacek_p2p:
		"楊納傑克對家鄉民俗音樂的熱情，令他多次親身到訪摩爾維亞的鄉村，以民族誌角度研究。楊納傑克在研究摩爾維亞和西利西亞的民俗活動方面是先鋒，他研究語言和音樂的關係，並逐漸發展出一種原創的作曲風格。他的作品帶有斯洛伐克語、匈牙利和羅馬尼亞民俗音樂的旋律和不規律節奏特色，煥發強烈現實主義色彩，與日常生活形成緊密連繫。",
	Janacek_Slh1: "《格拉高利彌撒曲》",
	Janacek_Sl1p:
		"楊納傑克創作這首後來被稱為《格拉高利彌撒曲》的作品，原意是想將羅馬天主教彌撒的常用經文翻譯成古教會斯拉夫語的版本。",
	Janacek_Sl2p:
		"格拉高利語約起源於九世紀，是最早的斯拉夫文字和書寫語言。捷克學者耶澤夫．瓦斯根據十四世紀的文本，在1919年和1920年分別出版兩本拉丁字母的抄本。楊納傑克在彌撒曲的不同部分中使用了這兩本文字抄本，其後他把文本寄給身在布拉格的斯拉夫研究教授。時至今天，雖然不同版本的《格拉高利彌撒曲》在唱詞上仍有細微差別，但楊納傑克始終視之為對斯拉夫文化的讚揚。",
	Janacek_Sl3p:
		"楊納傑克當時正處於音樂事業的高峰，他在創作過程將自己的戲劇元素巧妙地注入彌撒曲之中。作品管弦樂的部分描繪得極為詳盡，獨奏部分富有表現力，合唱表演亦不甘示弱，氣勢磅礴而偶具歌劇的濃烈情緒。垂憐經和信經兩樂章，與牧歌的狂歡時刻相得益彰，楊納傑克又透過光榮頌引發聽者聯想他故鄉的群山風景。",
	Janacek_Sl4p:
		"這是一首「為大教堂而創作的音樂，寫於一望無際、廣袤的樹林與天空之間」。楊納傑克將宗教與非正統宗教的儀式戲劇性地融合起來，同時傾注創作精力和細膩溫情，帶領聽眾踏上一段新穎而令人振奮的旅程。",
	Janacek_c2: "1920年，楊納傑克和卡米拉．戴娜蘇娃身處盧哈喬維采。",
	Janacek_p3h1: "迷戀與靈感",
	Janacek_p3p:
		"已婚的楊納傑克在63歲時遇上25歲的卡米拉．戴娜蘇娃，當時她同樣已為人婦。本來可能只是曇花一現的意亂情迷，卻演變成無法自拔的迷戀。楊納傑克一直無法得到卡米拉，但從她身上找到創作靈感，創作他大部分歌劇裏的女英雄角色。",
	Janacek_q1:
		"「我們之間有太多感情的焰火，」\n    楊納傑克如此寫給卡美拉，\n「假如著火燃燒，將共同化成灰燼。」",
	Janacek_p4p:
		"1925年一個晴天，這對不太可能走在一起的情侶坐在皮塞克鎮的一個公園裡──卡米拉與丈夫正是居於當地。他們聽着軍樂隊身穿統一制服步操的表演，不禁入迷了。",
	Janacek_p5h1: "《小交響曲》",
	Janacek_p5p:
		"楊納傑克一向受愛國主義和民族主義吸引，尤其在1918年10月捷克宣佈獨立以後。當他受委任替索科體操節──一個慶祝青春、體育與獨立國家地位的運動──創作樂曲時，楊納傑克自然樂意揮灑他對國家身分的認同。這首樂曲最初題名為《軍隊交響樂曲》，題獻給捷克斯洛伐克的武裝部隊。",
	Janacek_p6p: "按照作曲家所言，",
	Janacek_q2:
		"「它體現了當代一個自由人的理想，\n    以及其精神上的美和喜悅、其為勝利而戰的\n    力量、勇氣與決心」。",
	Janacek_p7p:
		"後來他刪去標題上軍隊一字，於是樂曲便直接稱為《小交響曲》。1918年，捷克宣告獨立後，楊納傑克如此描述與布爾諾市相關的種種情景和地點：「這座城市經歷的猶如奇跡。我曾討厭陰暗的市政廳、對群山仇恨從深處痛苦咆哮、厭惡街道和人群──此時都消失了。猶如奇跡降臨，自由憑空出現，照耀整個城市。我看到自己身處其中。我本屬於它。布爾諾的種種——勝利號角的響聲、女王修道院的聖潔和平、夜色投下的影子、綠色山丘的青蔥氣息以及日益壯大的小鎮未來，攜手促成了《小交響曲》一曲的誕生。」",
	Janacek_v2p:
		"由凡杜拉．察蘭哥華、奥莎娜．羅戈日納和揚．斯姆卡製作的動畫，描述了楊納傑克的「精采晚年」──在生命中的最後十年，楊納傑克寫下了一批聞名而今的重要作品。",
	Janacek_legacy:
		"楊納傑克借取音樂和語言的深層次特質，從而向聽眾傳達複雜糾結的情緒和感受。他對人類的各種狀況抱有強烈同情心，並且能夠將深刻的心理洞見轉化為音樂，展現非凡才華。",
	Janacek_m_q1:
		"「我們之間有太多感情的焰火，」楊納傑克如此寫給卡美拉，「假如著火燃燒，將共同化成灰燼。」",
	Janacek_m_q2:
		"「它體現了當代一個自由人的理想，以及其精神上的美和喜悅、其為勝利而戰的力量、勇氣與決心」。",
	Janacek_m_p7p:
		"後來他刪去標題上軍隊一字，於是樂曲便直接稱為《小交響曲》。1918年，捷克宣告獨立後，楊納傑克如此描述與布爾諾市相關的種種情景和地點：「這座城市經歷的猶如奇跡。我曾討厭陰暗的市政廳、對群山仇恨從深處痛苦咆哮、厭惡街道和人群──此時都消失了。猶如奇跡降臨，自由憑空出現，照耀整個城市。我看到自己身處其中。我本屬於它。布爾諾的種種——勝利號角的響聲、女王修道院的聖潔和平、夜色投下的影子、綠色山丘的青蔥氣息以及日益壯大的小鎮未來，攜手促成了《小交響曲》一曲的誕生。」",
	Janacek_m_Sl1p:
		"楊納傑克創作這首後來被稱為《格拉高利彌撒曲》的作品，原意是想將羅馬天主教彌撒的常用經文翻譯成古教會斯拉夫語的版本。",
	Janacek_m_Sl2p:
		"格拉高利語約起源於九世紀，是最早的斯拉夫文字和書寫語言。捷克學者耶澤夫．瓦斯根據十四世紀的文本，在1919年和1920年分別出版兩本拉丁字母的抄本。楊納傑克在彌撒曲的不同部分中使用了這兩本文字抄本，其後他把文本寄給身在布拉格的斯拉夫研究教授。時至今天，雖然不同版本的《格拉高利彌撒曲》在唱詞上仍有細微差別，但楊納傑克始終視之為對斯拉夫文化的讚揚。",
	Janacek_m_Sl3p:
		"楊納傑克當時正處於音樂事業的高峰，他在創作過程將自己的戲劇元素巧妙地注入彌撒曲之中。作品管弦樂的部分描繪得極為詳盡，獨奏部分富有表現力，合唱表演亦不甘示弱，氣勢磅礴而偶具歌劇的濃烈情緒。垂憐經和信經兩樂章，與牧歌的狂歡時刻相得益彰，楊納傑克又透過光榮頌引發聽者聯想他故鄉的群山風景。",
	Janacek_m_Sl4p:
		"這是一首「為大教堂而創作的音樂，寫於一望無際、廣袤的樹林與天空之間」。楊納傑克將宗教與非正統宗教的儀式戲劇性地融合起來，同時傾注創作精力和細膩溫情，帶領聽眾踏上一段新穎而令人振奮的旅程。",
};

const Martinu = {
	Martinu_BGc:
		"馬替奴與姊姊瑪麗亞舉行第一場音樂會時，年僅八歲。© Center of Bohuslav Martinů, Polička",
	Martinu_p1h1: "無名的協奏曲",
	Martinu_p1p:
		"馬替奴一直密切關注世界音樂發展中的先鋒作品。同時，他也明確地意識到自己作為捷克音樂作曲家的身份。他的創作風格豐富多彩，作品形式揮灑自如，往往從一顆顆樂音開始，築構出連綿的旋律。爵士樂的和聲如魚得水地融入在錯綜複雜的對位旋律中，一位雕塑家曾形容他的作品為「大理石雕塑」。",
	Martinu_c1: "帕杜比赤的聖詹姆斯教堂是馬替奴的出生地。",
	Martinu_p2h1: "源自布拉格",
	Martinu_p2p:
		"馬替奴五歲時便以小提琴獨奏家的身份，在家鄉舉行第一場公開演出。他的非凡才能立即備受認可，當地居民更資助他到布拉格音樂學院接受數年的音樂教育。馬替奴的作曲才華迅速發展起來，但他未能在守舊的布拉格闖出一番事業，遂於1923年前往巴黎發展。",
	Martinu_c2:
		"1938年，馬替奴與一群身處巴黎的捷克人在一起，當中包括約瑟夫．帕萊尼切克（作曲家）、魯道夫．\n費庫斯尼（鋼琴家）與卡雷爾．塞班尼克（出版商）。© Center of Bohuslav Martinů, Polička",
	Martinu_p3h1: "在巴黎",
	Martinu_p3p:
		"馬替奴起初最喜歡以芭蕾舞進行創作實驗。然而，隨着歐洲與其家鄉捷克斯洛伐克的政治局勢逐漸惡化，馬替奴的音樂很快便重燃起捷克民俗和文化意識。他的音樂抒發撕心裂肺之情，常被強硬粗暴的節奏打斷，以此表達他不祥的預感。他的惡夢最終成真——1940年納粹黨佔領捷克。馬替奴與捷克反對派素有來往，故被列入黑名單，他只好流亡到巴黎。",
	Martinu_p4p:
		"馬替奴的三幕芭蕾舞劇《斯帕利切克》1932年於布拉格首演。當時馬替奴深愛着一位年輕捷克女人，因此他譜寫此劇時常常聯想到故鄉的民俗風情。根據學者所言，《斯帕利切克》可能引用了一些附捷克鄉村生活插畫的市井民間傳説和民謠集。",
	Martinu_p5p:
		"馬替奴的創作靈感，亦可能來自一系列名為《斯帕利切克》的民歌和謎語集，它們由多產的畫家兼插畫家米古拉斯．阿列什所創作。這套芭蕾舞劇起初還包括後來獨立成劇的清唱劇《幽靈的新娘》，文本由十九世紀的知名詩人兼民俗學家卡雷爾．愛爾本所創。",
	Martinu_p6p:
		"《斯帕利切克》的插圖，由多產畫家兼插畫家阿列什繪製。 \n它寫道：「我是一個來自摩爾維亞的人，\n我的女孩，相信我：\n我就如一隻鳥，我愛你，我要離開。」",
	Martinu_p7p:
		"馬替奴後來形容《斯帕利切克》為「一套包含流行戲劇、習俗和童話的芭蕾舞」。劇情將耳熟能詳的童話故事和捷克民間傳說共冶一爐，而音樂則糅合各種民歌動機，並採用了具諷刺意味的巴羅克曲風和受「法國六人組作曲家」啟發的現代主義風格。",
	Martinu_q1: "「我的作品依舊帶有捷克風采，\n    與我的祖國息息相關。」",
	Martinu_c3:
		"1941年，馬替奴及妻子夏洛特，估計攝於他們從歐洲抵達美國後不久。\n© Center of Bohuslav Martinů, Polička",
	Martinu_p8h1: "流亡到美國 ",
	Martinu_p8p:
		"馬替奴為了逃避納粹的壓迫，到美國尋求庇護。他財政狀況緊絀，而且深陷似乎了無止境的鄉愁。他開始為民歌作詞，與他的家鄉建立象徵式的連結。他對祖國的掛念以及對和平的憧憬，與現實世界的不完美構成強烈反差。馬替奴的結局終究還是不得圓滿，因為捷克斯洛伐克在戰後便迎來了共產政權，令他無法落葉歸根。",
	Martinu_Slh1: "《春天降臨》",
	Martinu_Sl1p:
		"在人生的最後歲月裡，馬替奴被逼過着流亡的生活，只能通過作曲反覆地回望自己的家鄉。在寫給老朋友──作家兼詩人米羅斯拉夫．布雷什的信中，馬替奴建議可於他們的家鄉波利奇卡舉行清唱劇《春天降臨》的首演，寫道：「這樣一來，至少有一部分的我得以回家。」",
	Martinu_Sl2p:
		"灰心喪氣和深受鄉愁困擾的馬替奴被布雷什所寫的純樸民歌所感動，該首詩歌頌讚捷克摩爾維亞高原的景緻與當地民俗。馬替奴沉浸在豐富的情緒之中，製作了四首的室內清唱劇，大致可視為一套完整組曲，當中《春天降臨》娓娓道出了作曲家對故鄉的真摯情感。",
	Martinu_Sl3p:
		"在馬替奴的家鄉，當地人每年透過潔淨及重啟水源向冬季告別，馬替奴受這種民俗儀式以及當地人的語言習慣啟發，以音樂表達這種切身聯繫。《春天降臨》為兩把小提琴、一把中提琴和鋼琴而譜寫，還包括女聲合唱、男中音獨奏和朗誦部分。歌曲盛載着摩爾維亞民歌的神髓，同時賦予民歌一種普世價值，使馬替奴的音樂得以超越「愛國民俗音樂」的狹窄疆界。",
	Martinu_legacy:
		"馬替奴留下來的音樂遺產中有接近四百首樂曲，涵蓋合唱歌曲、歌劇、交響樂，以及大量的室樂作品。他一直尋找生命的意義和真理，曾經寫道：「一種令人迷茫的體制已經侵佔我們的日常生活。由機械化和統一性帶來的壓力定必引發抗議，而藝術家只有一種表達方式──音樂。」",
	Martinu_m_c2:
		"1938年，馬替奴與一群身處巴黎的捷克人在一起，當中包括約瑟夫．帕萊尼切克（作曲家）、魯道夫．費庫斯尼（鋼琴家）與卡雷爾．塞班尼克（出版商）。© Center of Bohuslav Martinů, Polička",
	Martinu_m_p6p:
		"《斯帕利切克》的插圖，由多產畫家兼插畫家阿列什繪製。 \n它寫道：「我是一個來自摩爾維亞的人，\n我的女孩，相信我：\n我就如一隻鳥，我愛你，我要離開。」",
	Martinu_m_q1:
		"「我的作品依舊帶有捷\n    克風采，與我的祖國\n    息息相關。」",
	Martinu_m_Sl1p:
		"在人生的最後歲月裡，馬替奴被逼過着流亡的生活，只能通過作曲反覆地回望自己的家鄉。在寫給老朋友──作家兼詩人米羅斯拉夫．布雷什的信中，馬替奴建議可於他們的家鄉波利奇卡舉行清唱劇《春天降臨》的首演，寫道：「這樣一來，至少有一部分的我得以回家。」",
	Martinu_m_Sl2p:
		"灰心喪氣和深受鄉愁困擾的馬替奴被布雷什所寫的純樸民歌所感動，該首詩歌頌讚捷克摩爾維亞高原的景緻與當地民俗。馬替奴沉浸在豐富的情緒之中，製作了四首的室內清唱劇，大致可視為一套完整組曲，當中《春天降臨》娓娓道出了作曲家對故鄉的真摯情感。",
	Martinu_m_Sl3p:
		"在馬替奴的家鄉，當地人每年透過潔淨及重啟水源向冬季告別，馬替奴受這種民俗儀式以及當地人的語言習慣啟發，以音樂表達這種切身聯繫。《春天降臨》為兩把小提琴、一把中提琴和鋼琴而譜寫，還包括女聲合唱、男中音獨奏和朗誦部分。歌曲盛載着摩爾維亞民歌的神髓，同時賦予民歌一種普世價值，使馬替奴的音樂得以超越「愛國民俗音樂」的狹窄疆界。",
};

const NDB = {
	NDB_p1p:
		"布爾諾與歌劇的關係可追溯到十八世紀初。一切源於市中心「蔬菜市集」的一座美麗建築，它原址是間小酒館，如今被被稱為「瑞都塔」，現為布爾諾國家歌劇院的一部分。",
	NDB_p2p:
		"當意大利的演出經理安傑洛．明戈蒂在1732年到達布爾諾時，他在斯皮爾博城堡城牆間有一所騎術學校，並在那裏興建了一所個人劇院。它可能是後來瑞都塔劇院的雛形——劇院建於1733年，地點就在生菜市集，音樂廳設有透視式舞台和廂座。這座劇院是意大利以外第一座由市政府建立和資助的歌劇院。歐洲著名的音樂家如莫扎特、李斯特和克拉拉．舒曼都曾在瑞都塔劇院演出，而在眾多劇場導演當中，還有莫扎特歌劇《魔笛》的作詞者伊曼紐爾．席卡內德。",
	NDB_te1:
		"瑞都塔劇院第二次被火災焚毀。在維也納的歌劇院影響之下，劇院在重建後成為了布爾諾市民觀賞歌劇演出的地方，演出包括莫扎特的《魔笛》和薩里耶利的《阿克蘇爾》。",
	NDB_te2:
		"瑞劇院再次發生火災，蓬勃的藝術活動因而陷入停頓。這次花了二十年時間來重建，但重新開放時已不再是劇院，變成市集。",
	NDB_te3: "三月，瑞都塔恢復了劇院用途。 ",
	NDB_te4: "捷克斯諾伐克共和國成立後，劇院由捷克和德國的公司共同經營。",
	NDB_te5:
		"第二次世界大戰後，瑞都塔劇院為一個輕歌劇樂團提供表演空間，但仍有持續上演歌劇。 ",
	NDB_te6: "建築物因結構上出現嚴重問題而關閉。",
	NDB_te7: "劇院經過數年的修建後重開。",
	NDB_p3p1:
		"1880年代，布爾諾開始有劇院落成：維維日街出現了一所臨時劇院，原址是一所小酒館，不少歌劇、輕歌劇和戲劇（後來還有芭蕾舞）公司都進駐了劇院。劇院在1884年12月5日開放，楊納傑克當時出版雜誌《音樂報》，在創刊號中熱情地宣佈：",
	NDB_q1:
		"「我們期待已久的時刻終於來臨：國家藝術劇院正式開幕！這標誌着布爾諾和整個摩爾維亞邁向新紀元。」",
	NDB_p3p2:
		"在第二次世界大戰期間，劇院遭受破壞，並終在1973年12月拆除。這原本只為臨時所用的劇院，最終為布爾諾國家歌劇院服務了整整68個演出季度。",
	NDB_p4p1:
		"瑞都塔劇院於1870年的大火摧毀以後，布爾諾市政府決定要建造一座新的劇院，這座劇院後來成為了布爾諾輝煌的象徵。",
	NDB_p4p2:
		"維也納建築師費爾內爾和赫爾默受委約設計劇院，並於1881年7月18日開始施工。鑑於歐洲眾多劇院於當年接連發生多場毀滅性大火，布爾諾市政府於是決定以新發明的電燈取代原本的煤氣照明，令馬亨劇院成為歐洲第一座完全以電力運作的劇院。",
	NDB_p5p:
		"劇院的外觀帶有新文藝復興時期的建築特徵；主樓梯的靈感則來自巴黎歌劇院。劇場成為一座享負盛名的建築。",
	NDB_p6p:
		"德國劇院在1919年由布爾諾捷克國家劇院接管，它的第一個表演節目就是楊納傑克的《耶奴法》。楊納傑克大部分歌劇作品都在馬亨劇院首演。在此舉行的全球首演還包括馬替奴的《瑪莉亞的奇蹟》和《門後的劇院》，以及普羅科菲夫的《羅密歐與茱麗葉》。時至今天，馬亨劇院主要是布爾諾國家歌劇院劇團的營運場地，而楊納傑克歌劇團和芭蕾團則自1965年起，便移師至後期落成的楊納傑克劇院。",
	NDB_p7p:
		"布爾諾國家歌劇院歌劇團於1965年10月寫下一座里程碑——當歌劇團搬進新建築楊納傑克劇院繼續運作，並以這位作曲家的作品《狡猾的小狐狸》揭開序幕。",
	NDB_p8p:
		"劇院籌備已久，由1910年到1958年間，一共舉辦七次建築設計比賽，以挑選最合適的設計，最後由建築師揚．維謝克及團隊所繪製的設計突圍而出。楊納傑克劇院開幕時，是當時捷克斯洛伐克最雄偉和技術最先進的劇院。",
	NDB_p9p:
		"楊納傑克劇院現已成為中歐頂尖的歌劇院之一，演出眾多十八至十九世紀的劇目，並與海外不同知名劇院合作。劇院每年舉辦布爾諾楊納傑克節，主要呈獻捷克與海外劇團的歌劇表演，另外還籌備交響樂和室樂音樂會、白天音樂會，以及其他與楊納傑克相關的表演節目。2018年，布爾諾楊納傑克節更在享負盛名的國際歌劇大獎中獲得「最佳藝術節」獎項，成為捷克共和國第一個獲得此獎項的音樂節。",
	NDB_v1c: "Libuše\n貝多伊齊．斯美塔那",
	NDB_v2c: "The Devil and Kate\n安東尼．德伏扎克",
	NDB_v3c: "The Greek Passion\n博胡斯拉夫．馬替奴",
	BackToTheMap: "回到地圖",
	JanacekTheatre_m: "楊納傑克\n劇院",
	NDB_m_p9p:
		"楊納傑克劇院現已成為中歐頂尖的歌劇院之一，演出眾多十八至十九世紀的劇目，並與海外不同知名劇院合作。劇院每年舉辦布爾諾楊納傑克節，主要呈獻捷克與海外劇團的歌劇表演，另外還籌備交響樂和室樂音樂會、白天音樂會，以及其他與楊納傑克相關的表演節目。2018年，布爾諾楊納傑克節更在享負盛名的國際歌劇大獎中獲得「最佳藝術節」獎項，成為捷克共和國第一個獲得此獎項的音樂節。",
};

const Share = {
	ShareJourney: "選擇你最喜愛的音樂旅途",
	ShareSM: "即到社交平台分享！",
};

const zh_hant = Object.assign(
	General,
	Menu,
	Map,
	Smetana,
	Dvorak,
	Janacek,
	Martinu,
	NDB,
	Share
);

export default zh_hant;
