import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../scss/Ndb.scss";
import * as LANG from "../Redux/Actions/Lang";
import * as ROUTE from "../Redux/Actions/Route";
import { useIntl } from "react-intl";
import { Controller, Scene } from "react-scrollmagic";
import { Timeline, Tween } from "react-gsap";
import NDBv1 from "../Assets/Images/NDB/Section12/Libuše_BedřichSmetana.mp4";
import NDBv2 from "../Assets/Images/NDB/Section12/TheDevilandKate_AntonínDvořák.mp4";
import NDBv3 from "../Assets/Images/NDB/Section12/TheGreekPassion_BohuslavMartinů.mp4";
import Constant from "../Constant";
import StaircasePhoto from "../Assets/Images/NDB/Section6/Staircase.png";
import Stage from "../Assets/Images/NDB/Section7/Stage.png";
import Libuše from "../Assets/Images/NDB/Section12/Libuše.png";
import TheDevilandKate from "../Assets/Images/NDB/Section12/TheDevilandKate.png";
import * as Scroll from "react-scroll";
import Cookies from "universal-cookie/es6";

const Ndb = () => {
	const history = useHistory();
	const [offsetY, setOffsetY] = useState(0);

	const dispatch = useDispatch();
	dispatch(ROUTE.NDB());

	const intl = useIntl();
	// @ts-ignore
	const lang = useSelector((state) => state.locale.lang);
	const cookies = new Cookies();

	useLayoutEffect(() => {
		function classChkOnReload() {
			var elements = document.getElementsByClassName("Section");
			for (var i = 0; i < elements.length; i++) {
				// @ts-ignore
				if (elements.item(i).offsetTop <= window.pageYOffset) {
					elements.item(i)?.classList.add("Active");
				}
			}
		}

		window.addEventListener("load", classChkOnReload);
		window.addEventListener("scroll", classChkOnReload);
		window.addEventListener("load", () => setOffsetY(window.pageYOffset));

		return () => {
			window.removeEventListener("load", classChkOnReload);
			window.removeEventListener("scroll", classChkOnReload);
			window.removeEventListener("load", () => setOffsetY(window.pageYOffset));
		};
	});

	useEffect(() => {
		window.name = "Ndb";

		const handleScroll = () => {
			setOffsetY(window.pageYOffset);
		};

		// function sectionSevenTop() {
		// 	if (
		// 		// @ts-ignore
		// 		window.pageYOffset > document.getElementById("Section Six").offsetTop &&
		// 		window.pageYOffset <
		// 			// @ts-ignore
		// 			document.getElementById("Section Six").offsetTop +
		// 				window.innerWidth * Constant.screenRatio +
		// 				window.innerWidth * 0.5
		// 	) {
		// 		// @ts-ignore
		// 		document.getElementById("SixAfter").style.position = "absolute";
		// 		// @ts-ignore
		// 		document.getElementById("SixAfter").style.top =
		// 			window.pageYOffset + window.innerWidth * Constant.screenRatio + "px";
		// 	}
		// }

		window.addEventListener("scroll", handleScroll);
		// window.addEventListener("scroll", sectionSevenTop);

		return () => {
			window.removeEventListener("scroll", handleScroll);
			// window.removeEventListener("scroll", sectionSevenTop);
		};
	});

	function openFullscreen(elem: HTMLElement) {
		if (!document.fullscreenElement) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
				// @ts-ignore
			} else if (elem.webkitRequestFullscreen) {
				/* Safari */
				// @ts-ignore
				elem.webkitRequestFullscreen();
				// @ts-ignore
			} else if (elem.msRequestFullscreen) {
				/* IE11 */
				// @ts-ignore
				elem.msRequestFullscreen();
			}
		}
	}

	function closeFullscreen() {
		if (document.fullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
				// @ts-ignore
			} else if (document.webkitExitFullscreen) {
				/* Safari */
				// @ts-ignore
				document.webkitExitFullscreen();
				// @ts-ignore
			} else if (document.msExitFullscreen) {
				/* IE11 */
				// @ts-ignore
				document.msExitFullscreen();
			}
		}
	}

	return (
		<div
			className={"NDB" + (lang === LANG.EN().type ? " ENG" : " ZH-HANT")}
			style={{
				backgroundPosition:
					(window.innerWidth > 450 ? "" : "-100vw " + offsetY + "px"),
			}}
		>
			<div
				id="Section One"
				className="Section One"
				onClick={() => {
					if (
						document.getElementById("Paragraph One")?.className ===
						"Paragraph One"
					) {
						document.getElementById("Paragraph One")?.classList.add("Visible");
					} else {
						Scroll.animateScroll.scrollTo(
							//@ts-ignore
							document.getElementById("Section Two")?.offsetTop
						);
					}
				}}
			>
				<div className="DotsRect" />
				<div
					className="Ellipse Green"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				{/* <div
					className="Ellipse Blue"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/> */}
				<div className={"ChapterName" + (lang === LANG.EN().type ? "" : " ZH")}>
					<p>
						{window.innerWidth > 450
							? intl.formatMessage({ id: "NationalTheatreBrno" })
							: intl.formatMessage({ id: "NationalTheatreBrno_m" })}
					</p>
				</div>
				<div id="Paragraph One" className="Paragraph One">
					<p>
						{intl.formatMessage({
							id: "NDB_p1p",
							defaultMessage:
								"The history of opera in Brno dates back to the early 18th century. It all began in a beautiful building, originally a tavern,  on the corner of Zelný trh (Green Market)  in the city centre, today called the Reduta and it is still part of the  National Theatre Brno.",
						})}
					</p>
				</div>
			</div>
			<div
				id="Section Two"
				className="Section Two"
				onClick={() => {
					if (
						offsetY <
						// @ts-ignore
						document.getElementById("Section Two")?.offsetTop +
							// @ts-ignore
							document.getElementById("Section Two")?.offsetHeight -
							1
					) {
						Scroll.animateScroll.scrollTo(
							// @ts-ignore
							document.getElementById("Section Two")?.offsetTop +
								// @ts-ignore
								document.getElementById("Section Two")?.offsetHeight
						);
					} else {
						Scroll.animateScroll.scrollTo(
							// @ts-ignore
							document.getElementById("Section Three")?.offsetTop
						);
					}
				}}
			>
				<Controller>
					<Scene triggerHook="onLeave" duration="100%" pin>
						<Timeline
							wrapper={
								<div
									className={
										"ScrollMagicWrapper" +
										(offsetY >
										//@ts-ignore
										document.getElementById("Section Two")?.offsetTop
											? " Stage2"
											: "")
									}
								/>
							}
							target={
								<section
									id="RedutaTheatrePhoto"
									className="RedutaTheatrePhoto"
									onClick={() => {
										Scroll.animateScroll.scrollTo(
											// @ts-ignore
											document.getElementById("Section Three")?.offsetTop
										);
									}}
								/>
							}
						>
							<div
								className={
									"TheatreTitle" + (lang === LANG.EN().type ? "" : " ZH")
								}
								style={{
									transform: `translateY(${Math.min(
										Math.max(
											offsetY -
												// @ts-ignore
												document.getElementById("Section Two")?.offsetTop / 2,
											0
										) * 0.5,
										window.innerHeight * 0.25
									)}px)`,
								}}
							>
								<p>{intl.formatMessage({ id: "RedutaTheatre" })}</p>
							</div>
							<Tween from={{ opacity: "0.5" }} to={{ opacity: "1" }} />
						</Timeline>
					</Scene>
				</Controller>
			</div>
			<div
				id="Section Three"
				className="Section Three"
				onClick={() => {
					if (window.innerWidth > 450) {
						if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section Three")?.offsetTop +
								window.innerHeight * 0.94
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Three")?.offsetTop +
									window.innerHeight * 0.95
							);
						} else if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section Three")?.offsetTop +
								window.innerHeight * 1.6
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Three")?.offsetTop +
									window.innerHeight * 1.7
							);
						} else if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section Three")?.offsetTop +
								window.innerHeight * 2.4
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Three")?.offsetTop +
									window.innerHeight * 2.5
							);
						} else {
							Scroll.animateScroll.scrollTo(
								//@ts-ignore
								document.getElementById("Section ThreePointOne")?.offsetTop
							);
						}
					} else {
						if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section Three")?.offsetTop +
								// @ts-ignore
								document.getElementById("TimelineEvent One")?.offsetTop -
								21
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Three")?.offsetTop +
									// @ts-ignore
									document.getElementById("TimelineEvent One")?.offsetTop -
									20
							);
						} else if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section Three")?.offsetTop +
								// @ts-ignore
								document.getElementById("TimelineEvent Five")?.offsetTop -
								21
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Three")?.offsetTop +
									// @ts-ignore
									document.getElementById("TimelineEvent Five")?.offsetTop -
									20
							);
						} else {
							Scroll.animateScroll.scrollTo(
								//@ts-ignore
								document.getElementById("Section ThreePointOne")?.offsetTop
							);
						}
					}
				}}
			>
				<div
					className="Ellipse Green"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				<div className="Paragraph Two">
					<p>
						{intl.formatMessage(
							{
								id: "NDB_p2p",
								defaultMessage:
									"When the Italian impresario Angelo Mingotti arrived in Brno in 1732, he built his own theatre in the riding school between ramparts under Špilberk Castle. It most likely served as the model for the city’s own theatre, built in 1733, with a deep-perspective stage and loges in the auditorium at Zelný trh. The theatre was the first opera house outside Italy to be established and funded by a municipality. Leading European artists such as Mozart, Liszt and Clara Schuman appeared at the Reduta and among its theatrical directors was Emanuel Schikaneder, librettist of Mozart’s opera {wordVal}.",
							},
							{ wordVal: <i>The Magic Flute</i> }
						)}
					</p>
				</div>
				<div className="BGDots RightDots" />
				<div className="Timeline" />
				<div id="TimelineEvent One" className="TimelineEvent One">
					<h1>1786</h1>
					<p>
						{intl.formatMessage(
							{
								id: "NDB_te1",
								defaultMessage:
									"The Reduta burns down for a second time. Rebuilt and influenced by the opera houses in Vienna, it was a place for Brno’s citizens to hear operatic performances, including Mozart’s {wordVal1}, and Salieri’s {wordVal2}.",
							},
							{ wordVal1: <i>The Magic Flute</i>, wordVal2: <i>Axur</i> }
						)}
					</p>
				</div>
				<div id="TimelineEvent Two" className="TimelineEvent Two">
					<h1>1870</h1>
					<p>
						{intl.formatMessage({
							id: "NDB_te2",
							defaultMessage:
								"The theatre’s flourishing artistic life was brought to a halt by another fire. It took 20 years for the building to be reconstructed, being reopened not as a theatre but as a market.",
						})}
					</p>
				</div>
				<div id="TimelineEvent Three" className="TimelineEvent Three">
					<h1>1909</h1>
					<p>
						{intl.formatMessage({
							id: "NDB_te3",
							defaultMessage: "The Reduta resumes life as a theatre in March.",
						})}
					</p>
				</div>
				<div id="TimelineEvent Four" className="TimelineEvent Four">
					<h1>1919</h1>
					<p>
						{intl.formatMessage({
							id: "NDB_te4",
							defaultMessage:
								"After the establishment of the Czechoslovak Republic, the theatre is co-administered by Czech and German companies.",
						})}
					</p>
				</div>
				<div id="TimelineEvent Five" className="TimelineEvent Five">
					<h1>1945</h1>
					<p>
						{intl.formatMessage({
							id: "NDB_te5",
							defaultMessage:
								"The Reduta housed an operetta ensemble after the Second World War but opera also continued to be staged.",
						})}
					</p>
				</div>
				<div id="TimelineEvent Six" className="TimelineEvent Six">
					<h1>1993</h1>
					<p>
						{intl.formatMessage({
							id: "NDB_te6",
							defaultMessage:
								"The building is closed due to the critical condition of the structure.",
						})}
					</p>
				</div>
				<div id="TimelineEvent Seven" className="TimelineEvent Seven">
					<h1>2005</h1>
					<p>
						{intl.formatMessage({
							id: "NDB_te7",
							defaultMessage:
								"The Reduta reopens after several years’ reconstruction.",
						})}
					</p>
				</div>
			</div>
			<div
				id="Section ThreePointOne"
				className="Section ThreePointOne"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section ThreePointTwo")?.offsetTop
					);
				}}
			/>
			<div
				id="Section ThreePointTwo"
				className="Section ThreePointTwo"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Four")?.offsetTop
					);
				}}
			>
				{lang === LANG.EN().type ? (
					<div className="TheatrenaVeveříTitle" />
				) : (
					<>
						<div className="TheatrenaVeveříTitle ZH">
							<p>維維日臨時劇院</p>
						</div>
					</>
				)}
				<p>
					{intl.formatMessage(
						{
							id: "NDB_p3p1",
							defaultMessage:
								"Czech theatre became to emerge in Brno in the 1880s; a temporary theatre building on the corner of Veveří Street on the site of the former U Marovských tavern housed opera, operetta and drama (and later also ballet) companies. It opened on 6 December 1884, with the first issue of {wordVal} (Music Pages) edited by the young Leoš Janáček, enthusiastically declaring:",
						},
						{ wordVal: <i>Hudební listy</i> }
					)}
				</p>
				<p className="Quote One">
					{intl.formatMessage({
						id: "NDB_q1",
						defaultMessage:
							'"The moment that we have waited so long for has finally arrived: \nthe national Theatre of Art has opened! \nThis signifies a new era for Brno and for the whole Moravia."',
					})}
				</p>
				<p>
					{intl.formatMessage({
						id: "NDB_p3p2",
						defaultMessage:
							"During World War II, the building was damaged and it was finally demolished in December 1973. The theatre building, which was supposed to be only temporary, would ultimately serve the National Theatre Brno for 68 full seasons.",
					})}
				</p>
			</div>
			<div
				id="Section Four"
				className="Section Four"
				onClick={() => {
					if (
						offsetY <
						// @ts-ignore
						document.getElementById("Section Four")?.offsetTop +
							// @ts-ignore
							document.getElementById("Section Four")?.offsetHeight -
							1
					) {
						Scroll.animateScroll.scrollTo(
							// @ts-ignore
							document.getElementById("Section Four")?.offsetTop +
								// @ts-ignore
								document.getElementById("Section Four")?.offsetHeight
						);
					} else {
						Scroll.animateScroll.scrollTo(
							// @ts-ignore
							document.getElementById("Section Five")?.offsetTop
						);
					}
				}}
			>
				<Controller>
					<Scene triggerHook="onLeave" duration="100%" pin>
						<Timeline
							wrapper={
								<div
									className={
										"ScrollMagicWrapper" +
										(offsetY >
										//@ts-ignore
										document.getElementById("Section Four")?.offsetTop
											? " Stage2"
											: "")
									}
								/>
							}
							target={
								<section
									className="MahenTheatrePhoto"
									onClick={() => {
										Scroll.animateScroll.scrollTo(
											// @ts-ignore
											document.getElementById("Section Three")?.offsetTop
										);
									}}
								/>
							}
						>
							<div
								className={
									"TheatreTitle" + (lang === LANG.EN().type ? "" : " ZH")
								}
								style={{
									transform: `translateY(${Math.min(
										Math.max(
											offsetY -
												// @ts-ignore
												(document.getElementById("Section Four")?.offsetTop +
													window.innerHeight / 2) /
													2,
											0
										) * 0.5,
										window.innerHeight * 0.25
									)}px)`,
								}}
							>
								<p>{intl.formatMessage({ id: "MahenTheatre" })}</p>
							</div>
							<Tween from={{ opacity: "0.5" }} to={{ opacity: "1" }} />
						</Timeline>
					</Scene>
				</Controller>
			</div>
			<div
				id="Section Five"
				className="Section Five"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Six")?.offsetTop
					);
				}}
			>
				<div
					className="Ellipse Green"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				<div
					className="Ellipse Blue"
					style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
				/>
				<div className="Paragraph Four">
					<p>
						{intl.formatMessage({
							id: "NDB_p4p1",
							defaultMessage:
								"After the Reduta Theatre was destroyed in a fire in 1870, Brno’s City Council decided to build a new theatre, which would be a symbol of prestige for the city.",
						})}
					</p>
					<p>
						{intl.formatMessage({
							id: "NDB_p4p2",
							defaultMessage:
								"The Viennese architects Fellner & Helmer were commissioned to design it and construction started on 18 July 1881. A series of devastating fires which damaged European theatres in 1881 led to the decision to replace the proposed gas lighting with a new invention: electric light, so the Mahen Theatre became the first in Europe to be fully lit by electricity.",
						})}
					</p>
				</div>
			</div>
			<div
				id="Section Six"
				className="Section Six"
				onClick={() => {
					if (window.innerWidth > 450) {
						if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section Six")?.offsetTop +
								window.innerWidth * 0.52
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Six")?.offsetTop +
									window.innerWidth * 0.53
							);
						} else {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Seven")?.offsetTop
							);
						}
					} else {
						Scroll.animateScroll.scrollTo(
							// @ts-ignore
							document.getElementById("Section Seven")?.offsetTop
						);
					}
				}}
			>
				{window.innerWidth > 450 ? (
					<Controller>
						<Scene triggerHook="onLeave" duration="100%" pin>
							<Timeline wrapper={<div className="ScrollMagicWrapper" />}>
								<div className="StaircaseBG" />
								<Tween from={{ x: "0%" }} to={{ x: "-33%" }}>
									<div className="Staircase">
										<div className="Images StaircasePhoto">
											<img src={StaircasePhoto} alt="" />
										</div>
										<div className="StaircasePhotoInfo">
											<p>
												{intl.formatMessage({
													id: "NDB_p5p",
													defaultMessage:
														"The exterior design of the theatre has neo-renaissance features; the main staircase was inspired by Paris’s Palais Garnier and the result is a prestigious building.",
												})}
											</p>
										</div>
									</div>
								</Tween>
							</Timeline>
						</Scene>
					</Controller>
				) : (
					<>
						<div className="StaircaseBG" />
						<div className="Staircase" style={{ left: "0vw !important" }}>
							<div className="Images StaircasePhoto">
								<img src={StaircasePhoto} alt="" />
							</div>
							<div className="StaircasePhotoInfo">
								<p>
									{intl.formatMessage({
										id: "NDB_p5p",
										defaultMessage:
											"The exterior design of the theatre has neo-renaissance features; the main staircase was inspired by Paris’s Palais Garnier and the result is a prestigious building.",
									})}
								</p>
							</div>
						</div>
					</>
				)}
			</div>

			<div
				id="Section Seven"
				className="Section Seven"
				onClick={() => {
					if (window.innerWidth > 450) {
						if (
							offsetY <
							// @ts-ignore
							document.getElementById("Section Seven")?.offsetTop +
								window.innerWidth * 0.52
						) {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Seven")?.offsetTop +
									window.innerWidth * 0.53
							);
						} else {
							Scroll.animateScroll.scrollTo(
								// @ts-ignore
								document.getElementById("Section Eight")?.offsetTop
							);
						}
					} else {
						Scroll.animateScroll.scrollTo(
							// @ts-ignore
							document.getElementById("Section Eight")?.offsetTop
						);
					}
				}}
			>
				{window.innerWidth > 450 ? (
					<Controller>
						<Scene triggerHook="onLeave" duration="100%" pin>
							<Timeline wrapper={<div className="ScrollMagicWrapper" />}>
								<div className="StageBG" />
								<Tween from={{ x: "0%" }} to={{ x: "-33.3%" }}>
									<section className="Stage">
										<div className="Images StagePhoto">
											<img src={Stage} alt="" />
										</div>
										<div className="StagePhotoInfo">
											<p>
												{intl.formatMessage(
													{
														id: "NDB_p6p",
														defaultMessage:
															"The German theatre was originally taken over by the Brno Czech National Theatre in 1919 and its first performance was Janáček's {wordVal1}. Most of the Janáček’s operas would be given their world premieres at the Mahen Theatre. Other notable world premieres at the theatre included Bohuslav Martinů’s {wordVal2} and {wordVal3}, and Prokofiev’s {wordVal4}.\n\nToday the Mahen Theatre is primarily home to the drama ensemble of the National Theatre Brno, while the Janáček Opera and the Ballet have, since 1965, been housed in the newer building of the Janáček Theatre.",
													},
													{
														wordVal1: <i>Jenůfa</i>,
														wordVal2: <i>The Miracles of Mary</i>,
														wordVal3: <i>Theatre Behind the Gate</i>,
														wordVal4: <i>Romeo and Juliet</i>,
													}
												)}
											</p>
										</div>
									</section>
								</Tween>
							</Timeline>
						</Scene>
					</Controller>
				) : (
					<>
						<div className="StageBG" />
						<div className="Stage">
							<div className="Images StagePhoto">
								<img src={Stage} alt="" />
							</div>
							<div className="StagePhotoInfo">
								<p>
									{intl.formatMessage(
										{
											id: "NDB_p6p",
											defaultMessage:
												"The German theatre was originally taken over by the Brno Czech National Theatre in 1919 and its first performance was Janáček's {wordVal1}. Most of the Janáček’s operas would be given their world premieres at the Mahen Theatre. Other notable world premieres at the theatre included Bohuslav Martinů’s {wordVal2} and {wordVal3}, and Prokofiev’s {wordVal4}.\n\nToday the Mahen Theatre is primarily home to the drama ensemble of the National Theatre Brno, while the Janáček Opera and the Ballet have, since 1965, been housed in the newer building of the Janáček Theatre.",
										},
										{
											wordVal1: <i>Jenůfa</i>,
											wordVal2: <i>The Miracles of Mary</i>,
											wordVal3: <i>Theatre Behind the Gate</i>,
											wordVal4: <i>Romeo and Juliet</i>,
										}
									)}
								</p>
							</div>
						</div>
					</>
				)}
			</div>
			<div
				id="Section Eight"
				className="Section Eight"
				onClick={() => {
					if (
						offsetY <
						// @ts-ignore
						document.getElementById("Section Eight")?.offsetTop +
							// @ts-ignore
							document.getElementById("Section Eight")?.offsetHeight -
							1
					) {
						Scroll.animateScroll.scrollTo(
							// @ts-ignore
							document.getElementById("Section Eight")?.offsetTop +
								// @ts-ignore
								document.getElementById("Section Eight")?.offsetHeight
						);
					} else {
						Scroll.animateScroll.scrollTo(
							// @ts-ignore
							document.getElementById("Section Nine")?.offsetTop
						);
					}
				}}
			>
				<Controller>
					<Scene triggerHook="onLeave" duration="100%" pin>
						<Timeline
							wrapper={
								<div
									className={
										"ScrollMagicWrapper" +
										(offsetY >
										//@ts-ignore
										document.getElementById("Section Eight")?.offsetTop
											? " Stage2"
											: "")
									}
								/>
							}
							target={<section className="JanáčekTheatrePhoto" />}
						>
							<div
								className="TheatreTitleContainer"
								style={{
									transform: `translateY(${Math.min(
										Math.max(
											offsetY -
												// @ts-ignore
												(document.getElementById("Section Eight")?.offsetTop +
													window.innerHeight / 2) /
													2,
											0
										) * 0.5,
										window.innerHeight * 0.25
									)}px)`,
								}}
							>
								{lang === LANG.EN().type ? (
									<div className="TheatreTitle" />
								) : (
									<div className="TheatreTitle ZH">
										<p>
											{window.innerWidth > 450
												? intl.formatMessage({ id: "JanacekTheatre" })
												: intl.formatMessage({ id: "JanacekTheatre_m" })}
										</p>
									</div>
								)}
							</div>
							<Tween from={{ opacity: "0.5" }} to={{ opacity: "1" }} />
						</Timeline>
					</Scene>
				</Controller>
			</div>
			<div
				id="Section Nine"
				className="Section Nine"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Ten")?.offsetTop
					);
				}}
			>
				<div className="LeftHalf">
					<div
						id="JanáčekTheatre1"
						className="JanáčekTheatre1"
						style={{
							transform:
								"scale(" +
								(Math.min(
									Math.abs(
										offsetY -
											// @ts-ignore
											document.getElementById("JanáčekTheatre1")?.offsetTop -
											// @ts-ignore
											document.getElementById("Section Nine")?.offsetTop
									) /
										// @ts-ignore
										document.getElementById("JanáčekTheatre1")?.offsetHeight,
									1
								) *
									Constant.photoMagnifier +
									1) +
								")",
						}}
					/>
				</div>
				<div className="RightHalf">
					<p>
						{intl.formatMessage(
							{
								id: "NDB_p7p",
								defaultMessage:
									"A milestone for the opera ensemble came in October 1965 when it moved into a new building, the Janáček Theatre, opening with a production of the composer’s {wordVal}.",
							},
							{ wordVal: <i>The Cunning Little Vixen</i> }
						)}
					</p>
				</div>
			</div>
			<div
				id="Section Ten"
				className="Section Ten"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Eleven")?.offsetTop
					);
				}}
			>
				{window.innerWidth > 450 ? (
					<>
						<div className="LeftHalf">
							<p>
								{intl.formatMessage({
									id: "NDB_p8p",
									defaultMessage:
										"The theatre had been planned for a long time; between 1910 and 1958 seven architectural competitions were held to select a design. The winning one was drawn up by a team led by architect Jan Víšek. When it opened, the Janáček Theatre was the largest and technically best equipped theatre in what was then Czechoslovakia.",
								})}
							</p>
						</div>
						<div className="RightHalf">
							<div
								id="JanáčekTheatre2"
								className="JanáčekTheatre2"
								style={{
									transform:
										"scale(" +
										(Math.min(
											Math.abs(
												offsetY -
													// @ts-ignore
													document.getElementById("JanáčekTheatre2")
														?.offsetTop -
													// @ts-ignore
													document.getElementById("Section Ten")?.offsetTop
											) /
												// @ts-ignore
												document.getElementById("JanáčekTheatre2")
													?.offsetHeight,
											1
										) *
											Constant.photoMagnifier +
											1) +
										")",
								}}
							/>
						</div>
					</>
				) : (
					<>
						<div className="RightHalf">
							<div
								id="JanáčekTheatre2"
								className="JanáčekTheatre2"
								style={{
									transform:
										"scale(" +
										(Math.min(
											Math.abs(
												offsetY -
													// @ts-ignore
													document.getElementById("JanáčekTheatre2")
														?.offsetTop -
													// @ts-ignore
													document.getElementById("Section Ten")?.offsetTop
											) /
												// @ts-ignore
												document.getElementById("JanáčekTheatre2")
													?.offsetHeight,
											1
										) *
											Constant.photoMagnifier +
											1) +
										")",
								}}
							/>
						</div>
						<div className="LeftHalf">
							<p>
								{intl.formatMessage({
									id: "NDB_p8p",
									defaultMessage:
										"The theatre had been planned for a long time; between 1910 and 1958 seven architectural competitions were held to select a design. The winning one was drawn up by a team led by architect Jan Víšek. When it opened, the Janáček Theatre was the largest and technically best equipped theatre in what was then Czechoslovakia.",
								})}
							</p>
						</div>
					</>
				)}
			</div>
			<div
				id="Section Eleven"
				className="Section Eleven"
				onClick={() => {
					Scroll.animateScroll.scrollTo(
						//@ts-ignore
						document.getElementById("Section Twelve")?.offsetTop
					);
				}}
			>
				<div className="YellowEllipse" />
				<div className="Paragraph Five">
					<p>
						{window.innerWidth > 450
							? intl.formatMessage({
									id: "NDB_p9p",
									defaultMessage:
										"The Janáček Opera is now \namong the leading opera houses in Central Europe, \noffering a wide repertoire of 18th and 19th opera. The \ncompany also collaborates with distinguished theatres abroad. \nThe Janáček Theatre is also home to the International Festival \nJanáček Brno. The festival features mainly opera performances \nby Czech and foreign companies but also symphonic and chamber \nconcerts, matinees, and other programmes connected with the \ncomposer. Janáček Brno became the first festival in the \nCzech Republic to win Best Festival at the prestigious \nInternational Opera Awards in 2018.",
							  })
							: intl.formatMessage({
									id: "NDB_m_p9p",
									defaultMessage: "",
							  })}
					</p>
				</div>
			</div>
			<div id="Section Twelve" className="Section Twelve">
				<div className="NDBVideoContainer">
					<div id="NDBv1 Mask" className="NDBVideoMask">
						<p>
							{intl.formatMessage({
								id: "NDB_v1c",
								defaultMessage: "Libuše\nBedřich Smetana",
							})}
						</p>
						<div
							className="VideoMaskBtn"
							onClick={() => {
								// @ts-ignore
								document.getElementById("NDBv1").play();
								// @ts-ignore
								document.getElementById("NDBv1").style.zIndex = "100";
								// @ts-ignore
								document.getElementById("NDBv1 Mask").style.opacity = "0";
								// @ts-ignore
								document.getElementById("NDBv1 Mask").style.zIndex = "-200";
							}}
						/>
					</div>
					<video
						id="NDBv1"
						className="NDBVideo"
						onPlay={() => {
							// @ts-ignore
							openFullscreen(document.getElementById("NDBv1"));
						}}
						onPause={() => {
							// @ts-ignore
							closeFullscreen();
							// @ts-ignore
							document.getElementById("NDBv1").style.zIndex = "-100";
							// @ts-ignore
							document.getElementById("NDBv1 Mask").style.opacity = "1";
							// @ts-ignore
							document.getElementById("NDBv1 Mask").style.zIndex = "100";
						}}
						poster={Libuše}
						controlsList="nodownload"
						controls
					>
						<source src={NDBv1} type="video/mp4" />
					</video>
				</div>
				<div className="NDBVideoContainer">
					<div id="NDBv2 Mask" className="NDBVideoMask">
						<p>
							{intl.formatMessage({
								id: "NDB_v2c",
								defaultMessage: "The Devil and Kate\nAntonín Dvořák",
							})}
						</p>
						<div
							className="VideoMaskBtn"
							onClick={() => {
								// @ts-ignore
								document.getElementById("NDBv2").play();
								// @ts-ignore
								document.getElementById("NDBv2").style.zIndex = "100";
								// @ts-ignore
								document.getElementById("NDBv2 Mask").style.opacity = "0";
								// @ts-ignore
								document.getElementById("NDBv2 Mask").style.zIndex = "-200";
							}}
						/>
					</div>
					<video
						id="NDBv2"
						className="NDBVideo"
						onPlay={() => {
							// @ts-ignore
							openFullscreen(document.getElementById("NDBv2"));
						}}
						onPause={() => {
							// @ts-ignore
							closeFullscreen();
							// @ts-ignore
							document.getElementById("NDBv2").style.zIndex = "-100";
							// @ts-ignore
							document.getElementById("NDBv2 Mask").style.opacity = "1";
							// @ts-ignore
							document.getElementById("NDBv2 Mask").style.zIndex = "100";
						}}
						poster={TheDevilandKate}
						controlsList="nodownload"
						controls
					>
						<source src={NDBv2} type="video/mp4" />
					</video>
				</div>
				<div className="NDBVideoContainer">
					<div id="NDBv3 Mask" className="NDBVideoMask">
						<p>
							{intl.formatMessage({
								id: "NDB_v3c",
								defaultMessage: "The Greek Passion\nBohuslav Martinů",
							})}
						</p>
						<div
							className="VideoMaskBtn"
							onClick={() => {
								// @ts-ignore
								document.getElementById("NDBv3").play();
								// @ts-ignore
								document.getElementById("NDBv3").style.zIndex = "100";
								// @ts-ignore
								document.getElementById("NDBv3 Mask").style.opacity = "0";
								// @ts-ignore
								document.getElementById("NDBv3 Mask").style.zIndex = "-200";
							}}
						/>
					</div>
					<video
						id="NDBv3"
						className="NDBVideo"
						onPlay={() => {
							// @ts-ignore
							openFullscreen(document.getElementById("NDBv3"));
						}}
						onPause={() => {
							// @ts-ignore
							closeFullscreen();
							// @ts-ignore
							document.getElementById("NDBv3").style.zIndex = "-100";
							// @ts-ignore
							document.getElementById("NDBv3 Mask").style.opacity = "1";
							// @ts-ignore
							document.getElementById("NDBv3 Mask").style.zIndex = "100";
						}}
						controlsList="nodownload"
						controls
					>
						<source src={NDBv3} type="video/mp4" />
					</video>
				</div>
				<div
					className="MapRedirect"
					onClick={() => {
						cookies.set("ShareExp", "visible");
						window.open("/");
					}}
				>
					<p>{intl.formatMessage({ id: "BackToTheMap" })}</p>
				</div>
			</div>
		</div>
	);
};

export default Ndb;
