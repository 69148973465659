import { RouteConfig } from "react-router-config";
import Dvorak from "./Pages/Composers/Dvorak";
import Janacek from "./Pages/Composers/Janacek";
import Smetana from "./Pages/Composers/Smetana";
import Martinu from "./Pages/Composers/Martinu";
import Map from "./Pages/Map";
import Intro from "./Pages/Intro";
import Ndb from "./Pages/Ndb";

const routes: RouteConfig[] = [
	{
		path: "/Janacek",
		component: Janacek,
		exact: true,
		breadcrumbName: "Janacek",
	},
	{
		path: "/Dvorak",
		component: Dvorak,
		exact: true,
		breadcrumbName: "Dvorak",
	},
	{
		path: "/Smetana",
		component: Smetana,
		exact: true,
		breadcrumbName: "Smetana",
	},
	{
		path: "/Martinu",
		component: Martinu,
		exact: true,
		breadcrumbName: "Martinu",
	},
	{
		path: "/Intro",
		component: Intro,
		exact: true,
		breadcrumbName: "Introduction",
	},
	{
		path: "/Ndb",
		component: Ndb,
		exact: true,
		breadcrumbName: "National Theatre of Brno",
	},
	{
		path: "",
		component: Map,
		exact: true,
		breadcrumbName: "Map",
	},
];

export default routes;
