import React, { MouseEvent, useEffect, useLayoutEffect, useState } from "react";
import "../scss/Map.scss";
import "../scss/Component.scss";
import { PlayState, Timeline, Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import { useHistory } from "react-router-dom";
import JanacekGIF from "../Assets/Images/Map/Composer/Janacek.gif";
import SmetanaGIF from "../Assets/Images/Map/Composer/Smetana.gif";
import MartinuGIF from "../Assets/Images/Map/Composer/Martinu.gif";
import DvorakGIF from "../Assets/Images/Map/Composer/Dvorak.gif";
import MapObject from "../Components/MapObject";
import { useDispatch, useSelector } from "react-redux";
import * as ROUTE from "../Redux/Actions/Route";
import { useIntl } from "react-intl";
import Cookies from "universal-cookie/es6";
import SmetanaJourney from "../Assets/Images/ShareExp/smetana.png";
import DvorakJourney from "../Assets/Images/ShareExp/dvorak.png";
import JanacekJourney from "../Assets/Images/ShareExp/janacek.png";
import MartinuJourney from "../Assets/Images/ShareExp/martinu.png";
import * as LANG from "../Redux/Actions/Lang";
import Constant from "../Constant";
import * as SHARE from "../Redux/Actions/Share";

const Map = () => {
	const history = useHistory();
	const [YOffset, setYOffset] = useState(window.pageYOffset);
	const [maskSlide, setMaskSlide] = useState(PlayState.stop);
	const [composerHovering, setComposerHovering] = useState("ComposerHovering");
	const [selectedComposer, setSelectedComposer] = useState("SelectedComposer");
	const [selectedCity, setSelectedCity] = useState("SelectedCity");
	const [selectedBuilding, setSelectedBuilding] = useState("SelectedBuilding");
	const [transMaskClass, setTransMaskClass] = useState("TransMask");
	const [firstScroll, setFirstScroll] = useState(true);
	const dispatch = useDispatch();
	dispatch(ROUTE.MAP());

	const intl = useIntl();
	const cookies = new Cookies();
	// @ts-ignore
	const lang = useSelector((state) => state.locale.lang);
	const [isDrag, setIsDrag] = useState(false);
	const [initialX, setInitialX] = useState(0);
	const [initialY, setInitialY] = useState(
		(window.innerHeight - 1366 * Constant.screenRatio) / 2
	);
	const [startX, setStartX] = useState(0);
	const [startY, setStartY] = useState(0);
	// @ts-ignore
	const showShare = useSelector((state) => state.share.show === "On");

	useLayoutEffect(() => {
		window.scrollTo(0, 0);

		function updateSize() {
			if (window.innerWidth > 1366) {
				// @ts-ignore
				document.getElementById("Map").style.cursor = "initial";
				// @ts-ignore
				document.getElementById("BackgroundMap").style = "";
				// @ts-ignore
				document.getElementById("BackgroundContainer").style = "";
			}

			// @ts-ignore
			document.getElementById("BackgroundMap").style.width =
				Math.max(window.innerWidth, 1366) + "px";
			// @ts-ignore
			document.getElementById("BackgroundMap").style.height =
				(Math.max(window.innerWidth, 1366) * 2304) / 4096 + "px";

			// @ts-ignore
			document.getElementById("BackgroundContainer").style.width =
				Math.max(window.innerWidth, 1366) + "px";
			// @ts-ignore
			document.getElementById("BackgroundContainer").style.height =
				(Math.max(window.innerWidth, 1366) * 2304) / 4096 + "px";
		}

		window.addEventListener("resize", updateSize);
		window.addEventListener("touchstart", dragStart);
		window.addEventListener("touchmove", dragMove);
		window.addEventListener("touchend", dragRelease);

		return () => {
			window.removeEventListener("resize", updateSize);
			window.removeEventListener("touchstart", dragStart);
			window.removeEventListener("touchmove", dragMove);
			window.removeEventListener("touchend", dragRelease);
		};
	});

	useEffect(() => {
		document.body.classList.add("Body");

		// @ts-ignore
		window.addEventListener("scroll", (e) => onScroll(e));

		window.name = "Home";

		if (cookies.get("ShareExp") === "visible") {
			dispatch(SHARE.ON());
		}

		return () => {
			// @ts-ignore
			window.removeEventListener("scroll", (e) => onScroll(e));
		};
	});

	function onScroll(e: MouseEvent) {
		if (document.getElementById("TransMask")?.className === "TransMask") {
			document.getElementById("TransMask")?.classList.add("Two");

			setTimeout(() => {
				setFirstScroll(false);
			}, 750);
		} else if (
			document.getElementById("TransMask")?.className !== "TransMask Slided"
		) {
			setMaskSlide(PlayState.play);
			setYOffset(window.pageYOffset);
			setTransMaskClass("TransMask Sliding");

			setTimeout(() => {
				setTransMaskClass("TransMask Slided");
			}, 2000);
		}

		e.preventDefault();
	}

	function onMaskBtnClick(e: MouseEvent) {
		if (document.getElementById("TransMask")?.className === "TransMask") {
			document.getElementById("TransMask")?.classList.add("Two");

			setFirstScroll(false);
		} else if (
			document.getElementById("TransMask")?.className !== "TransMask Slided"
		) {
			setMaskSlide(PlayState.play);
			setYOffset(window.pageYOffset);
			setTransMaskClass("TransMask Sliding");

			setTimeout(() => {
				setTransMaskClass("TransMask Slided");
			}, 1500);
		}

		e.preventDefault();
	}

	function onEnterComposer(composer: string) {
		if (selectedComposer.split(" ")[1] === composer) {
			setSelectedComposer("SelectedComposer");
		} else {
			let newClass = "SelectedComposer";

			setSelectedComposer(newClass.concat(" " + composer));
		}
		setSelectedBuilding("SelectedBuilding");
	}

	function onLeaveComposer() {
		setSelectedComposer("SelectedComposer");
		setSelectedBuilding("SelectedBuilding");
	}

	function onClickComposer(e: MouseEvent, composer: string) {
		document.getElementById("TutorialTwo")?.classList.add("Readed");
		cookies.set("TutorialTwo", "Readed", { path: "/" });
		window.open("/" + composer);
	}

	function onEnterCity(city: string) {
		let newClass = "SelectedCity";

		setSelectedCity(newClass.concat(" " + city));
	}

	function onLeaveCity() {
		setSelectedCity("SelectedCity");
	}

	function onEnter(building: string) {
		switch (building) {
			case "JanacekTheatre":
			case "MahenTheatre":
			case "RedutaTheatre":
			case "JanacekHouse":
				onEnterCity("Brno");
				break;
			case "OrganistHouse":
				onEnterCity("Kladno");
				break;
			case "SychrovCastle":
				onEnterCity("Liberec");
				break;
			case "MoravianTheatre":
				onEnterCity("Olomouc");
				break;
			case "MonumentOfBystrouška":
				onEnterCity("Ostrava");
				break;
			case "LitomyšlCastle":
			case "StJamesChurch":
				onEnterCity("Pardubice");
				break;
			case "CharlesBridge":
			case "Rudolfinum":
			case "EstatesTheatre":
			case "NationalTheatre":
			case "Vyšehrad":
				onEnterCity("Prague");
				break;
			case "DvorakMemorial":
				onEnterCity("Příbram");
				break;
			case "AugustinianHouse":
				onEnterCity("Zlín");
				break;
			default:
				break;
		}
	}

	function onLeave() {
		setSelectedCity("SelectedCity");
	}

	function onClickBuilding(e: MouseEvent, building: string) {
		if (!cookies.get("TutorialOne")) {
			document.getElementById("TutorialOne")?.classList.add("Readed");
			cookies.set("TutorialOne", "Readed", { path: "/" });
		}

		if (selectedBuilding.split(" ")[1] === building) {
			setSelectedBuilding("SelectedBuilding");
		} else {
			let newClass = "SelectedBuilding";

			setSelectedBuilding(newClass.concat(" " + building));
		}

		setSelectedComposer("SelectedComposer");

		e.stopPropagation();
	}

	function onClickMap() {
		setSelectedBuilding("SelectedBuilding");
		setSelectedComposer("SelectedComposer");
	}

	function dragStart(e: any) {
		if (
			document.getElementById("background")?.classList.contains("disable") ||
			cookies.get("ShareExp") === "visible"
		) {
			return;
		}

		if (
			window.innerWidth < 1366 ||
			window.innerHeight < (window.innerWidth * 2304) / 4096
		) {
			console.log("DragStart");
			setIsDrag(true);
			// @ts-ignore
			setStartX(e.screenX || e.touches[0].screenX);
			// @ts-ignore
			setStartY(e.screenY || e.touches[0].screenY);
			setInitialX(
				// @ts-ignore
				document.getElementById("BackgroundContainer").offsetLeft
			);
			// @ts-ignore
			setInitialY(document.getElementById("BackgroundContainer").offsetTop);

			// @ts-ignore
			document.getElementById("Map").style.cursor = "grabbing";
		}
	}

	function dragMove(e: any) {
		if (isDrag && window.innerWidth < 1366) {
			// @ts-ignore
			document.getElementById("BackgroundMap").style.left =
				Math.max(
					Math.min((e.screenX || e.touches[0].screenX) - startX + initialX, 0),
					window.innerWidth - 1366
				) + "px";

			// @ts-ignore
			document.getElementById("BackgroundContainer").style.left =
				Math.max(
					Math.min((e.screenX || e.touches[0].screenX) - startX + initialX, 0),
					window.innerWidth - 1366
				) + "px";

			if (window.innerHeight > (window.innerWidth * 2304) / 4096) {
				// @ts-ignore
				document.getElementById("BackgroundMap").style.top =
					Math.min(
						Math.max(
							(e.screenY || e.touches[0].screenY) - startY + initialY,
							0
						),
						window.innerHeight - (1366 * 2304) / 4096
					) + "px";
				// @ts-ignore
				document.getElementById("BackgroundContainer").style.top =
					Math.min(
						Math.max(
							(e.screenY || e.touches[0].screenY) - startY + initialY,
							0
						),
						window.innerHeight - (1366 * 2304) / 4096
					) + "px";
			} else {
				// @ts-ignore
				document.getElementById("BackgroundMap").style.top =
					Math.min(
						Math.max(
							(e.screenY || e.touches[0].screenY) - startY + initialY,
							window.innerHeight - (1366 * 2304) / 4096
						),
						0
					) + "px";
				// @ts-ignore
				document.getElementById("BackgroundContainer").style.top =
					Math.min(
						Math.max(
							(e.screenY || e.touches[0].screenY) - startY + initialY,
							window.innerHeight - (1366 * 2304) / 4096
						),
						0
					) + "px";
			}
		} else if (
			isDrag &&
			window.innerHeight < (window.innerWidth * 2304) / 4096
		) {
			// @ts-ignore
			document.getElementById("BackgroundMap").style.top =
				Math.max(
					Math.min((e.screenY || e.touches[0].screenY) - startY + initialY, 0),
					window.innerHeight - (window.innerWidth * 2304) / 4096
				) + "px";
			// @ts-ignore
			document.getElementById("BackgroundContainer").style.top =
				Math.max(
					Math.min((e.screenY || e.touches[0].screenY) - startY + initialY, 0),
					window.innerHeight - (window.innerWidth * 2304) / 4096
				) + "px";
		}
	}

	function dragRelease() {
		setIsDrag(false);

		if (
			window.innerWidth < 1366 ||
			window.innerHeight < (window.innerWidth * 2304) / 4096
		) {
			// @ts-ignore
			document.getElementById("Map").style.cursor = "grab";
		}
	}

	return (
		<div
			id="Map"
			className={"Map" + (lang === LANG.EN().type ? " ENG" : " ZH-HANT")}
			onClick={() => console.log("test")}
			onMouseDown={dragStart}
			onMouseMove={dragMove}
			onMouseUp={dragRelease}
		>
			<Controller>
				<Scene triggerHook="onLeave" duration={1000} pin>
					<div>
						<Timeline
							wrapper={
								<div
									style={{
										height: "100vh",
										width: "100vw",
										overflow: "hidden",
									}}
								/>
							}
						>
							<div
								id="background"
								className={
									"Background" +
									(maskSlide === PlayState.play || showShare ? "" : " disable")
								}
								onClick={() => onClickMap()}
							>
								<div
									id="BackgroundMap"
									style={{
										width: Math.max(window.innerWidth, 1366),
										height: (Math.max(window.innerWidth, 1366) * 2304) / 4096,
									}}
								/>
								<div
									id="BackgroundContainer"
									className="BackgroundContainer"
									style={{
										width: Math.max(window.innerWidth, 1366),
										height: (Math.max(window.innerWidth, 1366) * 2304) / 4096,
									}}
								>
									<div className="LeftFooter">
										<p>{intl.formatMessage({ id: "ProjectName" })}</p>
									</div>
									<div
										className="KeepSelected"
										onMouseEnter={() => {
											setSelectedCity(selectedCity);
										}}
										onMouseLeave={() => setSelectedCity("selectedCity")}
									/>
									<div className={composerHovering}>
										<div className={selectedComposer}>
											<MapObject
												type="Composer"
												object="Dvorak"
												onEnter={onEnterComposer}
												onLeave={onLeaveComposer}
												onClick={onClickComposer}
												h1MsgCode="Dvorak"
											/>
											<MapObject
												type="Composer"
												object="Janacek"
												onEnter={onEnterComposer}
												onLeave={onLeaveComposer}
												onClick={onClickComposer}
												h1MsgCode="Janacek"
											/>
											<MapObject
												type="Composer"
												object="Martinu"
												onEnter={onEnterComposer}
												onLeave={onLeaveComposer}
												onClick={onClickComposer}
												h1MsgCode="Martinu"
											/>
											<MapObject
												type="Composer"
												object="Smetana"
												onEnter={onEnterComposer}
												onLeave={onLeaveComposer}
												onClick={onClickComposer}
												h1MsgCode="Smetana"
											/>
										</div>
										<div className="Character Dvorak" />
										<div className="Character Janacek" />
										<div className="Character Martinu" />
										<div className="Character Smetana" />
										<div className="CharacterGIF Janacek">
											<img src={JanacekGIF} alt="Janacek" />
										</div>
										<div className="CharacterGIF Smetana">
											<img src={SmetanaGIF} alt="Smetana" />
										</div>
										<div className="CharacterGIF Martinu">
											<img src={MartinuGIF} alt="Martinu" />
										</div>
										<div className="CharacterGIF Dvorak">
											<img src={DvorakGIF} alt="Dvorak" />
										</div>
										<div className="MiniCharacter Janacek sit" />
										<div className="MiniCharacter Janacek stand" />
										<div className="MiniCharacter Smetana sit" />
										<div className="MiniCharacter Smetana stand" />
										<div className="MiniCharacter Martinu sit" />
										<div className="MiniCharacter Martinu stand" />
										<div className="MiniCharacter Dvorak sit" />
										<div className="MiniCharacter Dvorak stand" />
									</div>
									<div className={selectedCity + " " + selectedBuilding}>
										<div className="Sun" />
										<div className="Moon" />
										<MapObject
											type="Building"
											object="JanacekHouse"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="JanacekHouse"
											pMsgCode="JanacekHouseTT"
										/>
										<MapObject
											type="Building"
											object="JanacekTheatre"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={(e) => onClickBuilding(e, "BrnoTheatre")}
											pMsgCode="BrnoTheatresTT"
										/>
										<MapObject
											type="Building"
											object="MahenTheatre"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={(e) => onClickBuilding(e, "BrnoTheatre")}
											pMsgCode="BrnoTheatresTT"
										/>
										<MapObject
											type="Building"
											object="RedutaTheatre"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={(e) => onClickBuilding(e, "BrnoTheatre")}
											pMsgCode="BrnoTheatresTT"
										/>
										<div className="Tooltip BrnoTheatre">
											<p>{intl.formatMessage({ id: "BrnoTheatresTT" })}</p>
											<div
												className="Arrow"
												onClick={() => window.open("/Ndb")}
											/>
										</div>
										<MapObject
											type="Building"
											object="OrganistHouse"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="OrganistHouse"
											pMsgCode="OrganistHouseTT"
										/>
										<MapObject
											type="Building"
											object="SychrovCastle"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="SychrovCastle"
											pMsgCode="SychrovCastleTT"
										/>
										<MapObject
											type="Building"
											object="MoravianTheatre"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="MoravianTheatre"
											pMsgCode="MoravianTheatreTT"
										/>
										<MapObject
											type="Building"
											object="MonumentOfBystrouška"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="MonumentOfBystrouška"
											pMsgCode="MonumentOfBystrouškaTT"
											wordVal="Hukvaldy"
											wordValLineBreak={true}
										/>
										<MapObject
											type="Building"
											object="LitomyšlCastle"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="LitomyšlCastle"
											pMsgCode="LitomyšlCastleTT"
										/>
										<MapObject
											type="Building"
											object="StJamesChurch"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="StJamesChurch"
											pMsgCode="StJamesChurchTT"
											wordVal="Polička"
										/>
										<MapObject
											type="Building"
											object="Rudolfinum"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="Rudolfinum"
											pMsgCode="RudolfinumTT"
										/>
										<MapObject
											type="Building"
											object="EstatesTheatre"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="EstatesTheatre"
											pMsgCode="EstatesTheatreTT"
										/>
										<MapObject
											type="Building"
											object="NationalTheatre"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="NationalTheatre"
											pMsgCode="NationalTheatreTT"
										/>
										<MapObject
											type="Building"
											object="Vyšehrad"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="Vyšehrad"
											pMsgCode="VyšehradTT"
										/>
										<MapObject
											type="Building"
											object="DvorakMemorial"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="DvorakMemorial"
											pMsgCode="DvorakMemorialTT"
										/>
										<MapObject
											type="Building"
											object="CharlesBridge"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="CharlesBridge"
											pMsgCode="CharlesBridgeTT"
										/>
										<MapObject
											type="Building"
											object="AugustinianHouse"
											onEnter={onEnter}
											onLeave={onLeave}
											onClick={onClickBuilding}
											h1MsgCode="AugustinianHouse"
											pMsgCode="AugustinianHouseTT"
											wordVal=", Luhačovice"
										/>
										<div
											className="City Brno"
											onMouseEnter={() => onEnterCity("Brno")}
											onMouseLeave={() => onLeaveCity()}
										/>
										<div
											className="City Kladno"
											onMouseEnter={() => onEnterCity("Kladno")}
											onMouseLeave={() => onLeaveCity()}
										/>
										<div
											className="City Liberec"
											onMouseEnter={() => onEnterCity("Liberec")}
											onMouseLeave={() => onLeaveCity()}
										/>
										<div
											className="City Olomouc"
											onMouseEnter={() => onEnterCity("Olomouc")}
											onMouseLeave={() => onLeaveCity()}
										/>
										<div
											className="City Ostrava"
											onMouseEnter={() => onEnterCity("Ostrava")}
											onMouseLeave={() => onLeaveCity()}
										/>
										<div
											className="City Pardubice"
											onMouseEnter={() => onEnterCity("Pardubice")}
											onMouseLeave={() => onLeaveCity()}
										/>
										<div
											className="City Prague"
											onMouseEnter={() => onEnterCity("Prague")}
											onMouseLeave={() => onLeaveCity()}
										/>
										<div
											className="City Příbram"
											onMouseEnter={() => onEnterCity("Příbram")}
											onMouseLeave={() => onLeaveCity()}
										/>
										<div
											className="City Zlín"
											onMouseEnter={() => onEnterCity("Zlín")}
											onMouseLeave={() => onLeaveCity()}
										/>
									</div>
									{cookies.get("TutorialOne") !== "Readed" ? (
										<div
											id="TutorialOne"
											className="TutorialOne"
											onClick={() => {
												document
													.getElementById("TutorialOne")
													?.classList.add("Readed");
												cookies.set("TutorialOne", "Readed", { path: "/" });
											}}
										>
											<p>{intl.formatMessage({ id: "TutorialOne" })}</p>
										</div>
									) : (
										<></>
									)}
									{cookies.get("TutorialTwo") !== "Readed" ? (
										<div id="TutorialTwo" className="TutorialTwo">
											<p>{intl.formatMessage({ id: "TutorialTwo" })}</p>
										</div>
									) : (
										<></>
									)}
								</div>
							</div>
							{showShare ? (
								<></>
							) : (
								<>
									<Tween
										from={{ y: "0%" }}
										to={{ y: "-100%" }}
										duration={2}
										playState={maskSlide}
										ease="back.out(0.1)"
									>
										<div id="WhiteMask" className="WhiteMask" />
									</Tween>
									<div id="TransMask" className={transMaskClass}>
										<div id="MaskOne" className="MaskOne">
											<div className="MaskLogo" />
											<div className="MaskTitle">
												<p>
													{window.innerWidth > 450
														? intl.formatMessage({ id: "ProjectName" })
														: intl.formatMessage({ id: "ProjectName_m" })}
												</p>
											</div>
											<div className="MaskBtn" onClick={onMaskBtnClick} />
										</div>
										<div id="MaskTwo" className="MaskTwo">
											<div>
												<h1>{intl.formatMessage({ id: "Introduction" })}</h1>
												<p>{intl.formatMessage({ id: "Intro" })}</p>
											</div>
											<div className="MaskBtn" onClick={onMaskBtnClick} />
										</div>
									</div>
								</>
							)}
						</Timeline>
						{cookies.get("ShareExp") === "visible" ? (
							<div id="ShareExp" className="ShareExp">
								<p>{intl.formatMessage({ id: "ShareJourney" })}</p>
								<div className="MusicJourney">
									<a href={SmetanaJourney} download>
										<div className="Journey SmetanaImg" />
									</a>
									<a href={DvorakJourney} download>
										<div className="Journey DvorakImg" />
									</a>
									<a href={JanacekJourney} download>
										<div className="Journey JanacekImg" />
									</a>
									<a href={MartinuJourney} download>
										<div className="Journey MartinuImg" />
									</a>
								</div>
								<p>{intl.formatMessage({ id: "ShareSM" })}</p>
								<p>#IntotheCzechLands #49hkaf</p>
							</div>
						) : (
							<></>
						)}
					</div>
				</Scene>
			</Controller>
		</div>
	);
};

export default Map;
