import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../scss/Janacek.scss";
import * as Scroll from "react-scroll";
import Portrait from "../../Assets/Images/Janacek/Section1/Portrait.png";
import LJ1_EN from "../../Assets/Images/Janacek/Section3/LJ1_EN.mp4";
import Folksong from "../../Assets/Images/Janacek/Section4/Folksong.png";
import LJ3_ENG from "../../Assets/Images/Janacek/Section9/LJ3_ENG.mp4";
import Signature from "../../Assets/Images/Janacek/Section10/Signature.png";
import Folksong2 from "../../Assets/Images/Janacek/Section6/Folksong.png";
import Iframe from "react-iframe";
import * as ROUTE from "../../Redux/Actions/Route";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Constant from "../../Constant";
import findNearestSnap from "../../Utils/GeneralUtils";
import * as LANG from "../../Redux/Actions/Lang";

const Janacek = () => {
  const [footerHover, setFooterHover] = useState(false);
  const history = useHistory();
  const [offsetY, setOffsetY] = useState(0);

  const [currSlide, setCurrSlide] = useState("One");
  const [sliderClass, setSliderClass] = useState("One");

  const dispatch = useDispatch();
  dispatch(ROUTE.JANACEK());

  const intl = useIntl();
  // @ts-ignore
  const lang = useSelector((state) => state.locale.lang);

  const [isDrag, setIsDrag] = useState(false);

  useLayoutEffect(() => {
    function classChkOnReload() {
      var elements = document.getElementsByClassName("Section");
      for (var i = 0; i < elements.length; i++) {
        // @ts-ignore
        if (elements.item(i).offsetTop <= window.pageYOffset) {
          elements.item(i)?.classList.add("Active");
        }
      }
    }

    window.addEventListener("load", classChkOnReload);
    window.addEventListener("scroll", classChkOnReload);
    window.addEventListener("load", () => setOffsetY(window.pageYOffset));

    return () => {
      window.removeEventListener("load", classChkOnReload);
      window.removeEventListener("scroll", classChkOnReload);
      window.removeEventListener("load", () => setOffsetY(window.pageYOffset));
    };
  });

  useEffect(() => {
    window.name = "Janacek";

    const handleScroll = () => setOffsetY(window.pageYOffset);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  function nextSlide() {
    switch (currSlide) {
      case "One":
        setCurrSlide("Two");
        setSliderClass("One2Two");
        break;
      case "Two":
        setCurrSlide("Three");
        setSliderClass("Two2Three");
        break;
      case "Three":
        setCurrSlide("Four");
        setSliderClass("Three2Four");
        break;
      case "Four":
        setCurrSlide("Five");
        setSliderClass("Four2Five");
        break;
      default:
        break;
    }

    // @ts-ignore
    document.getElementById("ProgressBar")?.setAttribute("style", "");
  }

  function prevSlide() {
    switch (currSlide) {
      case "Five":
        setCurrSlide("Four");
        setSliderClass("Five2Four");
        break;
      case "Four":
        setCurrSlide("Three");
        setSliderClass("Four2Three");
        break;
      case "Three":
        setCurrSlide("Two");
        setSliderClass("Three2Two");
        break;
      case "Two":
        setCurrSlide("One");
        setSliderClass("Two2One");
        break;
      default:
        break;
    }

    // @ts-ignore
    document.getElementById("ProgressBar")?.setAttribute("style", "");
  }

  function setSlide(slide: number) {
    switch (slide) {
      case 0:
        setCurrSlide("One");
        setSliderClass("Two2One");
        break;
      case 1:
        setCurrSlide("Two");
        setSliderClass("One2Two");
        break;
      case 2:
        setCurrSlide("Three");
        setSliderClass("Two2Three");
        break;
      case 3:
        setCurrSlide("Four");
        setSliderClass("Three2Four");
        break;
      case 4:
        setCurrSlide("Five");
        setSliderClass("Four2Five");
        break;
      default:
        break;
    }
  }

  function openFullscreen(elem: HTMLElement) {
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        // @ts-ignore
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        // @ts-ignore
        elem.webkitRequestFullscreen();
        // @ts-ignore
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        // @ts-ignore
        elem.msRequestFullscreen();
      }
    }
  }

  function closeFullscreen() {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        // @ts-ignore
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        // @ts-ignore
        document.webkitExitFullscreen();
        // @ts-ignore
      } else if (document.msExitFullscreen) {
        /* IE11 */
        // @ts-ignore
        document.msExitFullscreen();
      }
    }
  }

  return (
    <div
      className={
        "ChapterJanacek" + (lang === LANG.EN().type ? " ENG" : " ZH-HANT")
      }
      style={{
        backgroundPosition:
          window.innerWidth > 450 ? "" : "-100vw " + offsetY + "px",
      }}
    >
      <div className="ChapterCaption">
        <p>
          {intl.formatMessage(
            {
              id: "Janacek_BGc",
              defaultMessage:
                "Autograph page of Fanfare (1st movement) from {wordVal}.",
            },
            { wordVal: <i>Sinfonietta</i> }
          )}
        </p>
      </div>
      <div
        id="Section One"
        className="Section One"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Two")?.offsetTop
          );
          document.getElementById("Section Two")?.classList.add("Active");
        }}
      >
        <div className="DotsRect" />
        <div className="ImgContainer Portrait">
          <img src={Portrait} alt="Janacek Portrait" />
        </div>
        <div
          className="Ellipse Green"
          style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        />
        <div
          className="Ellipse Blue"
          style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        />
        {lang === LANG.EN().type ? (
          <div className="ChapterName" />
        ) : (
          <div className="ChapterName ZH">
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage({ id: "Janacek" })
                : intl.formatMessage({ id: "Janacek_m" })}
            </p>
          </div>
        )}
        <div className="ChapterYear">
          <p>(1854-1928)</p>
        </div>
      </div>
      <div
        id="Section Two"
        className="Section Two"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Three")?.offsetTop
          );
        }}
      >
        <div
          className="clipContent"
          // @ts-ignore
          // style={{ opacity: `${window.pageYOffset / sectTwoPos}` }}
        >
          <div className="DotsRect" />
          <div
            className="Ellipse Blue"
            style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
          />
          <div className="Paragraph One">
            <h1>
              {intl.formatMessage({
                id: "Janacek_p1h1",
                defaultMessage: "Early Life",
              })}
            </h1>
            <p>
              {intl.formatMessage({
                id: "Janacek_p1p",
                defaultMessage:
                  "Janáček grew up in poverty, yet his interest in composition saw him study at the conservatories in Leipzig and Vienna. Upon returning to Brno in 1875, he tirelessly worked to make the Moravian capital his musical centre and cultural base. When his first opera failed to resonate with the public, Janáček immersed himself into the folk music of his native region.",
              })}
            </p>
          </div>
        </div>
        <div
          className="Ellipse Green"
          style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        />
      </div>
      <div id="Section Three" className="Section Three">
        <div className="VideoContainer">
          <div id="LJ1_EN Mask" className="VideoMask">
            <p>
              {intl.formatMessage({
                id: "Janacek_v1p",
                defaultMessage:
                  "The animated video briefly illustrates Janáček's birth, his arrival in Brno and his student days. Created in cooperation between the artist Vendula Chalánková and the animators Tomoko Lisá and David Lisý.",
              })}
            </p>
            <div
              className="VideoMaskBtn"
              onClick={() => {
                // @ts-ignore
                document.getElementById("LJ1_EN").play();
                // @ts-ignore
                document.getElementById("LJ1_EN Mask").style.opacity = "0";
                // @ts-ignore
                document.getElementById("LJ1_EN Mask").style.zIndex = "-100";
              }}
            />
            <p style={{ fontSize: "1vw", textAlign: "center" }}>
              {intl.formatMessage(
                {
                  id: "Janacek_vlink",
                  defaultMessage: "See more at Brno's office {link}",
                },
                {
                  link: (
                    <a href="https://www.leosjanacek.eu/en/">Janáček website</a>
                  ),
                }
              )}
            </p>
          </div>
          <video
            id="LJ1_EN"
            className="Video"
            onPlay={() => {
              // @ts-ignore
              document.getElementById("LJ1_EN Mask").style.opacity = "0";
              // @ts-ignore
              document.getElementById("LJ1_EN Mask").style.zIndex = "-100";
              // @ts-ignore
              openFullscreen(document.getElementById("LJ1_EN"));
            }}
            onPause={() => {
              // @ts-ignore
              document.getElementById("LJ1_EN Mask").style.zIndex = "100";
              // @ts-ignore
              document.getElementById("LJ1_EN Mask").style.opacity = "1";
            }}
            controlsList="nodownload"
            controls
          >
            <source src={LJ1_EN} type="video/mp4" />
          </video>
        </div>
      </div>
      <div
        id="Section Four"
        className="Section Four"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Five")?.offsetTop
          );
        }}
      >
        <div id="FolksongOne" className="Images Folksong">
          <img
            src={Folksong}
            alt="Janacek collecting folksong"
            style={{
              transform:
                "scale(" +
                (Math.min(
                  Math.abs(
                    offsetY -
                      // @ts-ignore
                      document.getElementById("FolksongOne")?.offsetTop -
                      // @ts-ignore
                      document.getElementById("Section Four")?.offsetTop
                  ) /
                    // @ts-ignore
                    document.getElementById("FolksongOne")?.offsetHeight,
                  1
                ) *
                  Constant.photoMagnifier +
                  1) +
                ")",
            }}
          />
          <div className="Caption Folksong">
            <p>
              {intl.formatMessage({
                id: "Janacek_c1",
                defaultMessage:
                  "Leoš Janáček collecting folksongs in Strání, a village in the Moravian Slovakia.",
              })}
            </p>
          </div>
        </div>
        <div className="Paragraph Two">
          <h1>
            {intl.formatMessage({
              id: "Janacek_p2h1",
              defaultMessage: "Intensive Study of Folklore",
            })}
          </h1>
          <p>
            {intl.formatMessage({
              id: "Janacek_p2p",
              defaultMessage:
                "Janáček’s enthusiasm for his native folk music spawned repeated ethnographic excursions into the Moravian countryside. He spearheaded folklorist activity in Moravia and Silesia, and his study of the relationships between language and music gradually developed an original compositional style. His compositions share in the melodic and rhythmic irregularities of word-based Slovak, Hungarian and Romanian folk music, and by conveying a sense of heightened realism powerfully make the connection to everyday life.",
            })}
          </p>
        </div>
        <div className="BGDots RightDots" />
      </div>
      <div id="Section Five" className="Section Five Slider">
        <section
          className={"Slides " + sliderClass}
          onMouseMove={(e) => {
            if (isDrag) {
              var pbLeft =
                Math.min(
                  Math.max(e.screenX, window.innerWidth * 0.2),
                  window.innerWidth * 0.68
                ) -
                window.innerWidth * 0.06;

              // @ts-ignore
              document.getElementById("ProgressBar").style.left = pbLeft + "px";
            }
          }}
          onMouseUp={(e) => {
            if (isDrag) {
              setIsDrag(false);

              var slideOneDiff = Math.abs(e.screenX - window.innerWidth * 0.26);

              var slideTwoDiff = Math.abs(e.screenX - window.innerWidth * 0.38);

              var slideThreeDiff = Math.abs(
                e.screenX - window.innerWidth * 0.5
              );

              var slideFourDiff = Math.abs(
                e.screenX - window.innerWidth * 0.62
              );

              var slideFiveDiff = Math.abs(
                e.screenX - window.innerWidth * 0.74
              );

              var slide = findNearestSnap(
                slideOneDiff,
                slideTwoDiff,
                slideThreeDiff,
                slideFourDiff,
                slideFiveDiff
              );

              // @ts-ignore
              document.getElementById("ProgressBar").style.left =
                slide * 12 + 20 + "%";

              setSlide(slide);
            }
          }}
        >
          <div className="Slide Zero">
            <div className="YellowEllipse" />
            <div className="ProgressBarBG" />
            <div
              id="ProgressBar"
              className="ProgressBar"
              onMouseDown={() => {
                setIsDrag(true);
              }}
            />
            <div className="SliderBtn PrevSlide" onClick={() => prevSlide()} />
            <div className="SliderBtn NextSlide" onClick={() => nextSlide()} />
          </div>
          <div className="Slide One">
            <h1>
              {intl.formatMessage({
                id: "Janacek_Slh1",
                defaultMessage: "Glagolitic Mass",
              })}
            </h1>
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage(
                    {
                      id: "Janacek_Sl1p",
                      defaultMessage:
                        "Leoš Janáček’s original intention in composing what would \nbecome known as the {wordVal} was to set an Old Church \nSlavic version of the Ordinary of the Roman Catholic Mass.",
                    },
                    { wordVal: <i>Glagolitic Mass</i> }
                  )
                : intl.formatMessage(
                    {
                      id: "Janacek_m_Sl1p",
                      defaultMessage: "",
                    },
                    { wordVal: <i>Glagolitic Mass</i> }
                  )}
            </p>
            <div className="MusicNote" />
            <div className="FlippedClick" />
            <div className="ScrollGIF" />
          </div>
          <div className="Slide Two">
            <h1>
              {intl.formatMessage({
                id: "Janacek_Slh1",
                defaultMessage: "Glagolitic Mass",
              })}
            </h1>
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage(
                    {
                      id: "Janacek_Sl2p",
                      defaultMessage:
                        "Glagolitic was the first Slavic literary and written language, \noriginating around the 9th century. The Czech scholar Jezef Vajs \npublished two Latin-alphabet transcriptions of a 14th-century source \nin 1919 and 1920, respectively. Janáček used both text transcriptions in \ndifferent parts of the mass, and he sent the text to a professor of \nSlavic studies in Prague. To this day, various editions of the\n {wordVal} show slight differences in text, but Janáček \nalways viewed it as a celebration of Slavic culture.",
                    },
                    { wordVal: <i>Glagolitic Mass</i> }
                  )
                : intl.formatMessage(
                    {
                      id: "Janacek_m_Sl2p",
                      defaultMessage: "",
                    },
                    { wordVal: <i>Glagolitic Mass</i> }
                  )}
            </p>
            <div className="MusicNote" />
          </div>
          <div className="Slide Three">
            <h1>
              {intl.formatMessage({
                id: "Janacek_Slh1",
                defaultMessage: "Glagolitic Mass",
              })}
            </h1>
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage({
                    id: "Janacek_Sl3p",
                    defaultMessage:
                      "Janáček was at the peak of his musical powers, and he \nbrilliantly poured his dramatic skills into the composition of the Mass. \nThe orchestral writing is brilliantly detailed, and the solo parts expressive. \nNot to be outdone, the choral writing is magnificent, with occasional \noperatic intensity. The drama of the Kyrie and Credo is balanced \nby moments of pastoral exultation, and in the Gloria Janáček \nevokes the hills surrounding his hometown.",
                  })
                : intl.formatMessage({
                    id: "Janacek_m_Sl3p",
                    defaultMessage: "",
                  })}
            </p>
            <div className="MusicNote" />
          </div>
          <div className="Slide Four">
            <h1>
              {intl.formatMessage({
                id: "Janacek_Slh1",
                defaultMessage: "Glagolitic Mass",
              })}
            </h1>
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage({
                    id: "Janacek_Sl4p",
                    defaultMessage:
                      "It is music “made for a cathedral from the huge expanse of \nwoods and sky stretching into a misty distance”. With its dramatic \nmix of religious and pagan ritual, and the juxtaposition of exuberant \nenergy and subtle tenderness, Janáček takes the listener on a \nhighly original and exhilarating journey.",
                  })
                : intl.formatMessage({
                    id: "Janacek_m_Sl4p",
                    defaultMessage: "",
                  })}
            </p>
            <div className="MusicNote" />
          </div>
          <div className="Slide Five">
            <Iframe
              className="Spotify"
              url="https://open.spotify.com/embed/album/4A8df1zINOngqQ35osLiP5"
              width={(window.innerWidth > 450
                ? window.innerWidth * 0.4
                : window.innerWidth * 0.8
              ).toString()}
              height={(window.innerWidth > 450
                ? window.innerWidth * 0.5
                : window.innerWidth * 0.6
              ).toString()}
              allow="encrypted-media"
            />
          </div>
        </section>
      </div>
      <div
        id="Section Six"
        className="Section Six"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Seven")?.offsetTop
          );
        }}
      >
        <div id="FolksongTwo" className="Images Folksong">
          <img
            src={Folksong2}
            alt="Janacek collecting folksong"
            style={{
              transform:
                "scale(" +
                (Math.min(
                  Math.abs(
                    offsetY -
                      // @ts-ignore
                      document.getElementById("FolksongTwo")?.offsetTop -
                      // @ts-ignore
                      document.getElementById("Section Six")?.offsetTop
                  ) /
                    // @ts-ignore
                    document.getElementById("FolksongTwo")?.offsetHeight,
                  1
                ) *
                  Constant.photoMagnifier +
                  1) +
                ")",
            }}
          />
        </div>
        <div className="Caption Folksong">
          <p>
            {intl.formatMessage({
              id: "Janacek_c2",
              defaultMessage:
                "Leoš Janáček and Kamila Stösslová in Luhačovice in 1920.",
            })}
          </p>
        </div>
        <div className="Paragraph Three">
          <h1>
            {intl.formatMessage({
              id: "Janacek_p3h1",
              defaultMessage: "Obsession and Inspiration",
            })}
          </h1>
          <p>
            {intl.formatMessage({
              id: "Janacek_p3p",
              defaultMessage:
                "Janáček was a married man aged 63 when he met the 25 year-old Kamila Stösslová, herself also married. What might have turned into an innocent and transient infatuation became a fully fledged obsession. Kamila remained unattainable, and became the inspiration for most of his operatic heroines.",
            })}
          </p>
          <div className="Quote One">
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage({
                    id: "Janacek_q1",
                    defaultMessage:
                      '"There is so much emotional fire,” he writes to Kamila, \n“if we both caught fire, we would be reduced to ashes."',
                  })
                : intl.formatMessage({
                    id: "Janacek_m_q1",
                    defaultMessage: "",
                  })}
            </p>
          </div>
          <p className="PThreePointTwo" style={{ marginTop: "12.5vw" }}>
            {intl.formatMessage({
              id: "Janacek_p4p",
              defaultMessage:
                "One sunny day in 1925 the unlikely couple sat in a park in the town of Písek, where Kamila lived with her husband. Spellbound, they listened to a performance by a military band marching in full uniform.",
            })}
          </p>
        </div>
        {/* <div className="Paragraph ThreePointFive"></div> */}
        <div className="BGDots RightDots" />
      </div>
      <div
        id="Section Seven"
        className="Section Seven"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Eight")?.offsetTop
          );
        }}
      />
      <div
        id="Section Eight"
        className="Section Eight"
        onClick={() => {
          Scroll.animateScroll.scrollTo(
            //@ts-ignore
            document.getElementById("Section Nine")?.offsetTop
          );
        }}
      >
        <div className="BGDots LeftDots" />
        <div className="BGDots RightDots" />
        <div className="Paragraph Four">
          <div className="Title">
            <h1>
              {intl.formatMessage({
                id: "Janacek_p5h1",
                defaultMessage: "Sinfonietta",
              })}
            </h1>
            <div className="MusicNotes" />
          </div>
          <p>
            {intl.formatMessage(
              {
                id: "Janacek_p5p",
                defaultMessage:
                  "Patriotism and nationalism, particularly in the aftermath of the declaration of Czech independence in October 1918, had always appealed to Leoš Janáček. And when he was asked to compose music for the gymnastic Sokol festival—a movement that celebrated youth, sport and an independent nationhood—Janáček readily gave expression to his notion of national identity. Initially, the work was titled {wordVal} and dedicated to the Czechoslovak Armed Forces.\n\n",
              },
              { wordVal: <i>Military Sinfonietta</i> }
            )}
            <p>
              {intl.formatMessage({
                id: "Janacek_p6p",
                defaultMessage: "According to the composer,",
              })}
            </p>
          </p>
          <div className="Quote One">
            <p>
              {window.innerWidth > 450
                ? intl.formatMessage({
                    id: "Janacek_q2",
                    defaultMessage:
                      "it embodied the ideals of contemporary free man, \nhis spiritual beauty and joy, his strength, courage and \ndetermination to fight for victory",
                  })
                : intl.formatMessage({
                    id: "Janacek_m_q2",
                    defaultMessage: "",
                  })}
            </p>
          </div>
          <p className="PFourPointTwo" style={{ marginTop: "15vw" }}>
            {window.innerWidth > 450
              ? intl.formatMessage(
                  {
                    id: "Janacek_p7p",
                    defaultMessage:
                      "Eventually he dropped the military designation, and the work became known simply as the {wordVal}.\n\nDepicting various scenes and locations associated with the city Brnoafter the 1918 declaration of independence, Janáček wrote: “I saw the town undergo a miraculous change. I lost my dislike for the gloomy Town Hall, my hatred of the hill from whose depths so much pain was screaming, my distaste for the street and its throng. As if by a miracle, liberty was conjured up, glowing over the town. I saw myself in it. I belonged to it. And the blare of the victorious trumpets, the holy peace of the Queen’s Monastery, the shadows of the night, the breath of the green hill and the vision of the growing greatness of the town, of my Brno, were all giving birth to my Sinfonietta.",
                  },
                  { wordVal: <i>Sinfonietta</i> }
                )
              : intl.formatMessage(
                  {
                    id: "Janacek_m_p7p",
                    defaultMessage: "",
                  },
                  { wordVal: <i>Sinfonietta</i> }
                )}
          </p>
        </div>
        <Iframe
          className="Spotify"
          url="https://open.spotify.com/embed/album/1ScuTMFQX2WnELZSYgW31B"
          width={(window.innerWidth > 450
            ? window.innerWidth * 0.3
            : window.innerWidth * 0.85
          ).toString()}
          height={(window.innerWidth > 450
            ? window.innerWidth * 0.12
            : window.innerWidth * 0.5
          ).toString()}
          allow="encrypted-media"
        />
        {/* <div className="Paragraph Five"></div> */}
      </div>
      <div id="Section Nine" className="Section Nine">
        <div className="VideoContainer">
          <div id="LJ3_EN Mask" className="VideoMask">
            <p>
              {intl.formatMessage({
                id: "Janacek_v2p",
                defaultMessage:
                  'It is an animated video by Vendula Chalánková, Oksana Rogožina and Jan Smrčka. It portrays Janáček\'s "great old age", the last ten years of his life when he wrote his major works which are famous across the world today.',
              })}
            </p>
            <div
              className="VideoMaskBtn"
              onClick={() => {
                // @ts-ignore
                document.getElementById("LJ3_EN").play();
                // @ts-ignore
                document.getElementById("LJ3_EN Mask").style.opacity = "0";
                // @ts-ignore
                document.getElementById("LJ3_EN Mask").style.zIndex = "-100";
              }}
            />
            <p style={{ fontSize: "1vw", textAlign: "center" }}>
              {intl.formatMessage(
                {
                  id: "Janacek_vlink",
                  defaultMessage: "See more at Brno's office {link}",
                },
                {
                  link: (
                    <a href="https://www.leosjanacek.eu/en/">Janáček website</a>
                  ),
                }
              )}
            </p>
          </div>
          <video
            id="LJ3_EN"
            className="Video"
            onPlay={() => {
              // @ts-ignore
              openFullscreen(document.getElementById("LJ3_EN"));
              // @ts-ignore
              document.getElementById("LJ3_EN Mask").style.opacity = "0";
              // @ts-ignore
              document.getElementById("LJ3_EN Mask").style.zIndex = "-100";
            }}
            onPause={() => {
              // @ts-ignore
              document.getElementById("LJ3_EN Mask").style.zIndex = "100";
              // @ts-ignore
              document.getElementById("LJ3_EN Mask").style.opacity = "1";
            }}
            controlsList="nodownload"
            controls
          >
            <source src={LJ3_ENG} type="video/mp4" />
          </video>
        </div>
      </div>
      <div id="Section Ten" className="Legacy">
        <div className="DotsRect" />
        <h1>
          {intl.formatMessage({ id: "Legacy", defaultMessage: "Legacy" })}
        </h1>
        <p>
          {intl.formatMessage({
            id: "Janacek_legacy",
            defaultMessage:
              "Janáček drew on the inner resources of music and speech to convey complex feelings and emotional states to his listeners. His extraordinary power in translating profound psychological insights into music emerged from his fervent compassion for the human condition.",
          })}
        </p>
        <div className="Signature">
          <img src={Signature} alt="Janacek's Signature" />
        </div>
        <div
          className="Ellipse Left"
          style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        />
        {/* <div className="Ellipse LowerRight" /> */}
        <div
          id="FooterSwitch"
          // className="FooterSwitch"
          className={"FooterSwitch" + (footerHover ? " Hover" : "")}
        >
          <div
            id="Ellipse Right"
            className="Ellipse Right"
            style={{
              transform: `translateY(-${offsetY * 0.5}px)`,
              top:
                // @ts-ignore
                (document.getElementById("Section Ten")?.offsetTop +
                  window.innerHeight -
                  // @ts-ignore
                  document.getElementById("Section Ten")?.offsetHeight) /
                  2 -
                window.innerHeight +
                "px",
            }}
          />
          <div
            className="FooterBtn"
            onMouseEnter={() => {
              setFooterHover(true);
              // @ts-ignore
              document.getElementById("Ellipse Right").style.top =
                // @ts-ignore
                (document.getElementById("Section Ten")?.offsetTop +
                  window.innerHeight -
                  // @ts-ignore
                  document.getElementById("Section Ten")?.offsetHeight) /
                  2 -
                window.innerHeight +
                // @ts-ignore
                document.getElementById("FooterSwitch")?.offsetTop +
                // @ts-ignore
                document.getElementById("NextChapter")?.offsetTop / 1.6 +
                "px";
            }}
            onMouseLeave={() => {
              setFooterHover(false);
              // @ts-ignore
              document.getElementById("Ellipse Right").style.top =
                // @ts-ignore
                (document.getElementById("Section Ten")?.offsetTop +
                  window.innerHeight -
                  // @ts-ignore
                  document.getElementById("Section Ten")?.offsetHeight) /
                  2 -
                window.innerHeight +
                "px";
            }}
            onClick={() => {
              history.push("/Martinu");
            }}
          >
            <div className="FooterName">
              <p>
                {intl.formatMessage({
                  id: "Martinu",
                })}
              </p>
            </div>
            <div id="NextChapter" className="NextChapter">
              <p>
                {intl.formatMessage({
                  id: "NextChapter",
                  defaultMessage: "Next Chapter",
                })}
              </p>
              <div className="FooterArrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Janacek;
