import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../scss/Menu.scss";
import MenuToggler from "./MenuToggler";
import * as LANG from "../Redux/Actions/Lang";
import * as ROUTE from "../Redux/Actions/Route";
import { useIntl } from "react-intl";
import Cookies from "universal-cookie/es6";

const Menu = () => {
	const [isOpened, setIsOpened] = useState(false);
	const [btnContainerClass, setBtnContainerClass] = useState("BtnContainer");
	const history = useHistory();
	const dispatch = useDispatch();
	const intl = useIntl();
	// @ts-ignore
	const currPage = useSelector((state) => state.route.page);
	// @ts-ignore
	const lang = useSelector((state) => state.locale.lang);
	const cookies = new Cookies();

	useLayoutEffect(() => {
		setBtnContainerClass("BtnContainer " + (currPage || "Home"));
	}, [currPage]);

	useEffect(() => {
		function onMouseMove(e: MouseEvent) {
			// @ts-ignore
			document.getElementById("Ellipse Blue").style.left =
				e.screenX - (7.5 / 200) * window.innerWidth + "px";
			// @ts-ignore
			document.getElementById("Ellipse Blue").style.top =
				e.screenY - (7.5 / 100) * window.innerWidth + "px";
		}

		window.addEventListener("mousemove", onMouseMove);

		return () => {
			window.removeEventListener("mousemove", onMouseMove);
		};
	});

	function onEnterMenuBtn(btnName: string) {
		setBtnContainerClass(
			"BtnContainer " + btnName + " " + (currPage || "Home")
		);
	}

	function onMenuBtnClick(route: string) {
		setIsOpened(false);

		if (route === "Share") {
			cookies.set("ShareExp", "visible", { path: "/" });
			if (!currPage) {
				var shareExp = document.getElementById("ShareExp");
				if (shareExp) {
					shareExp.style.zIndex = "300";
				}
			}
		} else if (route === "" && !currPage) {
			cookies.remove("ShareExp");
		} else {
			cookies.remove("ShareExp");
		}

		switch (route) {
			case "":
				dispatch(ROUTE.MAP());
				break;
			case "Share":
				// dispatch(SHARE.ON());
				dispatch(ROUTE.MAP());
				break;
			case "Smetana":
				dispatch(ROUTE.SMETANA());
				break;
			case "Dvorak":
				dispatch(ROUTE.DVORAK());
				break;
			case "Janacek":
				dispatch(ROUTE.JANACEK());
				break;
			case "Martinu":
				dispatch(ROUTE.MARTINU());
				break;
			case "Ndb":
				dispatch(ROUTE.NDB());
				break;
			default:
				return;
		}

		history.push("/" + route);
	}

	function switchHalf() {
		var menu = document.getElementById("Menu");

		console.log("Switch");
		if (menu?.classList.contains("LeftHalf")) {
			menu?.classList.replace("LeftHalf", "RightHalf");
		} else if (menu?.classList.contains("RightHalf")) {
			menu?.classList.replace("RightHalf", "LeftHalf");
		} else {
			menu?.classList.add("RightHalf");
		}
	}

	return (
		<div>
			<MenuToggler
				getMenuStatus={() => {
					return isOpened;
				}}
				toggleMenu={() => {
					setIsOpened(!isOpened);
				}}
			/>
			<div
				id="Menu"
				className={
					"Menu" +
					(isOpened ? " Opened" : "") +
					(lang === LANG.EN().type ? "" : " ZH-HANT")
				}
			>
				<div className="DotsRect" />
				<div className="Ellipse Green" />
				<div id="Ellipse Blue" className="Ellipse Blue" />
				<div className="LeftHalf">
					<div className={btnContainerClass}>
						<div
							id="MenuBtn Home"
							className="MenuBtn Home"
							onMouseEnter={() => onEnterMenuBtn("Home")}
							onClick={() => onMenuBtnClick("")}
						>
							<p>{intl.formatMessage({ id: "Map" })}</p>
						</div>
						<div
							id="MenuBtn Smetana"
							className="MenuBtn Smetana"
							onMouseEnter={() => onEnterMenuBtn("Smetana")}
							onClick={() => onMenuBtnClick("Smetana")}
						>
							{lang === LANG.EN().type ? (
								<></>
							) : (
								<p>{intl.formatMessage({ id: "Smetana" })}</p>
							)}
						</div>
						<div
							id="MenuBtn Dvorak"
							className="MenuBtn Dvorak"
							onMouseEnter={() => onEnterMenuBtn("Dvorak")}
							onClick={() => onMenuBtnClick("Dvorak")}
						>
							{lang === LANG.EN().type ? (
								<></>
							) : (
								<p>{intl.formatMessage({ id: "Dvorak" })}</p>
							)}
						</div>
						<div
							id="MenuBtn Janacek"
							className="MenuBtn Janacek"
							onMouseEnter={() => onEnterMenuBtn("Janacek")}
							onClick={() => onMenuBtnClick("Janacek")}
						>
							{lang === LANG.EN().type ? (
								<></>
							) : (
								<p>{intl.formatMessage({ id: "Janacek" })}</p>
							)}
						</div>
						<div
							id="MenuBtn Martinu"
							className="MenuBtn Martinu"
							onMouseEnter={() => onEnterMenuBtn("Martinu")}
							onClick={() => onMenuBtnClick("Martinu")}
						>
							{lang === LANG.EN().type ? (
								<></>
							) : (
								<p>{intl.formatMessage({ id: "Martinu" })}</p>
							)}
						</div>
						<div
							id="MenuBtn NationalTheatreBrno"
							className="MenuBtn NationalTheatreBrno"
							onMouseEnter={() => onEnterMenuBtn("NationalTheatreBrno")}
							onClick={() => onMenuBtnClick("Ndb")}
						>
							<p>{intl.formatMessage({ id: "NationalTheatreBrno" })}</p>
						</div>
						<div
							id="MenuBtn Share"
							className="MenuBtn Share"
							onMouseEnter={() => onEnterMenuBtn("Share")}
							onClick={() => onMenuBtnClick("Share")}
						>
							<p>{intl.formatMessage({ id: "ShareExp" })}</p>
						</div>
					</div>
					<div
						className="HKAFLogo"
						onClick={() =>
							(window.location.href = "https://www.hk.artsfestival.org/en/")
						}
					/>
					<div
						className="PlusLogo"
						onClick={() =>
							(window.location.href = "https://www.hk.artsfestival.org/en/")
						}
					/>
					{window.innerWidth > 450 ? (
						<></>
					) : (
						<div className="CreditSwitch" onClick={switchHalf}>
							<p>{intl.formatMessage({ id: "Credit" })}</p>
							<div className="SwitchArrow" />
						</div>
					)}
				</div>
				<div className="RightHalf">
					<div className="Contributors">
						<p>
							{intl.formatMessage({ id: "ExhibitionAuthor" })}: {"\n"}Dr. Georg
							Predota {"\n"}
						</p>
						<p>
							{intl.formatMessage({ id: "IllustrationDesign" })}: {"\n"}nicole
							p.s. chan {"\n"}
						</p>
						<p>
							{intl.formatMessage({ id: "WebsiteDesign" })}: {"\n"}
							HeyNova Creative
						</p>
						<p style={{ margin: "initial" }}>
							{intl.formatMessage({ id: "Courtesy" })}
						</p>
						<div
							className="Logo NDB"
							onClick={() =>
								(window.location.href =
									"https://www.hk.artsfestival.org/en/programmes/index.html?info=Brno")
							}
						/>
						<div
							className="Logo MartinuFoundation"
							onClick={() =>
								(window.location.href = "https://www.martinu.cz/en/")
							}
						/>
						<div
							className="Logo TIC"
							onClick={() =>
								(window.location.href = "https://www.martinu.cz/en/")
							}
						/>
						<div
							className={
								"LangSwitch" + (lang === LANG.EN().type ? " EN" : " ZH")
							}
						>
							<div
								className="LangBtn ZH"
								onClick={() => {
									dispatch(LANG.ZH_HANT());
									setIsOpened(false);
								}}
							>
								<p>繁</p>
							</div>
							<div
								className="LangBtn EN"
								onClick={() => {
									dispatch(LANG.EN());
									setIsOpened(false);
								}}
							>
								<p>EN</p>
							</div>
						</div>
						{window.innerWidth > 450 ? (
							<></>
						) : (
							<div className="MenuSwitch" onClick={switchHalf}>
								<div className="SwitchArrow" />
								<p>{intl.formatMessage({ id: "BackToMenu" })}</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Menu;
