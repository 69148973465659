import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { renderRoutes } from "react-router-config";
import routes from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import en from "./i18n/en";
import zh_hant from "./i18n/zh";
import { stateType } from "./Redux/Types";
import Menu from "./Components/Menu";
import Cookies from "universal-cookie/es6";

const App = () => {
	const locale = useSelector((state: stateType) => state.locale.lang);
	const cookies = new Cookies();
	const dispatch = useDispatch();

	let message;

	if (locale === "zh-hant") {
		message = zh_hant;
	} else {
		message = en;
	}

	dispatch({ type: cookies.get("LANG") || "en" });

	useEffect(() => {
		const script = document.createElement("script");

		script.innerHTML =
			"(function(d) {var config = { kitId: 'xuu2qvf', scriptTimeout: 3000, async: true }, h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,'')+' wf-inactive';},config.scriptTimeout),tk=d.createElement('script'),f=false,s=d.getElementsByTagName('script')[0],a;h.className+=' wf-loading';tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!='complete'&&a!='loaded')return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)})(document);";

		script.setAttribute("async", "");

		document.head.appendChild(script);

		window.addEventListener("contextmenu", (e) => e.preventDefault());

		return () => {
			document.head.removeChild(script);
			window.removeEventListener("contextmenu", (e) => e.preventDefault());
		};
	});

	return (
		<IntlProvider
			locale={locale}
			key={locale}
			defaultLocale="en"
			messages={message}
		>
			<Switch>{renderRoutes(routes)}</Switch>
			<Menu />
		</IntlProvider>
	);
};

export default App;
